import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer, GridRowId } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import PrintIcon from '@mui/icons-material/Print'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import PrintCoverDialog from './PrintCoverDialog'
import PrintQuotationDialog from './PrintQuotationDialog'
import { SalesQuotation } from '../../parse-class/SalesQuotation'

export const FIELDS = {
  DATE: 'DATE',
  CUSTOMER: 'CUSTOMER',
  NOTE: 'NOTE',
}

export function SalesQuotationPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [quotationList, setQuotationList] = React.useState<SalesQuotation[]>([])
  // state
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [showPrintCoverDialog, setShowPrintCoverDialog] = React.useState(false)
  const [printingQuotationIds, setPrintingQuotationIds] = React.useState<string[] | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(SalesQuotation)
      query.limit(10000)
      query.descending('createdAt')
      query.include('customer')
      const data = await query.find()
      setQuotationList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const quotation = SalesQuotation.createWithoutData(id)
      await quotation.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!quotationList) {
      return []
    }
    return quotationList.map(quotation => (
      {
        id: quotation.id,
        [FIELDS.DATE]: quotation.get('date'),
        [FIELDS.CUSTOMER]: quotation.get('customer')?.get('name') || '*不指定客戶*',
        [FIELDS.NOTE]: quotation.get('note'),
      }
    ))
  }, [quotationList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
      },
      {
        field: FIELDS.CUSTOMER,
        headerName: '客戶',
        width: 160,
      },
      {
        field: FIELDS.NOTE,
        headerName: '標題',
        width: 300,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      }
    ]
  }, [onDelete])

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="報價單" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              // selection
              onSelectionModelChange={selectionModel => { setSelectionModel(selectionModel) }}
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setShowAddDialog(true)
                      }}
                    >
                      新增報價單
                    </Button>
                    <Button
                      disabled={selectionModel.length === 0}
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setPrintingQuotationIds(selectionModel as string[]) }}
                    >
                      報價單
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setShowPrintCoverDialog(true) }}
                    >
                      封面
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <AddDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        fullWidth
        maxWidth='md'
        onUpdate={() => {
          setShowAddDialog(false) // 關閉視窗
          loadData()              // 重新載入資料
        }}
      />
      <EditDialog
        open={editingId !== null}
        onClose={() => setEditingId(null)}
        fullWidth
        maxWidth='md'
        editingId={editingId}
        onUpdate={() => {
          setEditingId(null) // 關閉視窗
          loadData()         // 重新載入資料
        }}
      />
      <PrintCoverDialog
        open={showPrintCoverDialog}
        onClose={() => setShowPrintCoverDialog(false)}
        fullWidth
        maxWidth="sm"
      />
      <PrintQuotationDialog
        open={printingQuotationIds !== null}
        onClose={() => setPrintingQuotationIds(null)}
        fullWidth
        maxWidth="sm"
        quotationIds={printingQuotationIds}
      />
    </React.Fragment>
  )
}