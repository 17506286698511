import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ClientPreferenceContext } from '../../context/client-preference'
import PosPrinterAutocomplete from '../../input/PosPrinterAutocomplete'
import BasicNumberField from '../../input/BasicNumberField'
import { SalesQuotation } from '../../parse-class/SalesQuotation'
import { PosPrinter } from '../../parse-class/PosPrinter'
import { createSalesQuotationTask } from '../../pos-printer/create-sales-quotation-task'

interface IFields {
  printer: PosPrinter | null
  copies: number
}

export interface IParams extends DialogProps {
  quotationIds: string[] | null
}

export default function PrintQuotationDialog(props: IParams) {

  const { quotationIds, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const clientPreference = React.useContext(ClientPreferenceContext)
  // data
  const [quotationList, setQuotationList] = React.useState<SalesQuotation[]>([])
  // state
  const [fields, setFields] = React.useState<IFields>({
    printer: null,
    copies: 1,
  })
  const [reorderIds, setReorderIds] = React.useState<string[]>([])

  const loadData = React.useCallback(async (ids: string[]) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(SalesQuotation)
      query.containedIn('objectId', ids)
      const data = await query.find()
      setQuotationList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && quotationIds !== null) {
      loadData(quotationIds)
      const defaultPosPrinter = clientPreference.get().defaultPosPrinter
      setFields({
        printer: defaultPosPrinter || null,
        copies: 1,
      })
      setReorderIds([...quotationIds])
    }
  }, [dialogProps.open, clientPreference, quotationIds, loadData])

  const verify = React.useMemo(() => {
    return fields.printer !== null
  }, [fields])

  const onPrint = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('上傳列印任務中')
        await createSalesQuotationTask({
          printer: fields.printer!,
          quotationIds: reorderIds,
          copies: fields.copies
        })
        snackbar.show('列印成功')
        dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') // 關閉視窗
      } catch (e: any) {
        snackbar.show('上傳列印任務失敗' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, fields, reorderIds])

  /**
   * 重新排序
   */
  const reorderData = React.useCallback((sourceIndex: number, destinationIndex: number) => {
    setReorderIds(pre => {
      const clone = [...pre]
      const [temp] = clone.splice(sourceIndex, 1)
      clone.splice(destinationIndex, 0, temp)
      return clone
    })
  }, [])

  return (
    <Dialog  {...dialogProps}>
      <DialogTitle>列印銷貨報價單</DialogTitle>
      <DialogContent>
        <PosPrinterAutocomplete
          value={fields.printer}
          onChange={(event, value) => setFields(pv => ({ ...pv, printer: value }))}
        />
        <BasicNumberField
          value={fields.copies}
          label="份數"
          type="number"
          onChange={e => setFields(pv => ({ ...pv, copies: Number(e.target.value) }))}
          autoFocus
        />
        <DragDropContext onDragEnd={result => {
          if (!result.destination) return
          reorderData(result.source.index, result.destination.index)
        }}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List ref={provided.innerRef} dense>
                {reorderIds.map((id, index) =>
                  <Draggable
                    key={id}
                    draggableId={id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ background: snapshot.isDragging ? 'rgb(235,235,235)' : undefined }}
                      >
                        <ListItemIcon>
                          <DragHandleIcon />
                        </ListItemIcon>
                        <ListItemText primary={quotationList.find(sq => sq.id === id)?.get('note') || '無標題'} />
                      </ListItem>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onPrint}
        >
          列印
        </Button>
      </DialogActions>
    </Dialog>
  )
}