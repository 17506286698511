import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import ItemAutocomplete from '../../input/ItemAutocomplete'
import BasicNumberField from '../../input/BasicNumberField'
import BasicTextField from '../../input/BasicTextField'
import { Item } from '../../parse-class/Item'
import { InventoryAdjustment } from '../../parse-class/InventoryAdjustment'
dayjs.extend(utc)

interface IFields {
  date: Date
  item: Item | null
  qty: number
  note: string
}

interface IParams extends DialogProps {
  group: string
  onUpdate: () => void
}

export default function AddDialog(props: IParams) {

  const { group, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    item: null,
    qty: 0,
    note: '',
  })

  const verify = React.useMemo(() => {
    return fields.item !== null
  }, [fields])

  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        item: null,
        qty: 0,
        note: '',
      })
    }
  }, [dialogProps.open])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const data = new InventoryAdjustment()
        data.set('group', group)
        data.set('date', fields.date)
        data.set('item', fields.item!)
        data.set('qty', fields.qty)
        data.set('note', fields.note)
        await data.save()
        snackbar.show('新增成功')
        onUpdate() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, fields, onUpdate])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>新增庫存調整</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, date: value }))
          }}
        />
        <ItemAutocomplete
          value={fields.item}
          onChange={(event, value) => setFields(pv => ({ ...pv!, item: value }))}
          textFieldProps={{ autoFocus: true }}
        />
        <BasicNumberField
          label="數量（+/-）"
          defaultValue={0}
          onChange={e => setFields(pv => ({ ...pv!, qty: Number(e.target.value) }))}
        />
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}