import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import BasicTextField from '../../input/BasicTextField'
import UserAutocomplete from '../../input/UserAutocomplete'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import { Customer } from '../../parse-class/Customer'
import { User } from '../../parse-class/User'
import { MarketingNote } from '../../parse-class/MarketingNote'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  salesperson: User | null
  customer: Customer | null
  topic: string
  result: string
}

interface IParams extends DialogProps {
  onChange: () => void
}

export default function AddDialog(props: IParams) {

  const { onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    salesperson: null,
    customer: null,
    topic: '',
    result: '',
  })

  const verify = React.useMemo(() => {
    return fields.salesperson !== null
      && fields.customer !== null
      && fields.topic.trim().length > 0
  }, [fields])


  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        salesperson: null,
        customer: null,
        topic: '',
        result: '',
      })
    }
  }, [dialogProps.open])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const marketingNote = new MarketingNote()
        marketingNote.set('date', fields.date)
        marketingNote.set('salesperson', fields.salesperson!)
        marketingNote.set('customer', fields.customer!)
        marketingNote.set('topic', fields.topic)
        marketingNote.set('result', fields.result)
        await marketingNote.save()
        snackbar.show('新增成功')
        onChange() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, onChange])

  return (
    <Dialog {...dialogProps}  >
      <DialogTitle>新增行銷行動</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, date: value }))
          }}
        />
        <UserAutocomplete
          roles={['SALESPERSON']}
          value={fields.salesperson}
          onChange={(event, value) => setFields(pv => ({ ...pv!, salesperson: value }))}
        />
        <CustomerAutocomplete
          value={fields.customer}
          onChange={(event, value) => setFields(pv => ({ ...pv, customer: value }))}
        />
        <BasicTextField
          label="主題"
          value={fields.topic}
          onChange={e => { setFields(pv => ({ ...pv, topic: e.target.value })) }}
        />
        <BasicTextField
          label="結果"
          multiline
          rows={5}
          value={fields.result}
          onChange={e => { setFields(pv => ({ ...pv, result: e.target.value })) }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}