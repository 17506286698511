import React from 'react'
import { DataGridPremium, GridColumns, GridToolbarContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import PrintIcon from '@mui/icons-material/Print'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import { PosPrinter } from '../../parse-class/PosPrinter'
import { createTestTask } from '../../pos-printer/create-test-task'

export const FIELDS = {
  ALIVE: 'ALIVE',
  NAME: 'NAME',
}

export function PosPrinterPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [printerList, setPrinterList] = React.useState<PosPrinter[] | null>(null)
  // state
  const [showAddDialog, setShowAddDialog] = React.useState(false)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(PosPrinter)
        .limit(1000)
        .find()
      setPrinterList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 複製金鑰到剪貼簿
   */
  const copyKey = React.useCallback(async (key: string) => {
    await navigator.clipboard.writeText(key)
    snackbar.show('出單機識別碼已複製到剪貼簿')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const printer = PosPrinter.createWithoutData(id)
      await printer.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  /**
   * 測試出單機
   */
  const onTest = React.useCallback(async (id: string) => {
    const printer = printerList?.find(p => p.id === id)
    if (printer) {
      try {
        backdrop.show('測試出單機中')
        await createTestTask({ printer, msg: `測試：${printer.get('name')}` })
        snackbar.show('測試成功')
      } catch (e: any) {
        snackbar.show('測試出單機失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printerList])

  /**
   * 將資料轉為Grid格式
   */
  const rows = React.useMemo(() => {
    if (!printerList) {
      return []
    }
    return printerList.map(printer => ({
      id: printer.id,
      [FIELDS.NAME]: printer.get('name'),
      [FIELDS.ALIVE]: printer.get('alive'),
    }))
  }, [printerList])

  /**
   * 定義欄位
   */
  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.ALIVE,
        headerName: '連線中',
        width: 75,
        type: 'boolean',
        editable: false,
        sortable: false,
        valueGetter: params => {
          // 如果alive時間小於10秒，就算是Online
          const isOnline = (Date.now() - params.value.getTime()) < (10 * 1000)
          return isOnline
        },
      },
      {
        field: FIELDS.NAME,
        headerName: '名稱',
        width: 200,
        editable: false,
      },
      {
        field: 'test',
        headerName: '測試',
        width: 75,
        editable: false,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <IconButton onClick={() => onTest(params.id as string)}>
            <PrintIcon />
          </IconButton>
        ),
      },
      {
        field: 'copy_id',
        headerName: 'ID',
        width: 75,
        editable: false,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <IconButton onClick={() => copyKey(params.id as string)}>
            <FileCopyIcon />
          </IconButton>
        ),
      },
      {
        field: 'delete',
        headerName: '刪除',
        width: 75,
        editable: false,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <IconButton color="error" onClick={() => onDelete(params.id as string)} >
            <DeleteIcon />
          </IconButton>
        ),
      },
    ]
  }, [onTest, copyKey, onDelete])

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="出單機" />
          {/* Printer Grid */}
          <Box mt={2} mb={1}>
            <DataGridPremium
              autoHeight
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              disableColumnReorder
              disableColumnMenu
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setShowAddDialog(true)}
                    >
                      新增出單機
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <AddDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onChange={() => {
          setShowAddDialog(false) // 關閉視窗
          loadData()              // 重新載入資料
        }}
      />
    </React.Fragment>
  )
}