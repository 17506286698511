import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import DateField from '../../input/DateField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import QuotationDetailGrid from './QuotationDetailGrid'
import { SalesQuotation, IQuotationDetail } from '../../parse-class/SalesQuotation'
import { Customer } from '../../parse-class/Customer'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  customer: Customer | null
  note: string
  details: IQuotationDetail[]
}

interface IProps extends DialogProps {
  onUpdate: () => void
}

export default function AddDialog(props: IProps) {

  const { onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    customer: null,
    note: '',
    details: []
  })

  const verify = React.useMemo(() => {
    return fields.details.length > 0
  }, [fields])

  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        customer: null,
        note: '',
        details: []
      })
    }
  }, [dialogProps.open])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const quotation = new SalesQuotation()
        quotation.set('date', fields.date)
        quotation.set('customer', fields.customer || undefined)
        quotation.set('details', fields.details)
        quotation.set('note', fields.note)
        await quotation.save()
        snackbar.show('新增成功')
        onUpdate() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, onUpdate])

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>新增報價單</DialogTitle>
        <DialogContent>
          {/* 日期 */}
          <DateField
            value={fields.date}
            onDateChange={(event, value) => {
              setFields(pv => ({ ...pv!, date: value }))
            }}
          />
          {/* 客戶 */}
          <CustomerAutocomplete
            value={fields.customer}
            onChange={(event, value) => setFields(pv => ({ ...pv, customer: value }))}
            textFieldProps={{
              autoFocus: true,
              placeholder: fields.customer ? "" : "留白不指定客戶"
            }}
          />
          {/* 備註 */}
          <BasicTextField
            label="備註"
            value={fields.note}
            onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
            multiline
            rows={3}
          />
          <QuotationDetailGrid
            onUpdate={details => setFields(pv => ({ ...pv, details }))}
            autoApplyPricing="PRICE"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            新增
          </Button>
          <Button
            color="error"
            onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}