import { ZCompany } from '../ZCompany'
import { ZItem } from '../ZItem'

export interface ISalesDetail {
  item: ZItem            // 貨物
  qty: number            // 數量
  price: number          // 售價
  base: number           // 底價
  cost: number           // 成本
  memo: string           // 備註
}

export interface IAttributes {
  date: Date                      // 銷貨日期
  code: string                    // 銷貨編號
  company: ZCompany               // 客戶
  salesperson: string             // 業務
  deliveryPerson: string          // 司機
  creater: string                 // 建檔人
  createTime: Date                // 建立時間
  sum: number                     // 總額
  received: number                // 已收額
  memo: string                    // 備註
  details: ISalesDetail[]         // 明細
}

export const CLASS_NAME = 'ZSales'

export class ZSales extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, ZSales)