import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ClientPreferenceContext } from '../../context/client-preference'
import PosPrinterAutocomplete from '../../input/PosPrinterAutocomplete'
import { PosPrinter } from '../../parse-class/PosPrinter'
import { createPickTask } from '../../pos-printer/create-pick-task'

interface IFields {
  printer: PosPrinter | null
}

export interface IParams {
  transactionIds: string[]
  open: boolean
  onClose: () => void
}

export default function PrintPickDialog(props: IParams) {

  const { transactionIds, open, onClose } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const clientPreference = React.useContext(ClientPreferenceContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    printer: null,
  })

  React.useEffect(() => {
    if (open) {
      const defaultPosPrinter = clientPreference.get().defaultPosPrinter
      setFields({
        printer: defaultPosPrinter || null,
      })
    }
  }, [open, clientPreference])

  const verify = React.useMemo(() => {
    return fields.printer !== null
  }, [fields])

  const onClickResult = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('建立列印任務中')
        await createPickTask({
          printer: fields.printer!,
          transactionIds,
        })
        onClose()
        snackbar.show('建立列印任務成功')
      } catch (e: any) {
        snackbar.show('建立列印任務失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, transactionIds, fields, onClose])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>列印撿貨單</DialogTitle>
      <DialogContent>
        <PosPrinterAutocomplete
          value={fields.printer}
          onChange={(event, value) => setFields(pv => ({ ...pv, printer: value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onClickResult}
        >
          列印
        </Button>
        <Button
          color="error"
          onClick={onClose}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}