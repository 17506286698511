import { PosPrinter } from '../parse-class/PosPrinter'
import { PosPrinterTask } from '../parse-class/PosPrinterTask'

export interface IOptions {
  printer: PosPrinter
  msg: string
}

export async function createTestTask(options: IOptions) {
  // 建立任務
  const { printer, msg } = options
  const task = new PosPrinterTask()
  task.set('printer', printer)
  task.set('template', 'test')
  task.set('params', JSON.stringify({ msg }))
  await task.save()
}