import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import BasicTextField from '../../input/BasicTextField'
import Button from '@mui/material/Button'
import { AuthContext } from '../../context/auth'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'

interface IFields {
  password: string
  passwordCheck: string
}

interface IProps extends DialogProps {
  onUpdate?: () => void
}

export default function UpdatePasswordDialog(props: IProps) {

  const { onUpdate, ...dialogProps } = props

  // context
  const { changePassword } = React.useContext(AuthContext)
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    password: '',
    passwordCheck: '',
  });
  // 驗證欄位是否完整
  const verify = React.useMemo(() => {
    return fields.password.length > 0
      && fields.passwordCheck.length > 0
      && fields.password === fields.passwordCheck
  }, [fields])

  /**
   * init
   */
  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        password: '',
        passwordCheck: '',
      })
    }
  }, [dialogProps.open])

  const update = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('更改密碼中')
        await changePassword(fields.password);
        snackbar.show('更改成功')
        onUpdate && onUpdate()
      } catch (e: any) {
        snackbar.show('更改失敗：' + e.message, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, onUpdate])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>變更密碼</DialogTitle>
      <DialogContent>
        {/* 密碼 */}
        <BasicTextField
          label="密碼"
          value={fields.password}
          onChange={e => setFields(pv => ({ ...pv, password: e.target.value }))}
          type="password"
        />
        {/* 確認密碼 */}
        <BasicTextField
          label="確認密碼"
          value={fields.passwordCheck}
          onChange={e => setFields(pv => ({ ...pv, passwordCheck: e.target.value }))}
          type="password"
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={update}
        >
          變更
        </Button>
        <Button onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}