
export interface IAttributes {
  name: string
}

export const CLASS_NAME = 'Company'

export class Company extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, Company)