import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import BasicTextField from '../../input/BasicTextField'
import { Customer } from '../../parse-class/Customer'


interface IFields {
  name: string
}

interface IParams {
  open: boolean
  onClose: () => void
  onChange: () => void
}

export default function AddDialog(props: IParams) {

  const { open, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // state
  const [fields, setFields] = React.useState<IFields>({
    name: ''
  })

  const verify = React.useMemo(() => {
    return fields.name.trim().length > 0
  }, [fields])

  React.useEffect(() => {
    if (open) {
      setFields({
        name: ''
      })
    }
  }, [open])

  const onAdd = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const customer = new Customer()
        customer.set('name', fields.name)
        await customer.save()
        snackbar.show('新增成功')
        onChange() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, onChange])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle>新增客戶</DialogTitle>
      <DialogContent>
        <BasicTextField
          label="名稱"
          value={fields.name}
          onChange={e => setFields(pv => ({ ...pv, name: e.target.value }))}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onAdd}
        >
          新增
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}