import React from 'react'
import Container from '@mui/material/Container'
import { DataGridPremium, GridColumns, GridActionsCellItem, GridSortModel } from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { RecordDialog, IRecordData } from './RecordDialog'
import { ZCompany } from '../../parse-class/ZCompany'
import { ZPurchase } from '../../parse-class/ZPurchase'
import { ZSales } from '../../parse-class/ZSales'

enum FIELDS {
  COMPANY = 'COMPANY',
  NAME = 'NAME',
  PURCHASE = 'PURCHASE',
  SALES = 'SALES',
}

export function ZCompanyPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [companyData, setCompanyData] = React.useState<ZCompany[] | null>(null)
  // state
  const [recordData, setRecordData] = React.useState<IRecordData[] | null>(null)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.PURCHASE, sort: 'desc' }, // 按照銷售額降序排列
  ])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(ZCompany)
        .limit(9999999)
        .find()
      setCompanyData(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const showPurchaseRecord = React.useCallback(async (company: ZCompany) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(ZPurchase)
        .equalTo('company', company)
        // @ts-ignore
        .include('details.item')
        .descending('date')
        .limit(500)
        .find()
      const result: IRecordData[] = []
      for (const purchase of data) {
        for (const detail of purchase.get('details')!) {
          result.push({
            date: purchase.get('date')!,
            item: detail.item.get('shortName')!,
            qty: detail.qty,
            price: detail.cost,
          })
        }
      }
      setRecordData(result)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSalesRecord = React.useCallback(async (company: ZCompany) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(ZSales)
        .equalTo('company', company)
        // @ts-ignore
        .include('details.item')
        .descending('date')
        .limit(500)
        .find()
      const result: IRecordData[] = []
      for (const sales of data) {
        for (const detail of sales.get('details')!) {
          result.push({
            date: sales.get('date')!,
            item: detail.item.get('shortName')!,
            qty: detail.qty,
            price: detail.price,
          })
        }
      }
      setRecordData(result)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = React.useMemo(() => {
    if (companyData === null) {
      return []
    }
    return companyData.map(company => ({
      id: company.id,
      [FIELDS.COMPANY]: company,
      [FIELDS.NAME]: company.get('shortName'),
      [FIELDS.PURCHASE]: Math.round(company.get('cache')?.purchase!),
      [FIELDS.SALES]: Math.round(company.get('cache')?.sales!),
    }))
  }, [companyData])

  const columns: GridColumns = React.useMemo(() => [
    { field: FIELDS.NAME, headerName: '公司', width: 300 },
    { field: FIELDS.PURCHASE, headerName: '年採購額', width: 150, type: 'number' },
    { field: FIELDS.SALES, headerName: '年銷售額', width: 150, type: 'number' },
    {
      field: 'purchase',
      headerName: '採購',
      type: 'actions',
      width: 70,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="採購"
            color="primary"
            onClick={() => { showPurchaseRecord(row[FIELDS.COMPANY]) }}
          />,
        ]
      }
    },
    {
      field: 'sales',
      headerName: '銷售',
      type: 'actions',
      width: 70,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="銷售"
            color="error"
            onClick={() => { showSalesRecord(row[FIELDS.COMPANY]) }}
          />,
        ]
      }
    }
  ], [showPurchaseRecord, showSalesRecord])

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 3 }}>
        <StandardBox>
          <StandardBoxTitle title="公司分析" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              disableSelectionOnClick
              pagination
              autoPageSize
              loading={companyData === null}
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
            />
          </Box>
        </StandardBox>
      </Container>
      <RecordDialog
        open={recordData !== null}
        onClose={() => { setRecordData(null) }}
        maxWidth="md"
        fullWidth
        data={recordData}
      />
    </React.Fragment>
  )
}

