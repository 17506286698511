import { createTheme } from '@mui/material/styles'
import { zhTW as coreZhTW } from '@mui/material/locale'
import { zhTW as xDataGridPremiumZhTW } from '@mui/x-data-grid-premium'

export const theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans TC', 'Roboto'].join(',')
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  },
  palette: {
    primary: {
      light: '#58a5f0',
      main: '#0277bd',
      dark: '#004c8c',
      contrastText: '#ffffff',
    },
  },
},
  coreZhTW,
  xDataGridPremiumZhTW,
);