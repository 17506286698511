import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import { Customer } from '../../parse-class/Customer'

interface IProps extends DialogProps {
  customer: Customer | null
  onClickEdit: () => void
}

export default function CustomerInfoDialog(props: IProps) {

  const { customer, onClickEdit, ...dialogProps } = props

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{customer?.get('name')}</DialogTitle>
      <DialogContent>
        {/* Note */}
        <Typography variant="body1">
          {customer?.get('note')}
        </Typography>
        {/* Tags */}
        <Box my={1}>
          {customer?.get('tags')?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              variant="outlined"
              size="small"
            />
          ))}
        </Box>
        {/* Link to Map */}
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            if (!customer) return
            const googlePlaceId = customer.get('googlePlaceId') || ''
            const position = customer.get('position')
            const url = googlePlaceId?.length > 0 ?
              `https://www.google.com/maps/search/?api=1&query=0,0&query_place_id=${googlePlaceId}` :
              `https://maps.google.com/?q=${position?.latitude},${position?.longitude}`
            window.open(url, '_blank')
          }}
        >
          在 Google 地圖上查看
        </Link>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClickEdit}
        >
          編輯
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}