import './init-parse'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ClientPreferenceProvider } from './context/client-preference'
import { SnackbarProvider } from './context/snackbar'
import { BackdropProvider } from './context/backdrop'
import { ConfirmProvider } from './context/confirm'
import { AuthProvider } from './context/auth'
import { theme } from './theme'
import { Router } from './router'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.Fragment>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <ClientPreferenceProvider>
        <SnackbarProvider>
          <BackdropProvider>
            <ConfirmProvider>
              <AuthProvider>
                <Router />
              </AuthProvider>
            </ConfirmProvider>
          </BackdropProvider>
        </SnackbarProvider>
      </ClientPreferenceProvider>
    </ThemeProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
