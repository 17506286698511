import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import { TaxEntity } from '../../parse-class/TaxEntity'

interface IFields {
  nickname: string
  taxTitle: string
  taxId: string
}

interface IParams extends DialogProps {
  editingId: string | null
  onUpdate: () => void
}
export default function EditDialog(props: IParams) {

  const { editingId, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [raw, setRaw] = React.useState<TaxEntity | null>(null)
  // state
  const [fields, setFields] = React.useState<IFields>({
    nickname: '',
    taxTitle: '',
    taxId: '',
  })

  const verify = React.useMemo(() => {
    return fields.nickname.trim().length > 0
      && fields.taxTitle.trim().length > 0
      && fields.taxId.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(TaxEntity).get(id)
      setRaw(data)
      setFields({
        nickname: data.get('nickname') || '',
        taxTitle: data.get('taxTitle') || '',
        taxId: data.get('taxId') || '',
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && editingId !== null) {
      loadData(editingId)
    }
  }, [dialogProps.open, editingId, loadData])

  const onSave = React.useCallback(async () => {
    if (verify && raw) {
      try {
        backdrop.show('更新資料中')
        raw.set('nickname', fields.nickname)
        raw.set('taxTitle', fields.taxTitle)
        raw.set('taxId', fields.taxId)
        await raw.save()
        snackbar.show('更新成功')
        onUpdate() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
  }, [editingId, raw, fields, onUpdate])// eslint-disable-line react-hooks/exhaustive-deps

  if (editingId === null || raw === null) {
    return null
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>編輯抬頭統編</DialogTitle>
      <DialogContent>
        <BasicTextField
          label="名稱"
          value={fields.nickname}
          onChange={e => setFields(pv => ({ ...pv, nickname: e.target.value }))}
          autoFocus
        />
        <BasicTextField
          disabled
          label="統編"
          value={fields.taxId}
        // onChange={e => setFields(pv => ({ ...pv, taxId: e.target.value }))}
        />
        <BasicTextField
          disabled
          label="抬頭"
          value={fields.taxTitle}
        // onChange={e => setFields(pv => ({ ...pv, taxTitle: e.target.value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}