import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { useCheckMobile } from '../../context/user-check-mobile'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import { TaxEntity } from '../../parse-class/TaxEntity'

export const COLUMNS = {
  NAME: 'NAME',
  TAX_TITLE: 'TAX_TITLE',
  TAX_ID: 'TAX_ID',
}

export function TaxEntityPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  const isMobile = useCheckMobile()
  // data
  const [taxEntityList, setTaxEntityList] = React.useState<TaxEntity[] | null>(null)
  // state
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(TaxEntity)
      query.limit(10000)
      query.descending('createdAt')
      const data = await query.find()
      setTaxEntityList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const taxEntity = TaxEntity.createWithoutData(id)
      await taxEntity.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!taxEntityList) {
      return []
    }
    return taxEntityList.map(taxEntity => ({
      id: taxEntity.id,
      [COLUMNS.NAME]: taxEntity.get('nickname'),
      [COLUMNS.TAX_ID]: taxEntity.get('taxId'),
      [COLUMNS.TAX_TITLE]: taxEntity.get('taxTitle'),
    }))
  }, [taxEntityList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: COLUMNS.NAME,
        headerName: '名稱',
        width: 220,
      },
      {
        field: COLUMNS.TAX_ID,
        headerName: '統編',
        width: 100,
      },
      {
        field: COLUMNS.TAX_TITLE,
        headerName: '抬頭',
        width: 220,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 75,
        getActions: ({ id }) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="編輯"
            onClick={() => { setEditingId(id as string) }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="刪除"
            color="error"
            onClick={() => { onDelete(id as string) }}
          />,
        ]
      }
    ]
  }, [onDelete])

  if (taxEntityList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="抬頭統編" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                  </GridToolbarContainer>
                )
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      {/* Add Dialog */}
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        onUpdate={() => {
          loadData()              // 載入新資料
          setShowAddDialog(false) // 關閉視窗
        }}
      />
      {/* Edit Dailog */}
      <EditDialog
        open={editingId !== null}
        onClose={() => { setEditingId(null) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        editingId={editingId}
        onUpdate={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}
