import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer, GridSortModel, GridSelectionModel } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import PrintIcon from '@mui/icons-material/Print'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { useCheckMobile } from '../../context/user-check-mobile'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import PrintSampleLabelDialog from './PrintSampleLabelDialog'
import { MarketingSample } from '../../parse-class/MarketingSample'

export const FIELDS = {
  DATE: 'DATE',
  CUSTOMER: 'CUSTOMER',
  ITEM: 'ITEM',
  NOTE: 'NOTE',
}

export function MarketingSamplePage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  const isMobile = useCheckMobile()
  // data
  const [marketingSampleList, setMarketingSampleList] = React.useState<MarketingSample[] | null>(null)
  // state
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc' } // 按照日期降序排列
  ])
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([])
  const [printingIds, setPrintingIds] = React.useState<string[] | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(MarketingSample)
      query.limit(10000)
      query.include('customer')
      query.include('item')
      query.descending('createdAt')
      const data = await query.find()
      setMarketingSampleList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const marketingNote = MarketingSample.createWithoutData(id)
      await marketingNote.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  /**
   * 將資料轉為Grid格式
   */
  const rows = React.useMemo(() => {
    if (!marketingSampleList) {
      return []
    }
    return marketingSampleList.map(marketingNote => (
      {
        id: marketingNote.id,
        [FIELDS.DATE]: marketingNote.get('date'),
        [FIELDS.CUSTOMER]: marketingNote.get('customer'),
        [FIELDS.ITEM]: marketingNote.get('item'),
        [FIELDS.NOTE]: marketingNote.get('note'),
      }
    ))
  }, [marketingSampleList])

  /**
   * 定義欄位
   */
  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 100,
      },
      {
        field: FIELDS.CUSTOMER,
        headerName: '客戶',
        width: 200,
        valueGetter: params => params.row[FIELDS.CUSTOMER].get('name') || '未知的客戶',
      },
      {
        field: FIELDS.ITEM,
        headerName: '貨物',
        width: 300,
        valueGetter: params => params.row[FIELDS.ITEM].getFormatName({ pkg: false }),
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 350,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 80,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      }
    ]
  }, [onDelete])

  /**
   * 取得資料後才顯示
   */
  if (marketingSampleList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="樣品配送" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              // selection
              onSelectionModelChange={selectionModel => { setSelectionModel(selectionModel) }}
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setShowAddDialog(true)}
                    >
                      新增
                    </Button>
                    <Button
                      disabled={selectionModel.length === 0}
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setPrintingIds(selectionModel as string[]) }}
                    >
                      標籤貼紙
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        onChange={() => {
          // 載入新資料
          loadData()
          // 關閉視窗
          setShowAddDialog(false)
        }}
      />
      <EditDialog
        open={editingId !== null}
        onClose={() => { setEditingId(null) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        editingId={editingId}
        onChange={() => {
          // 載入新資料
          loadData()
          // 關閉視窗
          setEditingId(null)
        }}
      />
      <PrintSampleLabelDialog
        open={printingIds !== null}
        onClose={() => { setPrintingIds(null) }}
        fullWidth
        maxWidth='md'
        sampleIds={printingIds}
      />
    </React.Fragment>
  )
}