import React from 'react'

interface IProps extends React.PropsWithChildren {
    authentication: boolean
}

/**
 * 導覽列權限認證
 */
export default function Authentication(props: IProps) {

    const { authentication, children } = props

    return (
        <React.Fragment>
            {authentication ? children : null}
        </React.Fragment>
    )
}