import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import OptionsDialog from './OptionsDialog'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import { InventoryAdjustment } from '../../parse-class/InventoryAdjustment'

export const FIELDS = {
  DATE: 'DATE',
  ITEM: 'ITEM',
  QTY: 'QTY',
  NOTE: 'NOTE',
}

export function InventoryAdjustmentPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [adjustmentList, setAdjustmentList] = React.useState<InventoryAdjustment[] | null>(null)
  // state
  const [group, setGroup] = React.useState<string | null>(null)
  const [showOptionsDialog, setShowOptionsDialog] = React.useState(true)
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    if (!group) {
      return setAdjustmentList([])
    }
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(InventoryAdjustment)
      query.limit(10000)
      query.equalTo('group', group)
      query.descending('createdAt')
      query.include('item')
      const data = await query.find()
      setAdjustmentList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const data = InventoryAdjustment.createWithoutData(id)
      await data.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const title = React.useMemo(() => {
    if (group) {
      return `庫存調整（${group}）`
    } else {
      return '庫存調整'
    }
  }, [group])

  const rows = React.useMemo(() => {
    if (!adjustmentList) {
      return []
    }
    return adjustmentList.map(adjust => ({
      id: adjust.id,
      [FIELDS.DATE]: adjust.get('date'),
      [FIELDS.ITEM]: adjust.get('item'),
      [FIELDS.QTY]: adjust.get('qty'),
      [FIELDS.NOTE]: adjust.get('note'),
    }))
  }, [adjustmentList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 100,
      },
      {
        field: FIELDS.ITEM,
        headerName: '貨物',
        width: 300,
        valueGetter: params => params.row[FIELDS.ITEM].getFormatName(),
      },
      {
        field: FIELDS.QTY,
        headerName: '數量',
        width: 80,
        type: 'number',
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 350,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 75,
        getActions: ({ id }) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="編輯"
            onClick={() => { setEditingId(id as string) }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="刪除"
            color="error"
            onClick={() => { onDelete(id as string) }}
          />,
        ]
      }
    ]
  }, [onDelete])

  if (adjustmentList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title={title} />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      disabled={!group}
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<SettingsIcon />}
                      onClick={() => { setShowOptionsDialog(true) }}
                    >
                      選項
                    </Button>
                  </GridToolbarContainer>
                )
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      {/*  */}
      <OptionsDialog
        open={showOptionsDialog}
        onClose={() => { setShowOptionsDialog(false) }}
        fullWidth
        maxWidth="sm"
        onUpdate={options => {
          setGroup(options.group)
          setShowOptionsDialog(false)
        }}
      />
      {/* Add Dialog */}
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        fullWidth
        maxWidth="sm"
        group={group!}
        onUpdate={() => {
          loadData()              // 載入新資料
          setShowAddDialog(false) // 關閉視窗
        }}
      />
      {/* Edit Dailog */}
      <EditDialog
        open={editingId !== null}
        onClose={() => { setEditingId(null) }}
        fullWidth
        maxWidth="sm"
        editingId={editingId}
        onUpdate={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}
