import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import BasicTextField from '../../input/BasicTextField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import ItemAutocomplete from '../../input/ItemAutocomplete'
import { Customer } from '../../parse-class/Customer'
import { Item } from '../../parse-class/Item'
import { MarketingSample } from '../../parse-class/MarketingSample'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  customer: Customer | null
  item: Item | null
  note: string
}

interface IParams extends DialogProps {
  onChange: () => void
}

export default function AddDialog(props: IParams) {

  const { onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    customer: null,
    item: null,
    note: '',
  })

  const verify = React.useMemo(() => {
    return fields.customer !== null
      && fields.item !== null
  }, [fields])


  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        customer: null,
        item: null,
        note: ''
      })
    }
  }, [dialogProps.open])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const marketingSample = new MarketingSample()
        marketingSample.set('date', fields.date)
        marketingSample.set('customer', fields.customer!)
        marketingSample.set('item', fields.item!)
        marketingSample.set('note', fields.note)
        await marketingSample.save()
        snackbar.show('新增成功')
        onChange() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, onChange])

  return (
    <Dialog {...dialogProps}  >
      <DialogTitle>新增樣品</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, date: value }))
          }}
        />
        <CustomerAutocomplete
          value={fields.customer}
          onChange={(event, value) => setFields(pv => ({ ...pv, customer: value }))}
          textFieldProps={{ autoFocus: true }}
        />
        <ItemAutocomplete
          value={fields.item}
          onChange={(event, value) => setFields(pv => ({ ...pv, item: value }))}
        />
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}