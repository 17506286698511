import dayjs from 'dayjs'
import { PosPrinter } from '../parse-class/PosPrinter'
import { PosPrinterTask } from '../parse-class/PosPrinterTask'
import { TransactionCustomer } from '../parse-class/TransactionCustomer'
import { User } from '../parse-class/User'
import { Item } from '../parse-class/Item'

namespace TaskHelper {

  interface IOptions {
    transaction: TransactionCustomer
    user: User
  }

  interface IParams {
    id: string
    date: string
    customer: string
    salesperson: string
    cellphone: string
    receivable: number
    bill: boolean
    details: {
      name: string
      pkg: string
      price: number
      qty: number
    }[]
  }

  function formatItemName(item: Item) {
    const name = item.get('name') || ''
    const spec = item.get('spec') || ''
    return name + (spec ? `【${spec}】` : '')
  }

  export function createParams(options: IOptions) {
    const { transaction, user } = options
    const date = transaction.get('date')
    const customer = transaction.get('customer')
    const receivable = transaction.sum()
    const details = transaction.get('details') || []
    const params: IParams = {
      id: transaction.id,
      date: date ? dayjs(date).format('YYYY-MM-DD') : '',
      customer: customer?.get('name') || '未知的客戶',
      salesperson: user?.get('name') || '',
      cellphone: user?.get('cellphone') || '',
      receivable: receivable,
      bill: customer?.get('bill') || false,
      details: details.map(({ item, qty, price }) => {
        return {
          name: item ? formatItemName(item) : '未知的貨物',
          pkg: item.get('pkg') || '',
          price: price,
          qty: qty,
        }
      }),
    }
    return params
  }
}

export interface IOptions {
  printer: PosPrinter
  transactionIds: string[]
  user: User
}

export async function createDeliveryTask(options: IOptions) {
  // 取得transaction
  const { printer, transactionIds, user } = options
  const transactions = await new Parse.Query(TransactionCustomer)
    .containedIn('objectId', transactionIds)
    .ascending('deliveryOrder')               // 按照order排序
    // @ts-ignore
    .include('secondParty.customer')
    // @ts-ignore
    .include('details.item')
    .find()
  // 逐筆建立列印任務
  for (let transaction of transactions) {
    const params = TaskHelper.createParams({
      transaction,
      user,
    })
    const task = new PosPrinterTask()
    task.set('printer', printer)
    task.set('template', 'delivery')
    task.set('params', JSON.stringify(params))
    await task.save()
  }
}