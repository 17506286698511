import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { twCity } from '../taiwan-district'

type AutocompletePropsType = Omit<AutocompleteProps<string, false, false, false>, 'options' | 'renderInput'>;
export interface IProps extends AutocompletePropsType {
  textFieldProps?: TextFieldProps;
}

export default function CityAutocomplete(props: IProps) {

  const { textFieldProps, ...autocompleteProps } = props;

  return (
    <Autocomplete
      renderInput={autocompleteRenderInputParams =>
        <TextField
          {...autocompleteRenderInputParams}
          margin="normal"
          variant="outlined"
          label="縣市"
          // 關閉Chrome的自動完成功能
          inputProps={{ ...autocompleteRenderInputParams.inputProps, autoComplete: "new-password" }}
          {...props.textFieldProps}
        />
      }
      options={twCity}
      getOptionLabel={city => city}
      fullWidth
      {...autocompleteProps}
    />
  )
}