import dayjs from 'dayjs'
import React from 'react'
import { DataGridPremium, GridColumns, GridRowModel } from '@mui/x-data-grid-premium'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { Provider } from '../../parse-class/Provider'
import { TransactionProvider } from '../../parse-class/TransactionProvider'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'

export const FIELDS = {
  ITEM: 'ITEM',
  MSG: 'MSG',
}

interface IParams extends DialogProps {
  provider: Provider | null
}

export default function CheckDialog(props: IParams) {

  const { provider, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [rows, setRows] = React.useState<GridRowModel[]>([])

  const loadData = React.useCallback(async () => {
    if (!provider) return
    try {
      backdrop.show('讀取資料中')
      const rows: GridRowModel[] = []
      const helperList = await new Parse.Query(PurchaseHelper)
        .limit(10000)
        .equalTo('provider', provider)
        .select('available', 'item')
        .find()
      /**
       * 一個月內有採購，但卻沒有在採購助手清單中
       */
      const transactions = await new Parse.Query(TransactionProvider)
        .limit(10000)
        .equalTo('provider', provider)
        // @ts-ignore
        .select('details.item')
        // @ts-ignore
        .include('details.item')
        .greaterThanOrEqualTo('date', dayjs().subtract(1, 'month').toDate()) // 一個月內
        .find()
      const transactionItems = transactions
        // 取出貨物
        .flatMap(t => t.get('details')!.map(d => d.item))
        // 去除重複
        .filter((item, index, array) => array.findIndex(i => i.id === item.id) === index)
      transactionItems
        // 沒有在採購助手清單中
        .filter(item => helperList.find(helper => helper.get('item')!.id === item.id) === undefined)
        // 產生訊息
        .forEach(item => {
          rows.push({
            id: item.id,
            [FIELDS.ITEM]: item,
            [FIELDS.MSG]: '一個月內有採購，卻沒在採購清單中'
          })
        })
      /**
       * 一個月內沒交易，但卻在採購助手清單中有效
       */
      helperList
        // 有效
        .filter(helper => helper.get('available'))
        //  沒有在一個月內交易貨物中
        .filter(helper => transactionItems.find(i => i.id === helper.get('item')!.id) === undefined)
        // 產生訊息
        .forEach(helper => {
          const item = helper.get('item')!
          rows.push({
            id: item.id,
            [FIELDS.ITEM]: item,
            [FIELDS.MSG]: '一個月以上沒採購，卻在有效採購清單中有效'
          })
        })
      setRows(rows)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
    }
  }, [dialogProps.open, loadData])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.ITEM,
        headerName: '貨物',
        width: 350,
        valueGetter: params => params.row[FIELDS.ITEM].getFormatName(),
      },
      {
        field: FIELDS.MSG,
        headerName: '提示',
        width: 400,
        editable: true
      },
    ]
  }, [])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>檢查</DialogTitle>
      <DialogContent>
        <DataGridPremium
          rows={rows}
          columns={columns}
          density="compact"
          disableSelectionOnClick
          autoHeight
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}