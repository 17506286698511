import dayjs from 'dayjs'
import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import BasicNumberField from '../../input/BasicNumberField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Customer } from '../../parse-class/Customer'
import { TransactionCustomer, ITransactionDetail } from '../../parse-class/TransactionCustomer'

interface IFields {
  date: Date
  target: Customer | null
  note: string
  details: ITransactionDetail[]
  deliveryTime: dayjs.Dayjs | null
  paymentTime: dayjs.Dayjs | null
  allowance: number
}

interface IProps extends DialogProps {
  editingId: string | null
  onChange: () => void
}

export default function CustomerTransactionEditDialog(props: IProps) {

  const { editingId, onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data 
  const [raw, setRaw] = React.useState<TransactionCustomer | null>(null)
  // state
  const [fields, setFields] = React.useState<IFields>()

  const verify = React.useMemo(() => {
    return fields
      && fields.target !== null
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(TransactionCustomer).get(id)
      setRaw(data)
      setFields({
        date: data.get('date')!,
        target: data.get('customer')!,
        note: data.get('note')!,
        details: data.get('details')!,
        deliveryTime: data.get('deliveryTime') ? dayjs(data.get('deliveryTime')) : null,
        paymentTime: data.get('paymentTime') ? dayjs(data.get('paymentTime')) : null,
        allowance: data.get('allowance')!,
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && editingId) {
      loadData(editingId)
    } else {
      setFields(undefined)
    }
  }, [dialogProps.open, editingId, loadData])

  const onSave = React.useCallback(async () => {
    if (raw && fields && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('date', fields.date)
        raw.set('customer', fields.target!)
        raw.set('details', fields.details)
        raw.set('note', fields.note)
        fields.deliveryTime ? raw.set('deliveryTime', fields.deliveryTime.toDate()) : raw.unset('deliveryTime')
        fields.paymentTime ? raw.set('paymentTime', fields.paymentTime.toDate()) : raw.unset('paymentTime')
        raw.set('allowance', fields.allowance)
        await raw.save()
        snackbar.show('更新成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, raw, fields, onChange])

  /**
   * 計算交易金額
   */
  const amount = React.useMemo(() => {
    if (fields) {
      const sum = fields.details.reduce((pre, cur) => pre + Math.round(cur.qty * cur.price), 0)
      return sum - fields.allowance
    } else {
      return 0
    }
  }, [fields])

  if (editingId === null || raw === null || fields === undefined) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogContent>
          <CustomerAutocomplete
            disabled
            value={fields.target}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={props => <BasicTextField {...props} />}
              label="物流完成時戳"
              inputFormat="YYYY-MM-DD HH:mm"
              value={fields.deliveryTime}
              onChange={value => { setFields(pv => ({ ...pv!, deliveryTime: value })) }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={props => <BasicTextField {...props} />}
              label="金流完成時戳"
              inputFormat="YYYY-MM-DD HH:mm"
              value={fields.paymentTime}
              onChange={value => { setFields(pv => ({ ...pv!, paymentTime: value })) }}
            />
          </LocalizationProvider>
          <BasicNumberField
            label="折讓"
            defaultValue={fields.allowance}
            onChange={e => setFields(pv => ({ ...pv!, allowance: Number(e.target.value) }))}
          />
          <BasicNumberField
            disabled
            label="總額"
            value={amount}
          />
          <BasicTextField
            label="備註"
            defaultValue={fields.note}
            onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            儲存
          </Button>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
          <Button
            disabled={fields.deliveryTime !== null}
            color="success"
            onClick={() => { setFields(pv => ({ ...pv!, deliveryTime: dayjs() })) }}
          >
            物流完成
          </Button>
          <Button
            disabled={fields.paymentTime !== null}
            color="success"
            onClick={() => { setFields(pv => ({ ...pv!, paymentTime: dayjs() })) }}
          >
            金流完成
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}