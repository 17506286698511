import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { DataGridPremium, GridColumns, useGridApiRef } from '@mui/x-data-grid-premium'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { MarketingSample } from '../../parse-class/MarketingSample'
import { downloadSampleLabelPDF, ISampleLabel } from '../../cloud/download-sample-label-pdf'

export const FIELDS = {
  CUSTOMER_NAME: 'CUSTOMER_NAME',
  ITEM_NAME: 'ITEM_NAME',
  PRICE: 'PRICE',
}

export interface IProps extends DialogProps {
  sampleIds: string[] | null
}

const formater = Intl.NumberFormat('zh-tw', { maximumFractionDigits: 1 })

export default function PrintSampleLabelDialog(props: IProps) {

  const { sampleIds, ...dialogProps } = props
  // ref
  const apiRef = useGridApiRef()
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data 
  const [marketingSampleList, setMarketingSampleList] = React.useState<MarketingSample[] | null>(null)

  const loadData = React.useCallback(async (ids: string[]) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(MarketingSample)
      query.containedIn('objectId', ids)
      query.include('customer')
      query.include('item')
      const data = await query.find()
      setMarketingSampleList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && sampleIds) {
      loadData(sampleIds)
    }
  }, [dialogProps.open, sampleIds, loadData])

  const onClickResult = React.useCallback(async () => {
    try {
      backdrop.show('產生檔案中')
      // formatter
      const rows = apiRef.current.getRowModels()
      const labels: ISampleLabel[] = []
      rows.forEach(row => {
        labels.push({
          customerName: row[FIELDS.CUSTOMER_NAME],
          itemName: row[FIELDS.ITEM_NAME],
          price: row[FIELDS.PRICE]
        })
      })
      const download = await downloadSampleLabelPDF({ labels })
      // download
      const filename = `樣品標籤${dayjs().format('YYYYMMDD-HHmmss')}.pdf`
      let a = document.createElement('a')
      a.href = download.get('file')!.url()
      a.download = filename
      a.target = '_blank'
      a.click()
      snackbar.show('產生檔案成功')
    } catch (e: any) {
      snackbar.show('產生檔案失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
  }, [apiRef]) // eslint-disable-line react-hooks/exhaustive-deps

  const rows = React.useMemo(() => {
    if (!marketingSampleList) {
      return []
    }
    return marketingSampleList.map(marketingNote => {
      const customer = marketingNote.get('customer')
      const item = marketingNote.get('item')
      const pricing = item?.getDefaultPricing()
      let price = ''
      if (pricing && item) {
        price = `${pricing.price}元/${item.get('pkg')}`
        const unitName = item.get('unitName') || ''
        const unitQty = item.get('unitQty') || 1
        if (unitName.length > 0) {
          const exact = item.get('unitExact') ? '' : '約'
          const avg = pricing.price / unitQty
          price += `（${exact}${unitQty}${unitName}→${formater.format(avg)}元/${unitName}）`
        }
      }
      return {
        id: marketingNote.id,
        [FIELDS.CUSTOMER_NAME]: customer?.get('name') || '',
        [FIELDS.ITEM_NAME]: item?.getFormatName() || '',
        [FIELDS.PRICE]: price,
      }
    })
  }, [marketingSampleList])

  const columns: GridColumns = [
    {
      field: FIELDS.CUSTOMER_NAME,
      headerName: '客戶名稱',
      width: 160,
      editable: true,
    },
    {
      field: FIELDS.ITEM_NAME,
      headerName: '品名',
      width: 240,
      editable: true,
    },
    {
      field: FIELDS.PRICE,
      headerName: '價格',
      width: 240,
      editable: true,
    },
  ]

  if (apiRef === null) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>列印樣品標籤貼紙</DialogTitle>
        <DialogContent>
          <DataGridPremium
            apiRef={apiRef}
            autoHeight
            density="compact"
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            disableColumnReorder
            disableColumnMenu
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClickResult}
          >
            列印
          </Button>
          <Button
            color="error"
            onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

