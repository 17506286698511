import React from 'react'
import { DataGridPro, GridColumns, useGridApiRef, GridRowsProp, GridRowId } from '@mui/x-data-grid-pro'
import { GridItemAutocomplete } from '../../input/GridItemAutocomplete'
import { Item } from '../../parse-class/Item'
import { IQuotationDetail } from '../../parse-class/SalesQuotation'

export const FIELDS = {
  ITEM: 'ITEM',
  PRICE: 'PRICE',
}

export interface IProps {
  defaultValue?: IQuotationDetail[]
  onUpdate?: (rows: IQuotationDetail[]) => void
  autoApplyPricing?: 'COST' | 'PRICE'
}

let idCounter = 0

export default function QuotationDetailGrid(props: IProps) {

  const { defaultValue, onUpdate, autoApplyPricing } = props
  // ref
  const apiRef = useGridApiRef()
  // data
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [items, setItems] = React.useState<Item[]>([])

  const loadData = React.useCallback(async () => {
    const data = await new Parse.Query(Item)
      .limit(10000)
      .equalTo('available', true)
      .find()
    setItems(data)
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 初始化：如果有預設資料就用預設資料
   * 用useEffect，而不用useMemo，是為了能觸發計算sum的功能
   */
  React.useEffect(() => {
    if (defaultValue) {
      setRows(defaultValue.map(row => ({
        id: idCounter++,
        [FIELDS.ITEM]: row.item,
        [FIELDS.PRICE]: row.price,
      })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 在最後插入一列
   */
  const insertRow = React.useCallback(async () => {
    // 加入一列
    const newId = idCounter++
    apiRef.current.updateRows([{
      id: newId,
      [FIELDS.ITEM]: null,
      [FIELDS.PRICE]: 0,
    }])
    // 將焦點放在該列第一欄
    apiRef.current.startCellEditMode({ id: newId, field: FIELDS.ITEM })
  }, [apiRef])

  /**
   * 刪除指定列
   */
  const deleteRow = React.useCallback((rowId: GridRowId, field: string) => {
    // 切換焦點：如果存在不只一列，就進行切換。如果不是在第一列，就向上切換，否則向下切換。
    const ids = apiRef.current.getAllRowIds()
    if (ids.length > 1) {
      const index = ids.findIndex(id => id === rowId)
      if (index === 0) {
        apiRef.current.setCellFocus(ids[1], field)
      } else {
        apiRef.current.setCellFocus(ids[index - 1], field)
      }
    } else {
      // 如果刪除後就沒有資料了，移動到表頭
      apiRef.current.setColumnHeaderFocus(field)
    }
    // 調用API刪除指定列
    apiRef.current.updateRows([{ id: rowId, _action: 'delete' }])
  }, [apiRef])

  /**
   * 在表頭中的鍵盤事件
   */
  React.useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderKeyDown', (params, event) => {
      switch (event.key) {
        /**
         * 按下「⇩」：如沒有任何資料，就新增一列
         */
        case 'ArrowDown':
          // 確認沒有任何資料
          if (apiRef.current.getRowsCount() === 0) {
            insertRow()
          }
          break
      }
    })
  }, [apiRef, insertRow])

  /**
   * 在儲存格中的鍵盤事件
   */
  React.useEffect(() => apiRef.current.subscribeEvent('cellKeyDown', (params, event) => {
    switch (event.key) {
      /**
       * 按下「⇩」：如果是檢視模式，且是在最後一列，則新增一列。
       */
      case 'ArrowDown':
        if (params.cellMode === 'view') {
          // 如果是在最後一列，就新增列
          const lastId = [...apiRef.current.getAllRowIds()].pop()
          if (params.id === lastId) {
            insertRow()
          }
        }
        break
      /**
        * 按下「Delete」：如果是檢視模式，則刪除整列。
        */
      case 'Delete':
        if (params.cellMode === 'view') {
          // 避免原先Delete清除Cell的動作
          event.defaultMuiPrevented = true
          deleteRow(params.id, params.field)
        }
        break
    }
  }), [apiRef, insertRow, deleteRow])

  /**
   * 套用預設價格：如果是貨物欄位更新，就更新預設價格
   */
  React.useEffect(() => apiRef.current.subscribeEvent('cellEditStop', async (params, event) => {
    const { id, field } = params
    if (field === FIELDS.ITEM && autoApplyPricing) {
      const item: Item = apiRef.current.getCellValue(id, field) // params中的value是舊值
      if (item) {
        const pricing = item.getDefaultPricing()
        let price = 0
        if (!pricing) price = 0
        else if (autoApplyPricing === 'PRICE') price = pricing.price
        else if (autoApplyPricing === 'COST') price = pricing.cost
        apiRef.current.updateRows([{ id, [FIELDS.PRICE]: price }])
      }
    }
  }), [apiRef, autoApplyPricing])

  const handleUpdate = React.useCallback(() => {
    if (onUpdate) {
      const rows = Array.from(apiRef.current.getRowModels().values())
      onUpdate(rows
        .filter(row => row[FIELDS.ITEM] !== null)
        .map(row => ({
          item: row[FIELDS.ITEM],
          price: row[FIELDS.PRICE],
        })))
    }
  }, [apiRef, onUpdate])

  /**
   * 格子內容變更：觸發回呼，傳回表格資料
   */
  React.useEffect(() => apiRef.current.subscribeEvent('rowsSet', () => {
    handleUpdate()
  }), [apiRef, handleUpdate])

  const columns: GridColumns = React.useMemo(() => [
    {
      field: FIELDS.ITEM,
      headerName: '貨物',
      width: 400,
      editable: true,
      sortable: false,
      valueFormatter: params => {
        const item: Item | null = params.value
        return item ? item.getFormatName() : '（新增欄位）'
      },
      renderEditCell: params => (
        <GridItemAutocomplete
          gridRenderEditCellParams={params}
          data={items}
        />
      )
    },
    {
      field: FIELDS.PRICE,
      headerName: '價格',
      width: 90,
      type: 'number',
      editable: true,
      sortable: false,
    },
  ], [items])

  return (
    <DataGridPro
      sx={{ mt: 2, mb: 1 }}
      apiRef={apiRef}
      autoHeight
      density="compact"
      rows={rows}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
      disableSelectionOnClick
      disableColumnReorder
      disableColumnMenu
      // reorder
      rowReordering
      onRowOrderChange={param => {
        handleUpdate()
      }}
    />
  )
}