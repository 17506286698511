import React from 'react'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { ClientPreferenceContext } from '../../context/client-preference'
import { SnackbarContext } from '../../context/snackbar'
import PosPrinterAutocomplete from '../../input/PosPrinterAutocomplete'
import { PosPrinter } from '../../parse-class/PosPrinter'

interface IFields {
  defaultPosPrinter: PosPrinter | null
}

export function ClientPreferencePage() {

  // context
  const clientPreference = React.useContext(ClientPreferenceContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    defaultPosPrinter: null
  })

  const verify = React.useMemo(() => {
    return true
  }, [])

  React.useEffect(() => {
    const preference = clientPreference.get()
    setFields({
      defaultPosPrinter: preference.defaultPosPrinter || null
    })
  }, [clientPreference])

  const onSave = React.useCallback(async () => {
    if (verify) {
      clientPreference.set({
        defaultPosPrinter: fields.defaultPosPrinter
      })
      snackbar.show('儲存成功')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, fields, clientPreference])

  return (
    <Container maxWidth="sm" sx={{ pt: 2 }}>
      <StandardBox>
        <StandardBoxTitle title="客戶端偏好設定" />
        <PosPrinterAutocomplete
          value={fields.defaultPosPrinter}
          onChange={(event, value) => setFields(pv => ({ ...pv, defaultPosPrinter: value }))}
          textFieldProps={{ autoFocus: true, label: '預設出單機' }}
        />
        <Button
          disabled={!verify}
          color="primary"
          fullWidth
          onClick={onSave}
        >
          儲存
        </Button>
      </StandardBox>
    </Container>
  )
}