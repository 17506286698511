
export type TUserRole = 'SALESPERSON' | 'LOGISTICIAN'

export interface IAttributes {
  name: string
  cellphone: string
  roles: TUserRole[]
}

export const CLASS_NAME = '_User'

export class User extends Parse.User<Partial<IAttributes>> {

  constructor() {
    super({})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, User)