/**
 * 客戶端偏好設定
 */
import React from 'react'
import { PosPrinter } from '../parse-class/PosPrinter'

interface IClientPreference {
  defaultPosPrinter: PosPrinter | null
}

interface Context {
  get: () => IClientPreference
  set: (preference: IClientPreference) => void
}

export const ClientPreferenceContext = React.createContext<Context>({
  get: () => 0 as any,
  set: () => 0 as any,
})

export function ClientPreferenceProvider(props: React.PropsWithChildren) {

  const [preference, setPreference] = React.useState<IClientPreference>({
    defaultPosPrinter: null,
  })

  /**
   * 從LocalStorage讀取客戶端偏好設定
   */
  const loadData = React.useCallback(async () => {
    const defaultPosPrinterId = localStorage.getItem('defaultPosPrinterId')
    if (defaultPosPrinterId) {
      try {
        const defaultPosPrinter = await new Parse.Query(PosPrinter).get(defaultPosPrinterId)
        setPreference(pre => ({ ...pre, defaultPosPrinter }))
      } catch (e) {
        localStorage.removeItem('defaultPosPrinterId')
        console.error('載入客戶端偏好設定失敗：' + e)
      }
    }
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const get = React.useCallback(() => {
    return preference
  }, [preference])

  const set = React.useCallback((preference: IClientPreference) => {
    setPreference(preference)
    // 寫入LocalStorage
    if (preference.defaultPosPrinter) {
      localStorage.setItem('defaultPosPrinterId', preference.defaultPosPrinter.id)
    } else {
      localStorage.removeItem('defaultPosPrinterId')
    }
  }, [])

  return (
    <ClientPreferenceContext.Provider value={{ get, set }}>
      {props.children}
    </ClientPreferenceContext.Provider>
  )
}