import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import BasicTextField from '../../input/BasicTextField'
import CityAutocomplete from '../../input/CityAutocomplete'
import DistrictAutocomplete from '../../input/DistrictAutocomplete'
import { Customer } from '../../parse-class/Customer'

interface IFields {
  name: string;
  city: string | null;
  district: string | null;
  address: string;
  contactName: string;
  contactPhone: string;
  googlePlaceId: string;
  lat: number;
  lng: number;
  tags: string;
  note: string;
  bill: boolean;
}

interface IParams {
  id: string | null;
  onClose: () => void;
  onChange: () => void;
}

export default function EditDialog(props: IParams) {

  const { id, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // state
  const [tab, setTab] = React.useState(0)
  const [raw, setRaw] = React.useState<Customer | null>(null)
  const [fields, setFields] = React.useState<IFields>({
    name: '',
    city: null,
    district: null,
    address: '',
    contactName: '',
    contactPhone: '',
    googlePlaceId: '',
    lat: 0,
    lng: 0,
    tags: '',
    note: '',
    bill: false,
  })

  const verify = React.useMemo(() => {
    return fields.name.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Customer)
      const customer = await query.get(id)
      setRaw(customer)
      setFields({
        name: customer.get('name') || '',
        city: customer.get('city') || null,
        district: customer.get('district') || null,
        address: customer.get('address') || '',
        contactName: customer.get('contactName') || '',
        contactPhone: customer.get('contactPhone') || '',
        googlePlaceId: customer.get('googlePlaceId') || '',
        lat: customer.get('position')?.latitude || 0,
        lng: customer.get('position')?.longitude || 0,
        tags: customer.get('tags')?.join(',') || '',
        note: customer.get('note') || '',
        bill: customer.get('bill') || false,
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (id !== null) {
      loadData(id)
    }
  }, [id, loadData])

  const onSave = React.useCallback(async () => {
    if (id !== null && raw !== null && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('name', fields.name)
        raw.set('city', fields.city || '')
        raw.set('district', fields.district || '')
        raw.set('address', fields.address)
        raw.set('contactName', fields.contactName)
        raw.set('contactPhone', fields.contactPhone)
        raw.set('googlePlaceId', fields.googlePlaceId)
        if (fields.lat !== 0 && fields.lng !== 0) {
          raw.set('position', new Parse.GeoPoint({ latitude: fields.lat, longitude: fields.lng }))
        }
        raw.setTags(fields.tags.split(','))
        raw.set('note', fields.note)
        raw.set('bill', fields.bill)
        await raw.save()
        snackbar.show('更新成功')
        onChange(); // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, raw, verify, fields, onChange])

  // 等待從伺服器取得資料
  if (raw === null) {
    return null;
  }

  return (
    <Dialog
      open={id !== null}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <Tabs value={tab} onChange={(e, value) => { setTab(value) }}>
        <Tab label="客戶資料" />
        <Tab label="定位" />
      </Tabs>
      {tab === 0 &&
        <DialogContent>
          {/* 名稱 */}
          <BasicTextField
            label="名稱"
            value={fields.name}
            onChange={e => { setFields(pv => ({ ...pv, name: e.target.value })) }}
          />
          {/* 縣市 */}
          <CityAutocomplete
            value={fields.city}
            onChange={(event, value) => { setFields(pv => ({ ...pv, city: value, district: null })) }}
          />
          {/* 鄉鎮市區 */}
          <DistrictAutocomplete
            city={fields.city}
            value={fields.district}
            onChange={(event, value) => { setFields(pv => ({ ...pv, district: value })) }}
          />
          {/* 地址 */}
          <BasicTextField
            label="地址"
            value={fields.address}
            onChange={e => { setFields(pv => ({ ...pv, address: e.target.value })) }}
          />
          {/* 聯絡人名稱 */}
          <BasicTextField
            label="聯絡人名稱"
            value={fields.contactName}
            onChange={e => { setFields(pv => ({ ...pv, contactName: e.target.value })) }}
          />
          {/* 聯絡人電話 */}
          <BasicTextField
            label="聯絡人電話"
            value={fields.contactPhone}
            onChange={e => { setFields(pv => ({ ...pv, contactPhone: e.target.value })) }}
          />
          {/* 標籤 */}
          <BasicTextField
            label="標籤（用逗號隔開）"
            value={fields.tags}
            onChange={e => { setFields(pv => ({ ...pv, tags: e.target.value })) }}
          />
          {/* 備註 */}
          <BasicTextField
            label="備註"
            value={fields.note}
            onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
            multiline
            rows={7}
          />
        </DialogContent>
      }
      {tab === 1 &&
        <DialogContent>
          {/* Place ID */}
          <BasicTextField
            label="Place ID"
            value={fields.googlePlaceId}
            onChange={e => { setFields(pv => ({ ...pv, googlePlaceId: e.target.value })) }}
          />
          {/* 緯度 */}
          <BasicTextField
            label="緯度"
            type="number"
            value={fields.lat}
            onChange={e => { setFields(pv => ({ ...pv, lat: Number(e.target.value) })) }}
            // @ts-ignore
            onWheel={e => { e.target.blur() }} // 避免滾輪改到經緯度
          />
          {/* 經度 */}
          <BasicTextField
            label="經度"
            type="number"
            value={fields.lng}
            onChange={e => { setFields(pv => ({ ...pv, lng: Number(e.target.value) })) }}
            // @ts-ignore
            onWheel={e => { e.target.blur() }} // 避免滾輪改到經緯度 
          />
        </DialogContent>
      }
      <DialogActions>
        <FormControlLabel
          label="簽帳"
          control={
            <Checkbox
              checked={fields.bill}
              onChange={e => setFields(pv => ({ ...pv!, bill: e.target.checked }))}
              color="default"
            />
          }
        />
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}