import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { Item } from '../../parse-class/Item'

type AutocompletePropsType = Omit<AutocompleteProps<Item, false, false, false>, 'options' | 'renderInput'>
export interface IProps extends AutocompletePropsType {
  gridRenderEditCellParams: GridRenderEditCellParams<Item>
  textFieldProps?: TextFieldProps
}

export function InventoryItemAutocomplete(props: IProps) {

  const { gridRenderEditCellParams, textFieldProps, ...autocompleteProps } = props
  // ref
  const apiRef = useGridApiContext()
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [items, setItems] = React.useState<Item[] | null>(null)
  // state
  const [open, setOpen] = React.useState(false)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Item)
      query.limit(10000)
      const data = await query.find()
      setItems(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 選擇完後按下Enter，應該只是先關閉視窗，避免繼續傳播事件
   */
  const onKeyDown = React.useCallback<React.KeyboardEventHandler<HTMLDivElement>>(event => {
    if (event.key === 'Enter' && open) {
      event.stopPropagation()
    }
  }, [open])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (items === null) {
    return null;
  }

  return (
    <Autocomplete
      options={items}
      getOptionLabel={item => item.getFormatName()}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(autocompleteRenderInputParams) =>
        <TextField
          {...autocompleteRenderInputParams}
          margin="none"
          variant="outlined"
          {...props.textFieldProps}
        />
      }
      onChange={(event, item) => {
        // 如果有選擇貨物，且貨物id和原先的不同，就更新格子資料
        const { id, value, field } = gridRenderEditCellParams
        if (item !== null && item.id !== value?.id) {
          apiRef.current.setEditCellValue({ id, field, value: item })
        }
      }}
      fullWidth
      onKeyDown={onKeyDown}
      onOpen={event => setOpen(true)}
      onClose={event => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center' }}
      openOnFocus
      autoHighlight
      {...autocompleteProps}
    />
  )
}