import { LegacyImage } from '../LegacyImage'

export interface IItemPricing {
  date: Date
  cost: number        // 進貨價格
  price: number       // 銷售價格
  note: string
  isDefault: boolean  // 預設
}

export interface IAttributes {
  name: string
  source: string
  spec: string
  specDescription: string
  pkg: string
  pkgDescription: string
  unitName: string
  unitQty: number
  unitExact: boolean
  pkgWeight: number
  pkgLength: number
  pkgWidth: number
  pkgHeight: number
  origin: string
  note: string
  available: boolean
  pos: string                     // 庫存位置
  cap: number                     // 庫存容量
  feature: string                 // 貨物特徵
  pricingData: IItemPricing[]
  legacyImages: LegacyImage[]
  cacheStock: number              // 庫存數量快取
  cacheMonthlySales: number       // 月銷量快取
  inventoryGroup: string          // 同種庫存分組
  inventoryWeight: number         // 同種庫存權重
}

export const CLASS_NAME = 'Item'

export class Item extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }

  public getFormatName(options?: { pkg?: boolean }) {
    const source = this.get('source')!.length > 0 ? `${this.get('source')}：` : ''
    const name = this.get('name')
    const spec = this.get('spec')!.length > 0 ? `【${this.get('spec')}】` : ''
    const pkg = this.get('pkg')!.length > 0 ? `【${this.get('pkg')}】` : ''
    if (options && options.pkg === false) {
      return `${source}${name}${spec}`
    } else {
      return `${source}${name}${spec}${pkg}`
    }
  }

  public getDefaultPricing() {
    return this.get('pricingData')?.find(pricing => pricing.isDefault)
  }

  /**
   * 同步更新同種貨物的庫存資訊
   */
  public async syncInventoryGroupData() {
    const groupItems = await new Parse.Query(Item)
      .notEqualTo('objectId', this.id)
      .equalTo('inventoryGroup', this.get('inventoryGroup'))
      .find()
    if (groupItems.length === 0) {
      return
    }
    const selfPos = this.get('pos')!
    const product = this.get('cap')! * this.get('inventoryWeight')!
    for (const item of groupItems) {
      const inventoryWeight = item.get('inventoryWeight')!
      item.set('pos', selfPos)
      item.set('cap', product / inventoryWeight)
    }
  }
}

Parse.Object.registerSubclass(CLASS_NAME, Item)