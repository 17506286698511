import React from 'react'
import dayjs from 'dayjs'
import Container from '@mui/material/Container'
import { DataGridPremium, GridRowsProp, GridColumns, GridSortModel, GridToolbarContainer, GridActionsCellItem, GridRowId } from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import PrintIcon from '@mui/icons-material/Print'
import SyncIcon from '@mui/icons-material/Sync'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { useCheckMobile } from '../../context/user-check-mobile'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import PrintDeliveryDialog from './PrintDeliveryDialog'
import PrintPickDialog from './PrintPickDialog'
import { Company } from '../../parse-class/Company'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'

export const FIELDS = {
  DATE: 'DATE',
  TARGET: 'TARGET',
  NOTE: 'NOTE',
  DELIVERY_PERSON: 'DELIVERY_PERSON',
  DELIVERY_TIME: 'DELIVERY_TIME',
  PAYMENT_PERSON: 'PAYMENT_PERSON',
  PAYMENT_TIME: 'PAYMENT_TIME',
}

export function TransactionCustomerPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  const isMobile = useCheckMobile()
  // data
  const [transactionList, setTransactionList] = React.useState<TransactionCustomer[]>([])
  // state
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [loadAll, setLoadAll] = React.useState(false)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc' } // 按照日期降序排列
  ])
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [printingDeliveryIds, setPrintingDeliveryIds] = React.useState<GridRowId[]>([]) // 欲列印銷貨單的目標
  const [printingPickIds, setPrintingPickIds] = React.useState<GridRowId[]>([])   // 欲列印撿貨單的目標

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      // 本公司
      const company = await new Parse.Query(Company).first()
      // 交易資料
      const query = new Parse.Query(TransactionCustomer)
      query.equalTo('company', company)
      query.limit(loadAll ? 100000 : 100)
      query.descending('date')          // 只有descending會套用limit效果
      query.addDescending('createdAt')  // limit完之後才會再用addDescending排序
      query.include('customer', 'deliveryPerson', 'paymentPerson')
      const data = await query.find()
      setTransactionList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAll])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const transaction = TransactionCustomer.createWithoutData(id)
      await transaction.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const rows: GridRowsProp = React.useMemo(() => {
    return transactionList.map(transaction => (
      {
        id: transaction.id,
        [FIELDS.DATE]: transaction.get('date'),
        [FIELDS.TARGET]: transaction.get('customer'),
        [FIELDS.NOTE]: transaction.get('note'),
        [FIELDS.DELIVERY_PERSON]: transaction.get('deliveryPerson'),
        [FIELDS.DELIVERY_TIME]: transaction.get('deliveryTime'),
        [FIELDS.PAYMENT_PERSON]: transaction.get('paymentPerson'),
        [FIELDS.PAYMENT_TIME]: transaction.get('paymentTime'),
      }
    ))
  }, [transactionList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
        valueFormatter: params => dayjs(params.value).format('YYYY-MM-DD')
      },
      {
        field: FIELDS.TARGET,
        headerName: '客戶',
        width: 250,
        valueGetter: params => params.row[FIELDS.TARGET].get('name'),
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 250,
      },
      {
        field: 'DELIVERY',
        headerName: '物流',
        width: 120,
        valueGetter: params => {
          const name = params.row[FIELDS.DELIVERY_PERSON].get('name')
          const finish = params.row[FIELDS.DELIVERY_TIME] !== undefined
          return name + (finish ? '' : '（X）')
        },
      },
      {
        field: 'PAYMENT',
        headerName: '金流',
        width: 120,
        valueGetter: params => {
          const name = params.row[FIELDS.PAYMENT_PERSON].get('name')
          const finish = params.row[FIELDS.PAYMENT_TIME] !== undefined
          return name + (finish ? '' : '（X）')
        },
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 75,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      },
    ]
  }, [onDelete])

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="客戶交易" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              // selection
              onSelectionModelChange={selectionModel => { setSelectionModel(selectionModel) }}
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                    <Button
                      disabled={loadAll}
                      color="primary"
                      startIcon={<SyncIcon />}
                      onClick={() => { setLoadAll(true) }}
                    >
                      {loadAll ? '已載入' : '載入全部'}
                    </Button>
                    <Button
                      disabled={selectionModel.length === 0}
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setPrintingDeliveryIds([...selectionModel]) }}
                    >
                      配送單
                    </Button>
                    <Button
                      disabled={selectionModel.length === 0}
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setPrintingPickIds([...selectionModel]) }}
                    >
                      撿貨單
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <AddDialog
        fullWidth
        maxWidth='md'
        fullScreen={isMobile}
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onChange={() => {
          setShowAddDialog(false) // 關閉視窗
          loadData() // 重新載入資料
        }}
      />
      <EditDialog
        fullWidth
        maxWidth='md'
        fullScreen={isMobile}
        editingId={editingId}
        open={editingId !== null}
        onClose={() => setEditingId(null)}
        onChange={() => {
          setEditingId(null) // 關閉視窗
          loadData() // 重新載入資料
        }}
      />
      {/* 列印配送單 */}
      <PrintDeliveryDialog
        transactionIds={printingDeliveryIds as string[]}
        open={printingDeliveryIds.length > 0}
        onClose={() => setPrintingDeliveryIds([])}
      />
      {/* 列印撿貨單 */}
      <PrintPickDialog
        transactionIds={printingPickIds as string[]}
        open={printingPickIds.length > 0}
        onClose={() => setPrintingPickIds([])}
      />
    </React.Fragment>
  )
}