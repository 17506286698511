import React from 'react'
import { Image } from 'mui-image'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DeleteIcon from '@mui/icons-material/Delete'
import ImageViewDialog from '../../component/ImageViewDialog'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { Customer } from '../../parse-class/Customer'
import { LegacyImage } from '../../parse-class/LegacyImage'

interface IProps extends DialogProps {
  customerId: string | null
}

export default function LegacyImageDialog(props: IProps) {

  const { customerId, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [images, setImages] = React.useState<LegacyImage[] | null>(null)
  // state
  const [tab, setTab] = React.useState<'list' | 'upload' | 'import'>('list')
  const [showImage, setShowImage] = React.useState<LegacyImage | null>(null)

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(Customer)
        .include('legacyImages')
        .get(id)
      setImages(data.get('legacyImages')!)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (customerId !== null) {
      loadData(customerId)
    }
  }, [customerId, loadData])

  if (!images) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog  {...dialogProps}>
        <Tabs value={tab} onChange={(e, v) => { setTab(v) }} >
          <Tab label="瀏覽" value="list" />
          <Tab label="上傳" value="upload" disabled />
        </Tabs>
        <DialogContent>
          {tab === 'list' &&
            <Box minHeight={350}>
              <Grid container spacing={1}>
                {images.map(image => (
                  <Grid item key={image.id} xs={6} sm={4} md={3} xl={2}>
                    <Card>
                      <CardActionArea onClick={() => { setShowImage(image) }}>
                        <Image src={image.get('file')!.url()} fit='scale-down' />
                      </CardActionArea>
                      <CardActions>
                        <Button
                          disabled
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                        >
                          刪除
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
      {/* Image Dialog */}
      <ImageViewDialog
        open={showImage !== null}
        onClose={() => { setShowImage(null) }}
        fullWidth
        maxWidth="md"
        src={showImage ? showImage.get('file')!.url() : null}
      />
    </React.Fragment >
  )
}