import React from 'react';
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
// import ImageIcon from '@mui/icons-material/Image';
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { Provider } from '../../parse-class/Provider'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
// import ImageGroupDialog from '../component/ImageGroupDialog';

export const FIELDS = {
  NAME: 'NAME',
  ADDRESS: 'ADDRESS',
}

export function ProviderPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [providerList, setProviderList] = React.useState<Provider[] | null>(null);
  // state
  // const [imageGroupId, setImageGroupId] = React.useState<number | null>(null);
  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const [editingId, setEditingId] = React.useState<string | null>(null);

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Provider)
      query.limit(10000)
      query.descending('createdAt')
      const data = await query.find()
      setProviderList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' });
    if (!sure) return;
    try {
      backdrop.show('刪除資料中')
      const provider = Provider.createWithoutData(id)
      await provider.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!providerList) {
      return [];
    }
    return providerList.map(provider => (
      {
        id: provider.id,
        [FIELDS.NAME]: provider.get('name'),
        [FIELDS.ADDRESS]: `${provider.get('city')}${provider.get('district')}`,
      }
    ))
  }, [providerList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.NAME,
        headerName: '供應商',
        width: 200,
      },
      {
        field: FIELDS.ADDRESS,
        headerName: '地區',
        width: 120,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 100,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            // <GridActionsCellItem
            //   icon={<ImageIcon />}
            //   label="照片"
            //   onClick={() => { setImageGroupId(id as string) }}
            // />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      }
    ]
  }, [onDelete])

  /**
   * 取得資料後才顯示
   */
  if (providerList === null) {
    return null;
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="供應商" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      {/*  */}
      {/* {imageGroupId !== null &&
        <ImageGroupDialog
          open={imageGroupId !== null}
          onClose={() => { setImageGroupId(null) }}
          group={`provider_${imageGroupId}`}
        />
      } */}
      {/* Add Dialog */}
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        onChange={() => {
          loadData()              // 載入新資料
          setShowAddDialog(false) // 關閉視窗
        }}
      />
      {/* Edit Dailog */}
      <EditDialog
        id={editingId}
        onClose={() => { setEditingId(null) }}
        onChange={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}