import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import ItemAutocomplete from '../../input/ItemAutocomplete'
import BasicNumberField from '../../input/BasicNumberField'
import BasicTextField from '../../input/BasicTextField'
import { Item } from '../../parse-class/Item'
import { InventoryAdjustment } from '../../parse-class/InventoryAdjustment'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  item: Item | null
  qty: number
  note: string
}

interface IParams extends DialogProps {
  editingId: string | null
  onUpdate: () => void
}

export default function EditDialog(props: IParams) {

  const { editingId, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>()

  const verify = React.useMemo(() => {
    return fields
      && fields.item !== null
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(InventoryAdjustment).get(id)
      setFields({
        date: data.get('date') || dayjs().utc(true).startOf('day').toDate(),
        item: data.get('item') || null,
        qty: data.get('qty') || 0,
        note: data.get('note') || '',
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && editingId !== null) {
      loadData(editingId)
    } else {
      setFields(undefined)
    }
  }, [dialogProps.open, editingId, loadData])

  const onSave = React.useCallback(async () => {
    if (verify && fields && editingId) {
      try {
        backdrop.show('更新資料中')
        const data = await new Parse.Query(InventoryAdjustment).get(editingId)
        data.set('date', fields.date)
        data.set('item', fields.item!)
        data.set('qty', fields.qty)
        data.set('note', fields.note)
        await data.save()
        snackbar.show('更新成功')
        onUpdate() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, fields, onUpdate])

  if (fields === undefined) {
    return null
  }

  return (
    <Dialog {...dialogProps} >
      <DialogTitle>編輯庫存調整</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, date: value }))
          }}
        />
        <ItemAutocomplete
          value={fields.item}
          onChange={(event, value) => setFields(pv => ({ ...pv!, item: value }))}
        />
        <BasicNumberField
          label="數量（+/-）"
          defaultValue={fields.qty}
          onChange={e => setFields(pv => ({ ...pv!, qty: Number(e.target.value) }))}
        />
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}