import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'

export type IProps = TextFieldProps & {
  value?: Date
  defaultValue?: Date
  onDateChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, date: Date) => void
}

function fillChar(raw: string, len: number, char = '0') {
  if (raw.length < len) {
    const n = len - raw.length
    return char.repeat(n) + raw
  }
  return raw
}

function toDateString(date: Date) {
  let yyyy = '' + date.getFullYear()
  let mm = '' + (date.getMonth() + 1)
  let dd = '' + date.getDate()
  return `${fillChar(yyyy, 4)}-${fillChar(mm, 2)}-${fillChar(dd, 2)}`
}

export default function DateField(props: IProps) {

  const { value, defaultValue, onDateChange, ...textFieldProps } = props

  const formatedValue = React.useMemo(() => {
    // Dayjs物件的最小值是從1900年開始，但編輯過程中會小於1900，所以不能用Dayjs轉換
    return value && toDateString(value)
  }, [value])

  const formatedDefaultValue = React.useMemo(() => {
    return defaultValue && toDateString(defaultValue)
  }, [defaultValue])

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      label="日期"
      type="date"
      {...textFieldProps}
      value={formatedValue}
      defaultValue={formatedDefaultValue}
      onChange={event => {
        if (event.target.value !== '') {
          onDateChange && onDateChange(event, new Date(event.target.value))
        }
      }}
    />
  )
}