import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import DateField from '../../input/DateField'
import BasicTextField from '../../input/BasicTextField'
import ProviderAutocomplete from '../../input/ProviderAutocomplete'
import TransactionDetailGrid from '../TransactionCustomerPage/TransactionDetailGrid'
import { transactionCheck } from './transaction-check'
import { Company } from '../../parse-class/Company'
import { Provider } from '../../parse-class/Provider'
import { Item } from '../../parse-class/Item'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'
import { TransactionProvider, ITransactionDetail } from '../../parse-class/TransactionProvider'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  target: Provider | null
  note: string
  details: ITransactionDetail[]
}

interface IProps extends DialogProps {
  onChange: () => void
}

export default function AddDialog(props: IProps) {

  const { onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [company, setCompany] = React.useState<Company | null>(null)
  const [providers, setProviders] = React.useState<Provider[] | null>(null) // 有交易的客戶
  const [items, setItems] = React.useState<Item[]>([])
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    target: null,
    note: '',
    details: []
  })

  const verify = React.useMemo(() => {
    return fields.target !== null
  }, [fields])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const company = await new Parse.Query(Company).first()
      if (company) {
        setCompany(company)
      } else {
        throw new Error('找不到本公司')
      }
      const providers = await PurchaseHelper.getProviders()
      setProviders(providers)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        target: null,
        note: '',
        details: []
      })
    }
  }, [dialogProps.open, loadData])

  const loadItemsData = React.useCallback(async () => {
    if (fields?.target) {
      // 有在採購清單的排前面
      const yes = await PurchaseHelper.getItems(fields.target)
      const no = await new Parse.Query(Item)
        .limit(10000)
        .notContainedIn('objectId', yes.map(i => i.id))
        .find()
      setItems([...yes, ...no])
    } else {
      setItems([])
    }
  }, [fields?.target])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadItemsData()
    }
  }, [dialogProps.open, loadItemsData])

  const onSave = React.useCallback(async () => {
    if (verify) {
      const transaction = new TransactionProvider()
      transaction.set('date', fields.date)
      transaction.set('company', company!)
      transaction.set('provider', fields.target!)
      transaction.set('details', fields.details)
      transaction.set('note', fields.note)
      // 交易檢查
      let msgs: string[] = []
      try {
        backdrop.show('交易檢查中')
        msgs = await transactionCheck(transaction)
      } catch (e: any) {
        snackbar.show('交易檢查失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
      if (msgs.length > 0) {
        const sure = await confirm.confirm({ title: '交易檢查', content: msgs.join('　　'), sure: '仍然執行', close: '取消' })
        if (!sure) return
      }
      // 新增交易
      try {
        backdrop.show('新增資料中')
        await transaction.save()
        snackbar.show('新增成功')
        onChange() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, company, fields, onChange])

  if (!company || !providers) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>新增供應商交易</DialogTitle>
        <DialogContent>
          <DateField
            value={fields.date}
            onDateChange={(event, value) => {
              setFields(pv => ({ ...pv!, date: value }))
            }}
          />
          <ProviderAutocomplete
            value={fields.target}
            onChange={(event, value) => setFields(pv => ({ ...pv, target: value }))}
            textFieldProps={{ autoFocus: true }}
            data={providers}
          />
          <BasicTextField
            label="備註"
            value={fields.note}
            onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
            multiline
            rows={3}
          />
          <TransactionDetailGrid
            items={items}
            onUpdate={rows => setFields(pv => ({ ...pv, details: rows }))}
            autoApplyPricing="COST"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            儲存
          </Button>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}