
interface Cache {
  purchase: number     // 年採購額
  sales: number        // 年銷售額
}

export interface IAttributes {
  code: string               // 客戶編號
  fullName: string           // 全稱
  shortName: string          // 簡稱
  taxNumber: string          // 統編
  taxTitle: string           // 抬頭
  fax: string                // 傳真
  tel: string                // 電話
  closingDay: string         // 結帳日
  addressA: string           // 地址A
  addressB: string           // 地址B
  addressC: string           // 地址C
  salesperson: string        // 業務
  deliverPerson: string      // 司機
  trust: number              // 信用額度
  payment: string            // 結帳方式
  contact: string            // 聯絡
  memo: string               // 備註
  cache: Cache               // 快取資料
}

export const CLASS_NAME = 'ZCompany'

export class ZCompany extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, ZCompany)