import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { DataGridPremium, GridColumns } from '@mui/x-data-grid-premium'

export interface IRecordData {
  date: Date
  company: string
  qty: number
  price: number
}

interface IProps extends DialogProps {
  data: IRecordData[] | null
}

enum FIELDS {
  DATE = 'DATE',
  COMPANY = 'COMPANY',
  QTY = 'QTY',
  PRICE = 'PRICE',
  SUM = 'SUM',
}

export function RecordDialog(props: IProps) {

  const { data, ...dialogProps } = props

  const columns: GridColumns = React.useMemo(() => [
    { field: FIELDS.DATE, headerName: '日期', width: 100, type: 'date' },
    { field: FIELDS.COMPANY, headerName: '廠商', width: 190 },
    { field: FIELDS.QTY, headerName: '數量', width: 80, type: 'number' },
    { field: FIELDS.PRICE, headerName: '單價', width: 80, type: 'number' },
    { field: FIELDS.SUM, headerName: '總額', width: 80, type: 'number' },
  ], [])

  const rows = React.useMemo(() => {
    if (!data) {
      return []
    }
    return data.map((row, index) => (
      {
        id: index,
        [FIELDS.DATE]: row.date,
        [FIELDS.COMPANY]: row.company,
        [FIELDS.QTY]: row.qty,
        [FIELDS.PRICE]: row.price,
        [FIELDS.SUM]: row.price * row.qty,
      }
    ))
  }, [data])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>記錄</DialogTitle>
      <DialogContent>
        <DataGridPremium
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          density="compact"
          autoHeight
        />
      </DialogContent>
    </Dialog>
  )
}