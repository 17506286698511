import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Loader } from '@googlemaps/js-api-loader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'
import MenuIcon from '@mui/icons-material/Menu'
import { useCheckMobile } from '../../context/user-check-mobile'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import FullHeightBox from '../../component/FullHeightBox'
import SelectDateDialog from './SelectDateDialog'
import OutOfMapDialog from '../MapCustomerPage/OutOfMapDialog'
import CustomerEventDialog, { TCustmerEvent } from './CustomerEventDialog'
import TransactionDeliveryOrderDialog from './TransactionDeliveryOrderDialog'
import OrderOptionsDialog, { IOrderOptions } from './OrderOptionsDialog'
import OrderDialog from './OrderDialog'
import { Customer } from '../../parse-class/Customer'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'
import { MarketingNote } from '../../parse-class/MarketingNote'
import { MarketingSample } from '../../parse-class/MarketingSample'
dayjs.extend(utc)

export function MapDeliveryPage() {

  // context
  const isMobile = useCheckMobile()
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [customerEvents, setCustomerEvents] = React.useState<Map<string, TCustmerEvent> | null>(null)
  const [outOfMapCustomers, setOutOfMapCustomers] = React.useState<Customer[] | null>(null)
  // state
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [today, setToday] = React.useState(dayjs().utc(true).startOf('day'))                    // 規定日期都必須是 start of day
  const [position, setPosition] = React.useState<GeolocationPosition | null>(null)              // 目前位置
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [customerEvent, setCustomerEvent] = React.useState<TCustmerEvent | null>(null)
  const [showOutOfMapDialog, setShowOutOfMapDialog] = React.useState(false)                     // 對話框：沒在地圖上的客戶
  const [showDateDialog, setShowDateDialog] = React.useState(false)                             // 對話框：日期選擇
  const [showDeliveryOrderDialog, setShowDeliveryOrderDialog] = React.useState(false)           // 對話框：排單
  const [showOrderOptionsDialog, setShowOrderOptionsDialog] = React.useState(false)             // 對話框：點貨選項
  const [showOrderDialog, setShowOrderDialog] = React.useState<IOrderOptions>()                 // 對話框：點貨

  const initMap = React.useCallback(async () => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      version: "weekly",
    })
    const google = await loader.load()
    const map = new google.maps.Map(document.getElementById('map')!, {
      center: { lat: 22.632711948177267, lng: 120.30403026346104 },
      zoom: 15,
      minZoom: 8,
      fullscreenControl: false,                                                 // 禁用全螢幕按鈕
      mapTypeControl: false,                                                    // 禁用衛星圖切換
      streetViewControl: false,                                                 // 禁用街景
      zoomControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },  // 縮放工具位於右上角
    })
    setMap(map)
  }, [])

  React.useLayoutEffect(() => {
    initMap()
  }, [initMap])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const customerEvents = new Map<string, TCustmerEvent>()
      // 客戶銷售的交易資料
      const transactionList = await new Parse.Query(TransactionCustomer)
        .limit(10000)
        .equalTo('date', today.toDate())
        .include('customer')
        .find()
      for (let transaction of transactionList) {
        const customer = transaction.get('customer')!
        const key = customer.id
        const value = customerEvents.get(key) || { customer, transactions: [], marketingNotes: [], marketingSamples: [] }
        value.transactions.push(transaction)
        customerEvents.set(key, value)
      }
      // 客戶相關的行銷資料
      const marketingNoteList = await new Parse.Query(MarketingNote)
        .limit(10000)
        .equalTo('date', today.toDate())
        .include('customer')
        .find()
      for (let marketingNote of marketingNoteList) {
        const customer = marketingNote.get('customer')!
        const key = customer.id
        const value = customerEvents.get(key) || { customer, transactions: [], marketingNotes: [], marketingSamples: [] }
        value.marketingNotes.push(marketingNote)
        customerEvents.set(key, value)
      }
      // 客戶相關的樣品資料
      const marketingSampleList = await new Parse.Query(MarketingSample)
        .limit(10000)
        .equalTo('date', today.toDate())
        .include('customer')
        .include('item')
        .find()
      for (let marketingSample of marketingSampleList) {
        const customer = marketingSample.get('customer')!
        const key = customer.id
        const value = customerEvents.get(key) || { customer, transactions: [], marketingNotes: [], marketingSamples: [] }
        value.marketingSamples.push(marketingSample)
        customerEvents.set(key, value)
      }
      setCustomerEvents(customerEvents)
      // 該顯示，卻沒有在地圖上的客戶
      const outOfMapCustomers = Array.from(customerEvents.values())
        .map(e => e.customer)
        .filter(customer => customer.get('position') === undefined)
      setOutOfMapCustomers(outOfMapCustomers)
      // 如果有這種客戶，就顯示提示框
      if (outOfMapCustomers.length > 0) {
        setShowOutOfMapDialog(true)
      }
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 更新自己目前的定位
   */
  const updateMyPosition = React.useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        setPosition(position)
        // 將目前位置設為地圖中心（如果地圖已載入完成）
        map && map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
      })
    } else {
      alert('無法使用定位功能')
    }
  }, [map])

  React.useEffect(() => {
    updateMyPosition()
  }, [updateMyPosition])

  /**
   * 顯示自己位置
   */
  React.useEffect(() => {
    if (map && position) {
      const marker = new google.maps.Marker({
        map: map,
        position: { lat: position.coords.latitude, lng: position.coords.longitude },
        label: {
          text: "\ue7f2", // codepoint from https://fonts.google.com/icons
          fontFamily: "Material Icons",
          color: "#ffffff",
          fontSize: "18px",
        },
      })
      return () => {
        marker.setMap(null)
      }
    }
  }, [map, position])

  /**
   * 顯示台南據點
   */
  React.useEffect(() => {
    if (map) {
      const marker = new google.maps.Marker({
        map: map,
        label: "據點",
        position: { lat: 23.001979702910646, lng: 120.22851990336865 },
      })
      marker.addListener('click', () => {
        window.open(`https://maps.google.com/?q=${23.001979702910646},${120.22851990336865}`, '_blank')
      })
      return () => {
        marker.setMap(null)
      }
    }
  }, [map])

  /**
   * 顯示客戶標記
   */
  React.useEffect(() => {
    if (map && customerEvents) {
      const markers = Array.from(customerEvents.values())
        .filter(event => event.customer.get('position') !== undefined)
        .map(event => {
          const customer = event.customer
          const position = customer.get('position')!
          const MarkerIcon = {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // yellow, green, blue
            scaledSize: new google.maps.Size(40, 40)
          }
          const icon = event.transactions.length > 0 ? undefined : MarkerIcon // 如果有交易就用預設值，否則用自訂Icon
          const marker = new google.maps.Marker({
            map: map,
            position: { lat: position.latitude, lng: position.longitude },
            label: customer.get('name'),
            icon: icon
          })
          marker.addListener('click', () => {
            setCustomerEvent(event)
          })
          return marker
        })
      return () => {
        markers.forEach(marker => marker.setMap(null))
      }
    }
  }, [map, customerEvents])

  return (
    <React.Fragment>
      <FullHeightBox>
        <div id="map" style={{ height: '100%' }} />
      </FullHeightBox>
      <Fab
        size="medium"
        color="primary"
        style={{ position: 'fixed', right: 24, bottom: 24 }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MenuIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => { setShowDateDialog(true); setAnchorEl(null) }}>{today.format('YYYY-MM-DD')}</MenuItem>
        <MenuItem onClick={() => { setToday(today.add(1, 'day')); setAnchorEl(null) }}>明天</MenuItem>
        <MenuItem onClick={() => { setShowDeliveryOrderDialog(true); setAnchorEl(null) }}>物流排程</MenuItem>
        <MenuItem onClick={() => { setShowOrderOptionsDialog(true); setAnchorEl(null) }}>點貨</MenuItem>
        <MenuItem onClick={() => { updateMyPosition(); setAnchorEl(null) }}>重新定位</MenuItem>
        <MenuItem onClick={() => { loadData(); setAnchorEl(null) }}>更新資料</MenuItem>
      </Menu>
      {/* Date Dialog */}
      <SelectDateDialog
        open={showDateDialog}
        onClose={() => setShowDateDialog(false)}
        fullWidth
        maxWidth="xs"
        defaultData={{
          date: today.toDate()
        }}
        onResult={({ date }) => {
          setToday(dayjs(date))
          setShowDateDialog(false)
        }}
      />
      <CustomerEventDialog
        open={customerEvent !== null}
        onClose={() => setCustomerEvent(null)}
        fullWidth
        maxWidth="xs"
        fullScreen={isMobile}
        event={customerEvent}
      />
      <OutOfMapDialog
        customers={outOfMapCustomers}
        open={showOutOfMapDialog}
        onClose={() => setShowOutOfMapDialog(false)}
        fullWidth
        maxWidth="xs"
      />
      <TransactionDeliveryOrderDialog
        open={showDeliveryOrderDialog}
        onClose={() => { setShowDeliveryOrderDialog(false) }}
        fullWidth
        maxWidth="xs"
        fullScreen={isMobile}
        today={today}
      />
      <OrderOptionsDialog
        open={showOrderOptionsDialog}
        onClose={() => { setShowOrderOptionsDialog(false) }}
        fullWidth
        maxWidth='xs'
        onResult={options => {
          setShowOrderOptionsDialog(false)
          setShowOrderDialog(options)
        }}
      />
      <OrderDialog
        open={showOrderDialog !== undefined}
        onClose={() => {
          setShowOrderDialog(undefined)
        }}
        fullWidth
        maxWidth='sm'
        fullScreen={isMobile}
        options={showOrderDialog}
      />
    </React.Fragment>
  )
}