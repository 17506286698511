import React from 'react'
import { DataGridPremium, GridColumns } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { Item } from '../../parse-class/Item'
import { updateItemCacheStorage } from '../../cloud/report-storage'

export const FIELDS = {
  ITEM: 'ITEM',
  CACHE_STOCK: 'CACHE_STOCK',
  CACHE_MONTHLY_SALES: 'CACHE_MONTHLY_SALES',
}

export function InventoryUnsalablePage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [itemList, setItemList] = React.useState<Item[] | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      await updateItemCacheStorage()
      const items = await new Parse.Query(Item)
        .greaterThan('cacheStock', 0)                     // 庫存大於0
        .descending('createdAt')
        .limit(10000)
        .find()
      const unsalable = items.filter(item => {
        const monthlySales = item.get('cacheMonthlySales')!
        const stock = item.get('cacheStock')!
        if (monthlySales <= 0) return true                // 月銷小等於0
        if (stock / monthlySales >= 6) return true        // 要賣超過半年
        return false
      })
      setItemList(unsalable)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!itemList) {
      return []
    }
    return itemList.map(item => (
      {
        id: item.id,
        [FIELDS.ITEM]: item.getFormatName(),
        [FIELDS.CACHE_STOCK]: item.get('cacheStock'),
        [FIELDS.CACHE_MONTHLY_SALES]: item.get('cacheMonthlySales'),
      }
    ))
  }, [itemList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.ITEM,
        headerName: '來源',
        width: 300,
      },
      {
        field: FIELDS.CACHE_STOCK,
        headerName: '庫存',
        width: 80,
        type: 'number',
      },
      {
        field: FIELDS.CACHE_MONTHLY_SALES,
        headerName: '月銷',
        width: 80,
        type: 'number',
      },
    ]
  }, [])

  if (itemList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="呆貨清單（半年內賣不完）" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: FIELDS.ITEM, sort: 'desc' }]
                },
              }}
            />
          </Box>
        </StandardBox>
      </Container>
    </React.Fragment>
  )
}