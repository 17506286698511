import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { Provider } from '../../parse-class/Provider'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'

interface IParams extends DialogProps {
  provider: Provider | null
}

export default function PurchaseMsgDialog(props: IParams) {

  const { provider, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [msgs, setMsgs] = React.useState<string[]>([])

  const loadData = React.useCallback(async () => {
    if (!provider) return
    try {
      backdrop.show('讀取資料中')
      const helperList = await new Parse.Query(PurchaseHelper)
        .limit(10000)
        .equalTo('provider', provider)
        .greaterThan('order', 0)
        .select('item', 'order')
        .find()
      setMsgs(helperList.map(helper => {
        const qty = helper.get('order')!
        const item = helper.get('item')!
        let spec = item.get('spec')!
        if (spec.length > 0) spec = `【${spec}】`
        const pkg = item.get('pkg')
        return `${item.get('name')}${spec}\t${qty}${pkg}`
      }))
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
    }
  }, [dialogProps.open, loadData])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>採購訊息</DialogTitle>
      <DialogContent>
        {msgs.map((msg, index) => (
          <span key={index}>{msg}<br /></span>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}