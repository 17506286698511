import { Item } from '../Item'
import { Image } from '../Image'

export interface IAttributes {
  item: Item
  image: Image
  tags: string[]
}

export const CLASS_NAME = 'ItemImage'

export class ItemImage extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, ItemImage)