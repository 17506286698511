import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { blue, orange, green } from '@mui/material/colors'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import CustomerTransactionEditDialog from './CustomerTransactionEditDialog'
import { Customer } from '../../parse-class/Customer'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'
import { MarketingNote } from '../../parse-class/MarketingNote'
import { MarketingSample } from '../../parse-class/MarketingSample'

export type TCustmerEvent = {
  customer: Customer
  transactions: TransactionCustomer[]
  marketingNotes: MarketingNote[]
  marketingSamples: MarketingSample[]
}

export interface Props extends DialogProps {
  event: TCustmerEvent | null
}

export default function CustomerEventDialog(props: Props) {

  const { event, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [editingTransactionId, setEditingTransactionId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    if (!event) return
    try {
      backdrop.show('讀取資料中')
      await TransactionCustomer.fetchAll(event.transactions, {})
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>{event?.customer.get('name')}</DialogTitle>
        <List dense sx={{ pt: 0 }}>
          {event?.transactions.map(transaction => {
            const sum = transaction.sum()
            return (
              <ListItem key={transaction.id}>
                <ListItemButton onClick={() => { setEditingTransactionId(transaction.id) }}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      售
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${sum}元`}
                    secondary={transaction.get('note')}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
          {event?.marketingNotes.map(note => (
            <ListItem key={note.id}>
              <ListItemButton onClick={() => { }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                    記
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={note.get('topic')}
                  secondary={note.get('result')}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {event?.marketingSamples.map(sample => {
            const item = sample.get('item')!
            return (
              <ListItem key={sample.id}>
                <ListItemButton onClick={() => { }}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: orange[100], color: orange[600] }}>
                      樣
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.getFormatName()}
                    secondary={sample.get('note')}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              const position = event?.customer.get('position')
              const url = `https://maps.google.com/?q=${position?.latitude},${position?.longitude}`
              window.open(url, '_blank')
            }}>
            在 Google 地圖上查看
          </Button>
          <Button
            color="error"
            onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
      <CustomerTransactionEditDialog
        fullWidth
        maxWidth='md'
        open={editingTransactionId !== null}
        onClose={() => setEditingTransactionId(null)}
        fullScreen={dialogProps.fullScreen}
        editingId={editingTransactionId}
        onChange={() => {
          setEditingTransactionId(null)       // 關閉視窗
          loadData()                          // 重新載入資料
        }}
      />
    </React.Fragment>
  )
}