import React from 'react'
import dayjs from 'dayjs'
import { DataGridPro, GridColumns, GridRowsProp } from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ElectronicInvoice } from '../../parse-class/ElectronicInvoice'

const formater = new Intl.NumberFormat('zh-tw')

export const FIELDS = {
  NAME: 'NAME',
  QTY: 'QTY',
  PRICE: 'PRICE',
  SUM: 'SUM',
}

interface IProps extends DialogProps {
  viewingId: string | null
}

export default function ViewDialog(props: IProps) {

  const { viewingId, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [invoice, setInvoice] = React.useState<ElectronicInvoice | null>(null)

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const invoice = await new Parse.Query(ElectronicInvoice)
        .include('toEntity')
        .include('fromEntity')
        .get(id)
      setInvoice(invoice)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && viewingId) {
      loadData(viewingId)
    }
  }, [dialogProps.open, viewingId, loadData])

  const rows: GridRowsProp = React.useMemo(() => {
    if (invoice) {
      const details = invoice.get('details') || []
      return details.map((detail, index) => ({
        id: index,
        [FIELDS.NAME]: detail.name,
        [FIELDS.QTY]: detail.qty,
        [FIELDS.PRICE]: detail.price,
      }))
    } else {
      return []
    }
  }, [invoice])

  const columns: GridColumns = React.useMemo(() => [
    {
      field: FIELDS.NAME,
      headerName: '品名',
      width: 240,
      sortable: false
    },
    {
      field: FIELDS.QTY,
      headerName: '數量',
      width: 100,
      type: 'number',
      sortable: false
    },
    {
      field: FIELDS.PRICE,
      headerName: '單價',
      width: 100,
      type: 'number',
      sortable: false
    },
    {
      field: 'SUM',
      headerName: '金額',
      width: 100,
      type: 'number',
      sortable: false,
      valueGetter: params => Math.round(params.row[FIELDS.PRICE] * params.row[FIELDS.QTY]),
    }
  ], [])

  const calculator = React.useMemo(() => invoice?.taxCalculator(), [invoice])

  const taxType = React.useMemo(() => {
    if (invoice) {
      switch (invoice.get('taxType')) {
        case 'TAXABLE': return '應稅'
        case 'TAX_EXEMPTION': return '免稅'
        case 'ZERO_TAX': return '零稅率'
      }
    } else {
      return ''
    }
  }, [invoice])

  const cn = React.useMemo(() => {
    const CN_NUMS = ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖']
    const CN_RADICE = ['元', '拾', '佰', '仟', '萬', '拾', '佰', '仟', '億']

    // 限定整數
    let money = Math.round(calculator?.sum || 0)

    // 處理0或負值的情況
    if (money === 0) {
      return '-'
    } else if (money < 0) {
      money = -money
    }
    // 轉大寫
    let moneyStr = money.toString()
    return moneyStr.split('')
      .reverse()
      .map((c, index) => CN_NUMS[Number(c)] + CN_RADICE[index])
      .reverse()
      .join(' ')
  }, [calculator])

  if (viewingId === null || invoice === null) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>電子發票 {invoice.get('code')}</DialogTitle>
        <DialogContent>
          <Box mb={1} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box border={1} p={1} >
              <Typography>買受人：{invoice.get('toEntity')?.get('taxTitle')}</Typography>
              <Typography>統　編：{invoice.get('toEntity')?.get('taxId')}</Typography>
            </Box>
            <Box flex={1} />
            <Box sx={{ display: 'flex', alignItems: 'end' }}>
              <Typography>{dayjs(invoice.get('date')).subtract(1911, 'year').format('民國 YYYY年 M 月 D 日')}</Typography>
            </Box>
          </Box>
          <DataGridPro
            rows={rows}
            columns={columns}
            density="compact"
            autoHeight
            disableSelectionOnClick
            disableColumnReorder
            disableColumnMenu
            hideFooter
          />
          <Box mt={1} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography>銷售額：</Typography>
                  <Typography>營業稅：</Typography>
                  <Typography>總　計：</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} minWidth={80}>
                  <Typography>{formater.format(calculator?.sales || 0)}</Typography>
                  <Typography>{invoice.get('taxType') === 'TAXABLE' ? formater.format(calculator?.tax || 0) : taxType}</Typography>
                  <Typography>{formater.format(calculator?.sum || 0)}</Typography>
                </Box>
              </Box>
              <Typography>大　寫：{cn}</Typography>
            </Box>
            <Box flex={1} />
            <Box border={1} p={1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>{invoice.get('fromEntity')?.get('taxTitle')}</Typography>
              <Typography>{invoice.get('fromEntity')?.get('taxId')}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}