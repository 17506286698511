import { TaxEntity } from '../TaxEntity'

export interface UniformInvoiceDetail {
  name: string
  qty: number
  price: number
}

export interface IAttributes {
  code: string
  date: Date
  fromEntity: TaxEntity
  toEntity: TaxEntity
  taxType: 'TAXABLE' | 'TAX_EXEMPTION' | 'ZERO_TAX'
  add: boolean // 是否+1元的稅（解決四捨五入驗算差一問題
  details: UniformInvoiceDetail[]
}

export const CLASS_NAME = 'UniformInvoice'

export class UniformInvoice extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }

  taxCalculator() {
    // 銷售額
    const details = this.get('details') || []
    let sales = details.reduce((pre, cur) => pre + Math.round(cur.price * cur.qty), 0)
    // 是否有兩種稅額
    let hasTwoTax = sales % 20 === 10
    // 營業稅
    let tax = 0
    if (this.get('taxType') === 'TAXABLE') {
      if (hasTwoTax && !this.get('add')) {
        tax = Math.round(sales * 0.05) - 1
      } else {
        tax = Math.round(sales * 0.05)
      }
    }
    // 總計
    let sum = sales + tax
    return { sales, hasTwoTax, tax, sum }
  }
}

Parse.Object.registerSubclass(CLASS_NAME, UniformInvoice)