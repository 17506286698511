import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DateField from '../../input/DateField'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
}

interface IProps extends DialogProps {
  defaultData?: IFields
  onResult?: (result: IFields) => void
}

export default function SelectDateDialog(props: IProps) {

  const { defaultData, onResult, ...dialogProps } = props
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate() // 規定日期都必須是 start of day
  })

  React.useEffect(() => {
    if (dialogProps.open) {
      if (defaultData) {
        setFields(defaultData)
      } else {
        setFields({
          date: dayjs().utc(true).startOf('day').toDate() // 規定日期都必須是 start of day
        })
      }
    }
  }, [dialogProps.open, defaultData])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>選擇日期</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pre => ({ ...pre, date: value }))
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onResult && onResult(fields)
          }}
        >
          確定
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}