import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'
dayjs.extend(utc)

/**
 * 交易檢查
 */
export async function transactionCheck(transaction: TransactionCustomer) {
  const msgs: string[] = []
  // 
  // (1) 檢查交易日期：當日15:00前，檢查日期是當日；當日15:00後，檢查日期非當日
  //
  const today = dayjs().utc(true).startOf('day')
  const other = dayjs.utc(transaction.get('date'))
  const now = dayjs()
  if (now.hour() < 15 && !other.isSame(today, 'day')) {
    msgs.push('日期不是今天？')
  } else if (now.hour() >= 15 && other.isSame(today, 'day')) {
    msgs.push('日期是今天？')
  }
  // 
  // (2) 檢查交易明細：貨物過去有出過、數量不為0、價格是預設價格
  // 
  const pastTransactions = await new Parse.Query(TransactionCustomer)
    .limit(10000)
    .equalTo('customer', transaction.get('customer'))
    .select('details')
    .find()
  const pastItemIds = pastTransactions.flatMap(t => t.get('details')?.map(d => d.item.id) || [])
  for (let { item, qty, price } of transaction.get('details') || []) {
    // 貨物過去有出過
    if (!pastItemIds.includes(item.id)) {
      msgs.push(`${item.get('name')}【${item.get('spec')}】_過去沒有出過？`)
    }
    // 數量不為0
    if (qty === 0) {
      msgs.push(`${item.get('name')}【${item.get('spec')}】_數量為0？`)
    }
    // 價格是預設價格
    const itemPricing = item.getDefaultPricing()
    if (itemPricing && price !== itemPricing.price) {
      msgs.push(`${item.get('name')}【${item.get('spec')}】_不是預設價格？`)
    }
  }

  return msgs
}