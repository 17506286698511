import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import BasicTextField from '../../input/BasicTextField'
import BasicNumberField from '../../input/BasicNumberField'
import { Item } from '../../parse-class/Item'

interface IFields {
  name: string
  source: string
  spec: string
  specDescription: string
  pkg: string
  pkgDescription: string
  unitName: string
  unitQty: number
  unitExact: boolean
  pkgWeight: number
  pkgLength: number
  pkgWidth: number
  pkgHeight: number
  origin: string
  note: string
  available: boolean
  pos: string
  cap: number
  feature: string
}

interface IParams {
  itemId: string | null
  onClose: () => void
  onChange: () => void
}

export default function EditDialog(props: IParams) {

  const { itemId, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // data
  const [raw, setRaw] = React.useState<Item | null>(null)
  // state
  const [tab, setTab] = React.useState(0)
  const [fields, setFields] = React.useState<IFields>()

  const verify = React.useMemo(() => {
    return fields && fields.name.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Item)
      const item = await query.get(id)
      setRaw(item)
      setFields({
        name: item.get('name') || '',
        source: item.get('source') || '',
        spec: item.get('spec') || '',
        specDescription: item.get('specDescription') || '',
        pkg: item.get('pkg') || '',
        pkgDescription: item.get('pkgDescription') || '',
        unitName: item.get('unitName') || '',
        unitQty: item.get('unitQty') || 0,
        unitExact: item.get('unitExact') || false,
        pkgWeight: item.get('pkgWeight') || 0,
        pkgLength: item.get('pkgLength') || 0,
        pkgWidth: item.get('pkgWidth') || 0,
        pkgHeight: item.get('pkgHeight') || 0,
        origin: item.get('origin') || '',
        note: item.get('note') || '',
        available: item.get('available') || false,
        pos: item.get('pos') || '',
        cap: item.get('cap') || 0,
        feature: item.get('feature') || '',
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (itemId !== null) {
      loadData(itemId)
      setTab(0)
    } else {
      setFields(undefined)
    }
  }, [itemId, loadData])

  const onSave = React.useCallback(async () => {
    if (raw && fields && verify) {
      const hasUpdateInventory = (raw.get('pos') !== fields.pos) || (raw.get('cap') !== fields.cap) // 是否有更新庫存設定
      try {
        backdrop.show('更新資料中')
        raw.set('name', fields.name)
        raw.set('source', fields.source)
        raw.set('spec', fields.spec)
        raw.set('specDescription', fields.specDescription)
        raw.set('pkg', fields.pkg)
        raw.set('pkgDescription', fields.pkgDescription)
        raw.set('unitName', fields.unitName)
        raw.set('unitQty', fields.unitQty)
        raw.set('unitExact', fields.unitExact)
        raw.set('pkgWeight', fields.pkgWeight)
        raw.set('pkgLength', fields.pkgLength)
        raw.set('pkgWidth', fields.pkgWidth)
        raw.set('pkgHeight', fields.pkgHeight)
        raw.set('origin', fields.origin)
        raw.set('note', fields.note)
        raw.set('available', fields.available)
        raw.set('pos', fields.pos)
        raw.set('cap', fields.cap)
        raw.set('feature', fields.feature)
        await raw.save()
        // 如果有更新庫存設定，就更新同種貨物
        if (hasUpdateInventory) {
          await raw.syncInventoryGroupData()
        }
        snackbar.show('更新成功')
        onChange(); // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raw, verify, fields, onChange])

  if (fields === undefined) {
    return null
  }

  return (
    <Dialog
      open={itemId !== null}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <Tabs value={tab} onChange={(e, value) => { setTab(value) }}>
        <Tab label="貨物資料" />
        <Tab label="成本單位" />
        <Tab label="包裝材積" />
        <Tab label="物流倉儲" />
      </Tabs>
      {tab === 0 &&
        <DialogContent>
          <BasicTextField
            label="名稱"
            defaultValue={fields.name}
            onChange={e => setFields(pv => ({ ...pv!, name: e.target.value }))}
          />
          <BasicTextField
            label="來源"
            defaultValue={fields.source}
            onChange={e => setFields(pv => ({ ...pv!, source: e.target.value }))}
          />
          <BasicTextField
            label="規格"
            defaultValue={fields.spec}
            onChange={e => setFields(pv => ({ ...pv!, spec: e.target.value }))}
          />
          <BasicTextField
            label="規格描述"
            defaultValue={fields.specDescription}
            onChange={e => setFields(pv => ({ ...pv!, specDescription: e.target.value }))}
          />
          <BasicTextField
            label="包裝"
            defaultValue={fields.pkg}
            onChange={e => setFields(pv => ({ ...pv!, pkg: e.target.value }))}
          />
          <BasicTextField
            label="包裝描述"
            defaultValue={fields.pkgDescription}
            onChange={e => setFields(pv => ({ ...pv!, pkgDescription: e.target.value }))}
          />
          <BasicTextField
            label="產地"
            defaultValue={fields.origin}
            onChange={e => setFields(pv => ({ ...pv!, origin: e.target.value }))}
          />
          <BasicTextField
            label="備註"
            defaultValue={fields.note}
            onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
            multiline
            rows={3}
          />
        </DialogContent>
      }
      {tab === 1 &&
        <DialogContent>
          <BasicNumberField
            label="成本數量"
            defaultValue={fields.unitQty}
            onChange={e => setFields(pv => ({ ...pv!, unitQty: Number(e.target.value) }))}
          />
          <BasicTextField
            label="成本單位"
            defaultValue={fields.unitName}
            onChange={e => setFields(pv => ({ ...pv!, unitName: e.target.value }))}
          />
        </DialogContent>
      }
      {tab === 2 &&
        <DialogContent>
          <BasicNumberField
            label="重量（kg）"
            defaultValue={fields.pkgWeight}
            onChange={e => setFields(pv => ({ ...pv!, pkgWeight: Number(e.target.value) }))}
          />
          <BasicNumberField
            label="長度（cm）"
            defaultValue={fields.pkgLength}
            onChange={e => setFields(pv => ({ ...pv!, pkgLength: Number(e.target.value) }))}
          />
          <BasicNumberField
            label="寬度（cm）"
            defaultValue={fields.pkgWidth}
            onChange={e => setFields(pv => ({ ...pv!, pkgWidth: Number(e.target.value) }))}
          />
          <BasicNumberField
            label="高度（cm）"
            defaultValue={fields.pkgHeight}
            onChange={e => setFields(pv => ({ ...pv!, pkgHeight: Number(e.target.value) }))}
          />
        </DialogContent>
      }
      {tab === 3 &&
        <DialogContent>
          <BasicTextField
            label="貨物特徵"
            defaultValue={fields.feature}
            onChange={e => setFields(pv => ({ ...pv!, feature: e.target.value }))}
          />
          <BasicTextField
            label="庫存位置"
            defaultValue={fields.pos}
            onChange={e => setFields(pv => ({ ...pv!, pos: e.target.value }))}
          />
          <BasicNumberField
            label="庫存容量"
            defaultValue={fields.cap}
            onChange={e => setFields(pv => ({ ...pv!, cap: Number(e.target.value) }))}
            helperText="更新庫存位置和容量時，會自動換算並套用到同種貨物上"
          />
        </DialogContent>
      }
      <DialogActions>
        {tab === 0 &&
          <FormControlLabel
            label="可交易"
            control={
              <Checkbox
                defaultChecked={fields.available}
                onChange={e => setFields(pv => ({ ...pv!, available: e.target.checked }))}
                color="default"
              />
            }
          />
        }
        {tab === 1 &&
          <FormControlLabel
            label="精準數量"
            control={
              <Checkbox
                defaultChecked={fields.unitExact}
                onChange={e => setFields(pv => ({ ...pv!, unitExact: e.target.checked }))}
                color="default"
              />
            }
          />
        }
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}