
interface Cache {
  purchaseQty: number     // 年採購量
  salesQty: number        // 年銷售量
  cost: number            // 年採購額
  revenue: number         // 年銷售額
}

export interface IAttributes {
  code: string            // 貨物編號
  fullName: string        // 全稱
  shortName: string       // 簡稱
  unit: string            // 單位
  pcsPkg: number          // 每件數量
  base: number            // 底價
  point: number           // 點數
  price1: number          // 定價1
  price2: number          // 定價2
  price3: number          // 定價3
  pos: string             // 庫存位置
  stock: number           // 庫存量
  provider: string        // 供應商
  memo: string            // 備註
  cache: Cache            // 快取資料
}

export const CLASS_NAME = 'ZItem'

export class ZItem extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, ZItem)


