
export interface IAttributes {
  placeId: string
  name: string
  position: Parse.GeoPoint
  city: string
  district: string
  address: string
  tags: string[]
}

export const CLASS_NAME = 'GoogleMapPlace'

export class GoogleMapPlace extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }

  setTags(tags: string[]) {
    const format = tags
      .map(t => t.trim())                  // 去除前後空白字元
      .filter(t => t.length > 0)           // 去除空標籤
    this.set('tags', [...new Set(format)]) // 去除重複
  }
}

Parse.Object.registerSubclass(CLASS_NAME, GoogleMapPlace)