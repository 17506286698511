import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DateField from '../../input/DateField'
import UserAutocomplete from '../../input/UserAutocomplete'
import { User } from '../../parse-class/User'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  paymentPerson: User | null
}

interface Props extends DialogProps {
  onUpdate: (options: { date: Date, paymentPerson: User }) => void;
}

export default function OptionDialog(props: Props) {

  const { onUpdate, ...dialogProps } = props
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    paymentPerson: null
  })

  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        paymentPerson: null
      })
    }
  }, [dialogProps.open])

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>選項</DialogTitle>
        <DialogContent>
          <DateField
            value={fields.date}
            onDateChange={(event, value) => {
              setFields(pv => ({ ...pv!, date: value }))
            }}
          />
          <UserAutocomplete
            roles={['LOGISTICIAN']}
            value={fields.paymentPerson}
            onChange={(event, value) => setFields(pv => ({ ...pv!, paymentPerson: value }))}
            textFieldProps={{ label: '金流人員' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={fields.paymentPerson === null}
            color="primary"
            onClick={() => onUpdate({
              date: fields.date,
              paymentPerson: fields.paymentPerson!
            })}
          >
            確定
          </Button>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}