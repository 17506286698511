
export interface IAttributes {
  name: string
  alive: Date
}

export const CLASS_NAME = 'PosPrinter'

export class PosPrinter extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, PosPrinter)