/**
 * 點貨對話框
 */
import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { IOrderOptions } from './OrderOptionsDialog'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'

interface Props extends DialogProps {
  options?: IOrderOptions
}

export default function OrderDialog(props: Props) {

  const { options, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [transactionList, setTransactionList] = React.useState<TransactionCustomer[]>([])
  // state
  const [reverse, setReverse] = React.useState(true)  // 預設後送先點

  const loadData = React.useCallback(async (options: IOrderOptions) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(TransactionCustomer)
        .equalTo('date', options.date)                                  // 指定日期
        .equalTo('deliveryPerson', options.deliveryPerson)              // 指定物流人員
        .ascending('deliveryOrder')
        .include('customer')
        // @ts-ignore
        .include('details.item')
        .find()
      setTransactionList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && options) {
      loadData(options)
    }
  }, [dialogProps.open, options, loadData])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>點貨</DialogTitle>
      <DialogContent>
        <Stack spacing={2} direction={reverse ? 'column-reverse' : 'column'}>
          {transactionList.map(transaction => {
            const customer = transaction.get('customer')
            return (
              <Card key={transaction.id} variant="outlined">
                <CardContent>
                  <Typography sx={{ fontSize: 16 }} color="text.primary">
                    {customer?.get('name')}
                  </Typography>
                  <Typography sx={{ fontSize: 14, mb: 2 }} color="text.secondary">
                    {transaction.get('note')}
                  </Typography>
                  <Stack spacing={1}>
                    {transaction.get('details')?.map((detail, index) => {
                      const item = detail.item
                      return (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box>
                              <Typography sx={{ fontSize: 14 }}>
                                {item ? item.getFormatName({ pkg: false }) : '未知的貨物'}
                              </Typography>
                            </Box>
                            <Box sx={{ flex: 1 }}></Box>
                            <Box>
                              <Typography sx={{ fontSize: 14 }} noWrap>
                                {detail.qty} {item.get('pkg')}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: 14, color: '#888' }}>
                              {item.get('feature')}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    })}
                  </Stack>
                </CardContent>
              </Card>
            )
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          label="後送先點"
          control={
            <Checkbox
              checked={reverse}
              onChange={e => { setReverse(e.target.checked) }}
              color="default"
            />
          }
        />
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>

  )
}