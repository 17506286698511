import { PosPrinter } from '../PosPrinter'

export interface IAttributes {
  printer: PosPrinter
  template: string
  params: string
}
export const CLASS_NAME = 'PosPrinterTask'

export class PosPrinterTask extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, PosPrinterTask)