
export interface IAttributes {
  raw: Parse.File         // 原圖
  compression: Parse.File // 壓縮圖
  x1440: Parse.File       // 縮圖1440
  x300: Parse.File        // 縮圖300
  placeholder: Parse.File // 佔位圖
}

export const CLASS_NAME = 'Image'

export class Image extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, Image)