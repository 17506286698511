import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export default function BasicNumberField(textFieldProps: TextFieldProps) {

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      type="number"
      onFocus={e => e.target.select()} // 點擊自動全選
      // @ts-ignore
      onWheel={e => { e.target.blur() }} // 避免滾輪改到數字
      {...textFieldProps}
    />
  )
}