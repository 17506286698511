import { Item } from '../Item'

export interface IAttributes {
  group: string     // 分類
  date: Date        // 日期
  item: Item        // 貨物
  qty: number       // 數量（+/-）
  note: string      // 備註
}

export const CLASS_NAME = 'InventoryAdjustment'

export class InventoryAdjustment extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, InventoryAdjustment)