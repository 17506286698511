import dayjs from 'dayjs'
import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import BasicTextField from '../../input/BasicTextField'
import BasicNumberField from '../../input/BasicNumberField'
import ProviderAutocomplete from '../../input/ProviderAutocomplete'
import UserAutocomplete from '../../input/UserAutocomplete'
import TransactionDetailGrid from '../TransactionCustomerPage/TransactionDetailGrid'
import { Provider } from '../../parse-class/Provider'
import { Item } from '../../parse-class/Item'
import { User } from '../../parse-class/User'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'
import { TransactionProvider, ITransactionDetail } from '../../parse-class/TransactionProvider'

interface IFields {
  date: Date
  target: Provider | null
  note: string
  details: ITransactionDetail[]
  deliveryPerson: User | null
  deliveryTime: dayjs.Dayjs | null
  paymentPerson: User | null
  paymentTime: dayjs.Dayjs | null
  allowance: number
}

interface IProps extends DialogProps {
  editingId: string | null
  onChange: () => void
}

export default function EditDialog(props: IProps) {

  const { editingId, onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data 
  const [raw, setRaw] = React.useState<TransactionProvider | null>(null)
  const [items, setItems] = React.useState<Item[]>([])
  // state
  const [fields, setFields] = React.useState<IFields>()
  const [tab, setTab] = React.useState(0)

  const verify = React.useMemo(() => {
    return fields
      && fields.target !== null
      && fields.deliveryPerson !== null
      && fields.paymentPerson !== null
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(TransactionProvider)
        // @ts-ignore
        .include('details.item')
        .get(id)
      setRaw(data)
      setFields({
        date: data.get('date')!,
        target: data.get('provider')!,
        note: data.get('note')!,
        details: data.get('details')!,
        deliveryPerson: data.get('deliveryPerson') || null,
        deliveryTime: data.get('deliveryTime') ? dayjs(data.get('deliveryTime')) : null,
        paymentPerson: data.get('paymentPerson') || null,
        paymentTime: data.get('paymentTime') ? dayjs(data.get('paymentTime')) : null,
        allowance: data.get('allowance')!,
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (editingId !== null) {
      loadData(editingId)
      setTab(0)
    } else {
      setFields(undefined)
    }
  }, [loadData, editingId])

  const loadItemsData = React.useCallback(async () => {
    if (fields?.target) {
      // 有在採購清單的排前面
      const yes = await PurchaseHelper.getItems(fields.target)
      const no = await new Parse.Query(Item)
        .limit(10000)
        .notContainedIn('objectId', yes.map(i => i.id))
        .find()
      setItems([...yes, ...no])
    } else {
      setItems([])
    }
  }, [fields?.target])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadItemsData()
    }
  }, [dialogProps.open, loadItemsData])

  const onSave = React.useCallback(async () => {
    if (raw && fields && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('date', fields.date)
        raw.set('provider', fields.target!)
        raw.set('details', fields.details)
        raw.set('note', fields.note)
        raw.set('deliveryPerson', fields.deliveryPerson!)
        fields.deliveryTime ? raw.set('deliveryTime', fields.deliveryTime.toDate()) : raw.unset('deliveryTime')
        raw.set('paymentPerson', fields.paymentPerson!)
        fields.paymentTime ? raw.set('paymentTime', fields.paymentTime.toDate()) : raw.unset('paymentTime')
        raw.set('allowance', fields.allowance)
        await raw.save()
        snackbar.show('更新成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, raw, fields, onChange])

  /**
   * 計算交易金額
   */
  const amount = React.useMemo(() => {
    if (fields) {
      const sum = fields.details.reduce((pre, cur) => pre + Math.round(cur.qty * cur.price), 0)
      return sum - fields.allowance
    } else {
      return 0
    }
  }, [fields])

  if (editingId === null || raw === null || fields === undefined) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <Tabs value={tab} onChange={(e, value) => { setTab(value) }}>
          <Tab label="供應商交易" />
          <Tab label="物流金流" />
        </Tabs>
        {tab === 0 &&
          <DialogContent>
            <DateField
              defaultValue={fields.date}
              onDateChange={(event, value) => {
                setFields(pv => ({ ...pv!, date: value }))
              }}
            />
            <ProviderAutocomplete
              value={fields.target}
              onChange={(event, value) => setFields(pv => ({ ...pv!, target: value }))}
              textFieldProps={{ autoFocus: true }}
            />
            <BasicTextField
              label="備註"
              defaultValue={fields.note}
              onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
              multiline
              rows={3}
            />
            <TransactionDetailGrid
              items={items}
              defaultValue={fields.details}
              onUpdate={rows => setFields(pv => ({ ...pv!, details: rows }))}
              autoApplyPricing="COST"
            />
          </DialogContent>
        }
        {tab === 1 &&
          <DialogContent>
            <UserAutocomplete
              roles={['LOGISTICIAN']}
              value={fields.deliveryPerson}
              onChange={(event, value) => setFields(pv => ({ ...pv!, deliveryPerson: value }))}
              textFieldProps={{ label: '物流人員' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={props => <BasicTextField {...props} />}
                label="物流完成時戳"
                inputFormat="YYYY-MM-DD HH:mm"
                value={fields.deliveryTime}
                onChange={value => { setFields(pv => ({ ...pv!, deliveryTime: value })) }}
              />
            </LocalizationProvider>
            <UserAutocomplete
              roles={['LOGISTICIAN']}
              value={fields.paymentPerson}
              onChange={(event, value) => setFields(pv => ({ ...pv!, paymentPerson: value }))}
              textFieldProps={{ label: '金流人員' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={props => <BasicTextField {...props} />}
                label="金流完成時戳"
                inputFormat="YYYY-MM-DD HH:mm"
                value={fields.paymentTime}
                onChange={value => { setFields(pv => ({ ...pv!, paymentTime: value })) }}
              />
            </LocalizationProvider>
            <BasicNumberField
              label="折讓"
              defaultValue={fields.allowance}
              onChange={e => setFields(pv => ({ ...pv!, allowance: Number(e.target.value) }))}
            />
            <BasicNumberField
              disabled
              label="總額"
              value={amount}
            />
          </DialogContent>
        }
        <DialogActions>
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            儲存
          </Button>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
          {tab === 1 &&
            <React.Fragment>
              <Button
                disabled={fields.deliveryTime !== null}
                color="success"
                onClick={() => { setFields(pv => ({ ...pv!, deliveryTime: dayjs() })) }}
              >
                物流完成
              </Button>
              <Button
                disabled={fields.paymentTime !== null}
                color="success"
                onClick={() => { setFields(pv => ({ ...pv!, paymentTime: dayjs() })) }}
              >
                金流完成
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}