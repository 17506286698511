import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import TaxTypeSelect, { TaxType } from '../../input/TaxTypeSelect'
import DateField from '../../input/DateField'
import TaxEntityAutocomplete from '../../input/TaxEntityAutocomplete'
import DetailGrid, { IDetailRow } from './DetailGrid'
import { TaxEntity } from '../../parse-class/TaxEntity'
import { UniformInvoice } from '../../parse-class/UniformInvoice'
import { getSelfTaxEntity } from '../../cloud/get-self-tax-entity'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const formater = new Intl.NumberFormat('zh-tw')

interface IFields {
  date: Date
  code: string
  entity: TaxEntity | null
  taxType: TaxType
  add: boolean
  details: IDetailRow[]
}

interface IProps extends DialogProps {
  direction: 'INPUT' | 'OUTPUT'
  onUpdate: () => void
}

export default function AddDialog(props: IProps) {

  const { direction, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [selfTaxEntity, setSelfTaxEntity] = React.useState<TaxEntity | null>(null)
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    code: '',
    entity: null,
    taxType: 'TAXABLE',
    add: false,
    details: []
  })

  const verify = React.useMemo(() => {
    return fields.code.trim().length > 0
      && fields.entity !== null
      && fields.details.length > 0
  }, [fields])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const selfTaxEntity = await getSelfTaxEntity()
      setSelfTaxEntity(selfTaxEntity)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        code: '',
        entity: null,
        taxType: 'TAXABLE',
        add: false,
        details: []
      })
    }
  }, [dialogProps.open, loadData])

  const calculator = React.useMemo(() => {
    const invoice = new UniformInvoice()
    invoice.set('taxType', fields.taxType)
    invoice.set('details', fields.details)
    return invoice.taxCalculator()
  }, [fields])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const invoice = new UniformInvoice()
        invoice.set('code', fields.code)
        invoice.set('date', fields.date)
        invoice.set('fromEntity', direction === 'INPUT' ? fields.entity! : selfTaxEntity!)
        invoice.set('toEntity', direction === 'OUTPUT' ? fields.entity! : selfTaxEntity!)
        invoice.set('taxType', fields.taxType)
        invoice.set('add', fields.add)
        invoice.set('details', fields.details)
        await invoice.save()
        snackbar.show('新增成功')
        onUpdate() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, selfTaxEntity, direction, fields, onUpdate])

  if (selfTaxEntity === null) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>新增{direction === 'INPUT' ? '進貨' : '銷貨'}統一發票</DialogTitle>
        <DialogContent>
          <DateField
            value={fields.date}
            onDateChange={(event, value) => {
              setFields(pv => ({ ...pv, date: value }))
            }}
          />
          <TaxEntityAutocomplete
            value={fields.entity}
            onChange={(event, value) => setFields(pv => ({ ...pv, entity: value }))}
            textFieldProps={{ autoFocus: true }}
          />
          <BasicTextField
            label="發票號碼"
            name="發票號碼"
            value={fields.code}
            onChange={e => setFields(pv => ({ ...pv, code: e.target.value }))}
          />
          <TaxTypeSelect
            value={fields.taxType}
            onChange={e => setFields(pv => ({ ...pv, taxType: e.target.value as TaxType }))}
          />
          <DetailGrid
            onUpdate={rows => {
              setFields(pv => ({ ...pv, details: rows }))
            }}
          />
          <Typography>銷售額：{formater.format(calculator.sales)}</Typography>
          <Typography>營業稅：{formater.format(calculator.tax)}</Typography>
          <Typography>總　計：{formater.format(calculator.sum)}</Typography>
        </DialogContent>
        <DialogActions>
          {calculator.hasTwoTax && fields.taxType === 'TAXABLE' &&
            <FormControlLabel
              label="加1元"
              control={
                <Checkbox
                  checked={fields.add}
                  onChange={e => setFields(pv => ({ ...pv!, add: e.target.checked }))}
                  color="default"
                />
              }
            />
          }
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            新增
          </Button>
          <Button
            color="error"
            onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}