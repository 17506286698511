import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import { PosPrinter } from '../../parse-class/PosPrinter'

interface IFields {
  name: string
}

interface IParams {
  open: boolean
  onClose: () => void
  onChange: () => void
}

export default function AddDialog(props: IParams) {

  const { open, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    name: ''
  })

  const verify = React.useMemo(() => {
    return fields.name.trim().length > 0
  }, [fields])

  React.useEffect(() => {
    if (open) {
      setFields({
        name: ''
      })
    }
  }, [open, setFields])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const printer = new PosPrinter()
        printer.set('name', fields.name)
        await printer.save()
        snackbar.show('新增成功')
        onChange() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, onChange])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>新增出單機</DialogTitle>
      <DialogContent>
        <BasicTextField
          value={fields.name}
          label="名稱"
          type="text"
          autoFocus
          onChange={e => setFields(pv => ({ ...pv, name: e.target.value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button
          color="error"
          onClick={onClose}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}