import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import { TaxEntity } from '../../parse-class/TaxEntity'

interface IFields {
  nickname: string
  taxTitle: string
  taxId: string
}

interface IParams extends DialogProps {
  onUpdate: () => void
}

export default function AddDialog(props: IParams) {

  const { onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    nickname: '',
    taxTitle: '',
    taxId: '',
  })
  const verify = React.useMemo(() => {
    return fields.nickname.trim().length > 0
      && fields.taxTitle.trim().length > 0
      && fields.taxId.trim().length > 0
  }, [fields])

  /**
   * 打開視窗時，初始化資料
   */
  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        nickname: '',
        taxTitle: '',
        taxId: '',
      })
    }
  }, [dialogProps.open])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const taxEntity = new TaxEntity()
        taxEntity.set('nickname', fields.nickname)
        taxEntity.set('taxTitle', fields.taxTitle)
        taxEntity.set('taxId', fields.taxId)
        await taxEntity.save()
        snackbar.show('新增成功')
        onUpdate() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, fields, onUpdate])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>新增抬頭統編</DialogTitle>
      <DialogContent>
        <BasicTextField
          label="名稱"
          value={fields.nickname}
          onChange={e => setFields(pv => ({ ...pv, nickname: e.target.value }))}
          autoFocus
        />
        <BasicTextField
          label="統編"
          value={fields.taxId}
          onChange={e => setFields(pv => ({ ...pv, taxId: e.target.value }))}
        />
        <BasicTextField
          label="抬頭"
          value={fields.taxTitle}
          onChange={e => setFields(pv => ({ ...pv, taxTitle: e.target.value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}