import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import ProviderAutocomplete from '../../input/ProviderAutocomplete'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { Provider } from '../../parse-class/Provider'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'

interface IFields {
  provider: Provider | null
}

interface IOptions {
  provider: Provider
}

interface IParams extends DialogProps {
  onUpdate: (options: IOptions) => void
}

export default function OptionsDialog(props: IParams) {

  const { onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [providers, setProviders] = React.useState<Provider[] | null>(null)
  // state
  const [fields, setFields] = React.useState<IFields>({
    provider: null
  })

  const verify = React.useMemo(() => {
    return fields.provider !== null
  }, [fields])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      // 有交易的客戶排在前面
      const yes = await PurchaseHelper.getProviders() // 有交易的客戶
      const no = await new Parse.Query(Provider)      // 沒交易的客戶
        .notContainedIn('objectId', yes.map(p => p.id))
        .limit(10000)
        .find()
      setProviders([...yes, ...no])
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
      setFields({
        provider: null
      })
    }
  }, [dialogProps.open, loadData])

  const onSave = React.useCallback(() => {
    if (verify) {
      onUpdate({
        provider: fields.provider!
      })
    }
  }, [verify, fields, onUpdate])

  if (providers === null) {
    return null
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>選項</DialogTitle>
      <DialogContent>
        <ProviderAutocomplete
          value={fields.provider}
          onChange={(event, value) => setFields(pv => ({ ...pv, provider: value }))}
          textFieldProps={{ autoFocus: true }}
          data={providers}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          確定
        </Button>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}