import React from 'react'
import dayjs from 'dayjs'
import { DataGridPremium, GridColumns, GridToolbarContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import SettingsIcon from '@mui/icons-material/Settings'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import OptionDialog from './OptionDialog'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'
import { User } from '../../parse-class/User'
import { Customer } from '../../parse-class/Customer'

export interface IReportRow {
  id: string               // 交易ID
  date: Date
  customer: Customer
  sum: number              // 交易金額
  allowance: number        // 折讓
  unfinished: number       // 未沖金額
  todayFinished: number    // 當日沖銷金額
}

export const FIELDS = {
  DATE: 'DATE',
  CUSTOMER: 'CUSTOMER',
  SUM: 'SUM',
  ALLOWANCE: 'ALLOWANCE',
  UNFINISHED: 'UNFINISHED',
  TODAY_FINISHED: 'TODAY_FINISHED',
}

interface Options {
  date: Date
  paymentPerson: User
}

export function ReportDailyTransactionPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [reportRows, setReportRows] = React.useState<IReportRow[] | null>(null)
  // state
  const [options, setOptions] = React.useState<Options | undefined>(undefined)
  const [showOptionDialog, setShowOptionDialog] = React.useState(false)

  const loadData = React.useCallback(async (date: Date, paymentPerson: User) => {
    try {
      backdrop.show('讀取資料中')
      const today = dayjs(date)
      const data: IReportRow[] = []
      /**
       * 指定「日期」該「金流人員」所有客戶交易
       */
      // 讀取資料庫
      const transactions = await new Parse.Query(TransactionCustomer)
        .equalTo('date', today.toDate())                // 指定日期
        .equalTo('paymentPerson', paymentPerson)        // 指定金流人員
        .include('customer')
        .find()
      // 格式化資料
      for (let transaction of transactions) {
        const customer = transaction.get('customer')
        const sum = transaction.sum()
        const allowance = transaction.get('allowance') || 0
        const unfinished = sum - allowance
        const paymentTime = transaction.get('paymentTime')
        const todayFinish = paymentTime && dayjs(date).isSame(paymentTime, 'day')
        data.push({
          id: transaction.id,
          date: transaction.get('date')!,
          customer: customer!,
          sum: sum,
          allowance: allowance,
          unfinished: unfinished,
          todayFinished: todayFinish ? unfinished : 0
        })
      }
      /**
       * 非指定日期，指定「金流人員」所有客戶交易，「金流日期」為指定日期
       */
      // 讀取資料庫
      const otherTransactions = await new Parse.Query(TransactionCustomer)
        .notEqualTo('date', today.toDate())                                 // 指定日期
        .equalTo('paymentPerson', paymentPerson)                            // 指定金流人員
        .greaterThanOrEqualTo('paymentTime', today.startOf('day').toDate()) // 「金流日期」為指定日期
        .lessThanOrEqualTo('paymentTime', today.endOf('day').toDate())
        .include('customer')
        .find()
      // 格式化資料
      for (let transaction of otherTransactions) {
        const customer = transaction.get('customer')
        const sum = transaction.sum()
        const allowance = transaction.get('allowance') || 0
        const unfinished = sum - allowance
        const paymentTime = transaction.get('paymentTime')
        const todayFinish = paymentTime && dayjs(date).isSame(paymentTime, 'day')
        data.push({
          id: transaction.id,
          date: transaction.get('date')!,
          customer: customer!,
          sum: sum,
          allowance: allowance,
          unfinished: unfinished,
          todayFinished: todayFinish ? unfinished : 0
        })
      }
      // 設定
      setReportRows(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (options) {
      loadData(options.date, options.paymentPerson)
    } else {
      setShowOptionDialog(true)
    }
  }, [options, loadData])

  const sum = React.useMemo(() => {
    let todayFinished = 0
    let todayUnfinished = 0
    if (reportRows) {
      for (let row of reportRows) {
        todayFinished += row.todayFinished
      }
    }
    return { todayFinished, todayUnfinished }
  }, [reportRows])

  const rows = React.useMemo(() => {
    if (!reportRows) {
      return []
    }
    return reportRows.map(row => ({
      id: row.id,
      [FIELDS.DATE]: row.date,
      [FIELDS.CUSTOMER]: row.customer.get('name'),
      [FIELDS.SUM]: row.sum,
      [FIELDS.ALLOWANCE]: row.allowance,
      [FIELDS.UNFINISHED]: row.unfinished,
      [FIELDS.TODAY_FINISHED]: row.todayFinished,
    }))
  }, [reportRows])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
      },
      {
        field: FIELDS.CUSTOMER,
        headerName: '客戶',
        width: 160,
      },
      {
        field: FIELDS.SUM,
        headerName: '交易金額',
        type: 'number',
        width: 100,
      },
      {
        field: FIELDS.ALLOWANCE,
        headerName: '折讓',
        type: 'number',
        width: 80,
      },
      {
        field: FIELDS.UNFINISHED,
        headerName: '未沖金額',
        type: 'number',
        width: 100,
      },
      {
        field: FIELDS.TODAY_FINISHED,
        headerName: '當日沖銷',
        type: 'number',
        width: 100,
      },
      {
        field: 'OVER',
        headerName: '餘額',
        type: 'number',
        width: 100,
        valueGetter: params => params.row[FIELDS.UNFINISHED] - params.row[FIELDS.TODAY_FINISHED]
      },
    ]
  }, [])

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="客戶交易金流日報表" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              disableSelectionOnClick
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<SettingsIcon />}
                      onClick={() => { setShowOptionDialog(true) }}
                    >
                      選項
                    </Button>
                  </GridToolbarContainer>
                ),
                Footer: CustomFooterComponent
              }}
              componentsProps={{ footer: { todayFinished: sum.todayFinished } }}
            />
          </Box>
        </StandardBox>
      </Container>
      <OptionDialog
        open={showOptionDialog}
        onClose={() => { setShowOptionDialog(false) }}
        fullWidth
        maxWidth='xs'
        onUpdate={options => {
          setOptions(options)
          setShowOptionDialog(false) // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}

export function CustomFooterComponent(props: { todayFinished: number }) {
  return (
    <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
      當日沖銷總額：{Intl.NumberFormat().format(props.todayFinished)}
    </Box>
  )
}