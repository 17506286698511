import { Customer } from '../Customer'
import { Item } from '../Item'

export interface IAttributes {
  date: Date
  customer: Customer
  item: Item
  note: string
}

export const CLASS_NAME = 'MarketingSample'

export class MarketingSample extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, MarketingSample)