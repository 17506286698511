import React from 'react';
import EventEmitter from 'events';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

/**
 * Context
 */
interface IConfirmOption {
  title?: string
  content?: string
  sure?: string
  close?: string
}
type TConfirm = (option?: IConfirmOption) => Promise<boolean>
export const ConfirmContext = React.createContext<{ confirm: TConfirm }>({
  confirm: () => new Promise(resolve => resolve(false))
})

/**
 * Provider
 */
export function ConfirmProvider(props: React.PropsWithChildren<{}>) {

  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  const [sureText, setSureText] = React.useState('');
  const [closeText, setCloseText] = React.useState('');

  const event = React.useMemo(() => new EventEmitter(), [])

  const fn = React.useCallback(() => new Promise<boolean>(resolve => {
    event.once('result', (result: boolean) => {
      resolve(result);
    })
  }), [event])

  const onClose = React.useCallback(() => {
    event.emit('result', false);
  }, [event])

  const onSure = React.useCallback(() => {
    event.emit('result', true)
  }, [event])

  const confirm: TConfirm = async option => {
    setOpen(true)
    if (option) {
      setTitle(option.title || '確定嗎？');
      setContent(option.content || '');
      setSureText(option.sure || '確定');
      setCloseText(option.close || '關閉');
    }
    const result = await fn()
    setOpen(false)
    return result
  }

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {props.children}
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        {content.length > 0 &&
          <DialogContent>
            <DialogContentText>{content}</DialogContentText>
          </DialogContent>
        }
        <DialogActions>
          <Button onClick={onSure} color="error">
            {sureText}
          </Button>
          <Button onClick={onClose} color="primary" autoFocus>
            {closeText}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmContext.Provider>
  )
}