import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ClientPreferenceContext } from '../../context/client-preference'
import PosPrinterAutocomplete from '../../input/PosPrinterAutocomplete'
import BasicTextField from '../../input/BasicTextField'
import BasicNumberField from '../../input/BasicNumberField'
import UserAutocomplete from '../../input/UserAutocomplete'
import { PosPrinter } from '../../parse-class/PosPrinter'
import { User } from '../../parse-class/User'
import { createSalesQuotationCoverTask } from '../../pos-printer/create-sales-quotation-cover-task'

interface IFields {
  printer: PosPrinter | null
  title: string
  salesperson: User | null
  copies: number
}

export interface IParams extends DialogProps {
}

export default function PrintCoverDialog(props: IParams) {

  const { ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const clientPreference = React.useContext(ClientPreferenceContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    printer: null,
    title: `${new Date().getMonth() + 1}月報價單`,
    salesperson: null,
    copies: 1,
  })

  React.useEffect(() => {
    if (dialogProps.open) {
      const defaultPosPrinter = clientPreference.get().defaultPosPrinter
      setFields({
        printer: defaultPosPrinter || null,
        title: `${new Date().getMonth() + 1}月報價單`,
        salesperson: null,
        copies: 1,
      })
    }
  }, [dialogProps.open, clientPreference])

  const verify = React.useMemo(() => {
    return fields.printer !== null && fields.salesperson !== null
  }, [fields])

  const onClickResult = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('上傳列印任務中')
        await createSalesQuotationCoverTask({
          printer: fields.printer!,
          title: fields.title,
          salesperson: fields.salesperson?.get('name') || '',
          cellphone: fields.salesperson?.get('cellphone') || '',
          copies: fields.copies
        })
        snackbar.show('列印成功')
        dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') // 關閉視窗
      } catch (e: any) {
        snackbar.show('上傳列印任務失敗' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, fields, dialogProps])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>列印銷貨報價單封面</DialogTitle>
      <DialogContent>
        <PosPrinterAutocomplete
          value={fields.printer}
          onChange={(event, value) => setFields(pv => ({ ...pv, printer: value }))}
        />
        <BasicTextField
          value={fields.title}
          label="標題"
          onChange={e => setFields(pv => ({ ...pv, title: e.target.value }))}
          autoFocus
        />
        <UserAutocomplete
          roles={['SALESPERSON']}
          value={fields.salesperson}
          onChange={(event, value) => setFields(pv => ({ ...pv!, salesperson: value }))}
          textFieldProps={{ label: '行銷人員' }}
        />
        <BasicNumberField
          value={fields.copies}
          label="份數"
          type="number"
          onChange={e => setFields(pv => ({ ...pv, copies: Number(e.target.value) }))}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onClickResult}
        >
          列印
        </Button>
      </DialogActions>
    </Dialog>
  )
}