import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { SelectProps } from '@mui/material/Select'
import OutlinedInput from "@mui/material/OutlinedInput"

export type TaxType = 'TAXABLE' | 'TAX_EXEMPTION' | 'ZERO_TAX'
export interface IProps extends SelectProps<TaxType> {

}

export default function TaxTypeSelect(props: IProps) {

  const shrink = props.value !== null // value沒預設值時（null），修復value和label重疊的問題

  return (
    <FormControl
      fullWidth
      margin="normal"
      variant="outlined"
    >
      <InputLabel shrink={shrink}>稅別</InputLabel>
      <Select
        label="稅別"
        input={<OutlinedInput label="稅別" notched={shrink} />}
        {...props}
      >
        <MenuItem value={'TAXABLE'}>應稅</MenuItem>
        <MenuItem value={'TAX_EXEMPTION'}>免稅</MenuItem>
        <MenuItem value={'ZERO_TAX'}>零稅率</MenuItem>
      </Select>
    </FormControl>
  )
}