import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { InventoryAdjustment } from '../../parse-class/InventoryAdjustment'

interface IFields {
  group: string | null
}

interface IOptions {
  group: string
}

interface IParams extends DialogProps {
  onUpdate: (options: IOptions) => void
}

export default function OptionsDialog(props: IParams) {

  const { onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [defalutGroups, setDefalutGroups] = React.useState<string[]>([])
  // state
  const [fields, setFields] = React.useState<IFields>({
    group: null
  })

  const verify = React.useMemo(() => {
    return fields.group && fields.group.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(InventoryAdjustment)
      query.limit(100000)
      query.select('group')
      const data = await query.find()
      const groups = [...new Set(data.map(d => d.get('group')!))]
      setDefalutGroups(groups)
      setFields({
        group: groups.length > 0 ? groups[0] : null
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
    }
  }, [dialogProps.open, loadData])

  const onSave = React.useCallback(() => {
    if (verify) {
      onUpdate({
        group: fields.group!
      })
    }
  }, [verify, fields, onUpdate])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>選項</DialogTitle>
      <DialogContent>
        <Autocomplete
          freeSolo
          options={defalutGroups}
          value={fields.group}
          onChange={(e, value) => { setFields(pre => ({ ...pre, group: value })) }}
          renderInput={(params) =>
            <TextField
              {...params}
              margin="normal"
              variant="outlined"
              label="名目"
            />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          確定
        </Button>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}