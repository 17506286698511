import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { BackdropContext } from '../context/backdrop'
import { SnackbarContext } from '../context/snackbar'
import { User, TUserRole } from '../parse-class/User'

type AutocompletePropsType = Omit<AutocompleteProps<User, false, false, false>, 'options' | 'renderInput'>
export interface IProps extends AutocompletePropsType {
  roles?: TUserRole[]
  textFieldProps?: TextFieldProps
}

export default function UserAutocomplete(props: IProps) {

  const { roles, textFieldProps, ...autocompleteProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [userList, setUserList] = React.useState<User[] | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(User)
      if (roles) {
        query.containsAll('roles', roles)
      }
      query.limit(10000)
      const data = await query.find()
      setUserList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (userList === null) {
    return null
  }

  return (
    <Autocomplete
      options={userList}
      getOptionLabel={user =>
        user.get('name') || `未知的使用者`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={autocompleteRenderInputParams =>
        <TextField
          {...autocompleteRenderInputParams}
          label="使用者"
          margin="normal"
          variant="outlined"
          {...props.textFieldProps}
        />
      }
      fullWidth
      autoHighlight
      {...autocompleteProps}
    />
  )
}