import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer, GridFilterModel } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import ImageIcon from '@mui/icons-material/Image'
import DeleteIcon from '@mui/icons-material/Delete'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ViewListIcon from '@mui/icons-material/ViewList'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import SyncIcon from '@mui/icons-material/Sync'
import PrintIcon from '@mui/icons-material/Print'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import ItemPricingDialog from './ItemPricingDialog'
import ImageDialog from './ImageDialog'
import SalesRecordDialog from './SalesRecordDialog'
import PurchaseRecordDialog from './PurchaseRecordDialog'
import PrintInventoryDialog from './PrintInventoryDialog'
import InventoryGroupDialog from './InventoryGroupDialog'
import { Item } from '../../parse-class/Item'
import { updateItemCacheStorage } from '../../cloud/report-storage'

export const FIELDS = {
  AVAILABLE: 'AVAILABLE',
  NAME: 'NAME',
  POS: 'POS',
  CACHE_STOCK: 'CACHE_STOCK',
  CACHE_MONTHLY_SALES: 'CACHE_MONTHLY_SALES',
}

export function ItemPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [itemList, setItemList] = React.useState<Item[] | null>(null)
  // state
  const [imageItemId, setImageItemId] = React.useState<string | null>(null)
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [editingPriceItemId, setEditingPriceItemId] = React.useState<string | null>(null)
  const [showStorage, setShowStorage] = React.useState(false)
  const [showPrintInventoryDialog, setShowPrintInventoryDialog] = React.useState(false)
  const [salesReocrdItemId, setSalesReocrdItemId] = React.useState<string | null>(null)
  const [purchaseReocrdItemId, setPurchaseReocrdItemId] = React.useState<string | null>(null)
  const [inventoryGroup, setInventoryGroup] = React.useState<string | null>(null)
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>()

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      showStorage && await updateItemCacheStorage()  // 如果要顯示庫存資料，就先更新後再抓貨物資料
      const query = new Parse.Query(Item)
      query.limit(10000)
      query.descending('createdAt')
      const data = await query.find()
      setItemList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStorage])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const item = Item.createWithoutData(id)
      await item.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  /**
   * 篩選出庫存異常
   * 過濾出：庫存數量為0，但有位置的貨物
   */
  const filterInventoryError = React.useCallback(() => {
    setFilterModel({
      items: [
        {
          id: 1,
          columnField: FIELDS.POS,
          operatorValue: 'isNotEmpty',
        },
        {
          id: 2,
          columnField: FIELDS.CACHE_STOCK,
          operatorValue: '=',
          value: 0
        },
      ],
    })
  }, [])

  /**
   * 顯示同種庫存群組編輯視窗，如果原先沒有群組，就隨機建立一個
   */
  const showInventoryGroupDialog = React.useCallback(async (item: Item) => {
    let inventoryGroup = item.get('inventoryGroup')
    if (!inventoryGroup) {
      inventoryGroup = uuidv4()
      item.set('inventoryGroup', inventoryGroup)
      await item.save()
    }
    setInventoryGroup(inventoryGroup)
  }, [])

  const rows = React.useMemo(() => {
    if (!itemList) {
      return []
    }
    return itemList.map(item => (
      {
        id: item.id,
        [FIELDS.AVAILABLE]: item.get('available'),
        [FIELDS.NAME]: item.getFormatName(),
        [FIELDS.POS]: item.get('pos'),
        [FIELDS.CACHE_STOCK]: item.get('cacheStock'),
        [FIELDS.CACHE_MONTHLY_SALES]: item.get('cacheMonthlySales'),
      }
    ))
  }, [itemList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.AVAILABLE,
        headerName: '可交易',
        type: 'boolean',
        width: 80,
        sortable: false,
      },
      {
        field: FIELDS.NAME,
        headerName: '貨物',
        width: 350,
      },
      {
        hide: !showStorage,
        field: FIELDS.POS,
        headerName: '位置',
        width: 80,
      },
      {
        hide: !showStorage,
        field: FIELDS.CACHE_STOCK,
        headerName: '庫存',
        width: 80,
        type: 'number',
      },
      {
        hide: !showStorage,
        field: FIELDS.CACHE_MONTHLY_SALES,
        headerName: '月銷',
        width: 80,
        type: 'number',
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 110,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
            <GridActionsCellItem
              showInMenu
              icon={<ImageIcon />}
              label="照片"
              onClick={() => { setImageItemId(id as string) }}
            />,
            <GridActionsCellItem
              showInMenu
              icon={<AttachMoneyIcon />}
              label="訂價"
              onClick={() => { setEditingPriceItemId(id as string) }}
            />,
            <GridActionsCellItem
              showInMenu
              icon={<ViewListIcon />}
              label="供應商交易"
              onClick={() => { setPurchaseReocrdItemId(id as string) }}
            />,
            <GridActionsCellItem
              showInMenu
              icon={<ViewListIcon />}
              label="客戶交易"
              onClick={() => { setSalesReocrdItemId(id as string) }}
            />,
            <GridActionsCellItem
              showInMenu
              icon={<WorkspacesIcon />}
              label="同種庫存群組"
              onClick={async () => {
                const item = itemList?.find(item => item.id === id)
                item && showInventoryGroupDialog(item)
              }}
            />,
          ]
        }
      }
    ]
  }, [showStorage, onDelete, itemList, showInventoryGroupDialog])

  if (itemList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="貨物" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                    <Button
                      disabled={showStorage}
                      color="primary"
                      startIcon={<SyncIcon />}
                      onClick={() => { setShowStorage(true) }}
                    >
                      {showStorage ? '已載入' : '載入庫存'}
                    </Button>
                    <Button
                      disabled={!showStorage}
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setShowPrintInventoryDialog(true) }}
                    >
                      盤點單
                    </Button>
                    <Button
                      disabled={!showStorage}
                      color="primary"
                      startIcon={<ErrorOutlineIcon />}
                      onClick={filterInventoryError}
                    >
                      篩選出庫存異常
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            />
          </Box>
        </StandardBox>
      </Container>
      {/*  */}
      <ImageDialog
        open={imageItemId !== null}
        onClose={() => { setImageItemId(null) }}
        fullWidth
        maxWidth="md"
        itemId={imageItemId}
      />
      {/* Add Dialog */}
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        onChange={() => {
          loadData()              // 載入新資料
          setShowAddDialog(false) // 關閉視窗
        }}
      />
      {/* Edit Dailog */}
      <EditDialog
        itemId={editingId}
        onClose={() => { setEditingId(null) }}
        onChange={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
      {/*  */}
      <ItemPricingDialog
        itemId={editingPriceItemId}
        onClose={() => setEditingPriceItemId(null)}
      />
      {/*  */}
      <PurchaseRecordDialog
        open={purchaseReocrdItemId !== null}
        onClose={() => { setPurchaseReocrdItemId(null) }}
        fullWidth
        maxWidth="sm"
        itemId={purchaseReocrdItemId}
      />
      {/*  */}
      <SalesRecordDialog
        open={salesReocrdItemId !== null}
        onClose={() => { setSalesReocrdItemId(null) }}
        fullWidth
        maxWidth="sm"
        itemId={salesReocrdItemId}
      />
      {/*  */}
      <PrintInventoryDialog
        open={showPrintInventoryDialog}
        onClose={() => { setShowPrintInventoryDialog(false) }}
        fullWidth
        maxWidth="sm"
      />
      {/*  */}
      <InventoryGroupDialog
        open={inventoryGroup !== null}
        onClose={() => { setInventoryGroup(null) }}
        fullWidth
        maxWidth="md"
        group={inventoryGroup}
        onChange={() => {
          loadData()               // 載入新資料
          setInventoryGroup(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}