import dayjs from 'dayjs'
import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import BasicTextField from '../../input/BasicTextField'
import BasicNumberField from '../../input/BasicNumberField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import UserAutocomplete from '../../input/UserAutocomplete'
import TransactionDetailGrid from './TransactionDetailGrid'
import RecordDialog from './RecordDialog'
import { Company } from '../../parse-class/Company'
import { Customer } from '../../parse-class/Customer'
import { Item } from '../../parse-class/Item'
import { User } from '../../parse-class/User'
import { TransactionCustomer, ITransactionDetail } from '../../parse-class/TransactionCustomer'

interface IFields {
  date: Date
  target: Customer | null
  note: string
  details: ITransactionDetail[]
  deliveryPerson: User | null
  deliveryTime: dayjs.Dayjs | null
  paymentPerson: User | null
  paymentTime: dayjs.Dayjs | null
  allowance: number
}

interface IProps extends DialogProps {
  editingId: string | null
  onChange: () => void
}

export default function EditDialog(props: IProps) {

  const { editingId, onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data 
  const [company, setCompany] = React.useState<Company | null>(null)
  const [raw, setRaw] = React.useState<TransactionCustomer | null>(null)
  const [items, setItems] = React.useState<Item[]>([])
  // state
  const [fields, setFields] = React.useState<IFields>()
  const [recordTarget, setRecordTarget] = React.useState<{ company: Company, customer: Customer } | null>(null)
  const [tab, setTab] = React.useState(0)

  const verify = React.useMemo(() => {
    return fields
      && fields.target !== null
      && fields.deliveryPerson !== null
      && fields.paymentPerson !== null
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const company = await new Parse.Query(Company).first()
      if (company) {
        setCompany(company)
      } else {
        throw new Error('找不到本公司')
      }
      const data = await new Parse.Query(TransactionCustomer)
        // @ts-ignore
        .include('details.item')
        .get(id)
      setRaw(data)
      setFields({
        date: data.get('date')!,
        target: data.get('customer')!,
        note: data.get('note')!,
        details: data.get('details')!,
        deliveryPerson: data.get('deliveryPerson') || null,
        deliveryTime: data.get('deliveryTime') ? dayjs(data.get('deliveryTime')) : null,
        paymentPerson: data.get('paymentPerson') || null,
        paymentTime: data.get('paymentTime') ? dayjs(data.get('paymentTime')) : null,
        allowance: data.get('allowance')!,
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (editingId !== null) {
      loadData(editingId)
      setTab(0)
    } else {
      setFields(undefined)
    }
  }, [loadData, editingId])

  const loadItemsData = React.useCallback(async () => {
    const data = await new Parse.Query(Item)
      .limit(10000)
      .equalTo('available', true)
      .find()
    setItems(data)
  }, [])

  React.useEffect(() => {
    loadItemsData()
  }, [loadItemsData])

  const onSave = React.useCallback(async () => {
    if (raw && fields && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('date', fields.date)
        raw.set('customer', fields.target!)
        raw.set('details', fields.details)
        raw.set('note', fields.note)
        raw.set('deliveryPerson', fields.deliveryPerson!)
        fields.deliveryTime ? raw.set('deliveryTime', fields.deliveryTime.toDate()) : raw.unset('deliveryTime')
        raw.set('paymentPerson', fields.paymentPerson!)
        fields.paymentTime ? raw.set('paymentTime', fields.paymentTime.toDate()) : raw.unset('paymentTime')
        raw.set('allowance', fields.allowance)
        await raw.save()
        snackbar.show('更新成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, raw, fields, onChange])

  /**
   * 打開歷史紀錄視窗
   */
  const showRecordDialog = React.useCallback(() => {
    if (fields?.target) {
      setRecordTarget({
        company: company!,
        customer: fields.target
      })
    }
  }, [fields, company])

  /**
   * 設定熱鍵：按下F2時開關價格記錄視窗
   */
  React.useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (e.key === 'F2' && fields && fields.target !== null && recordTarget === null) {
        showRecordDialog()
      }
    }
    // 註冊事件，並在關閉視窗時移除熱鍵
    if (editingId !== null) {
      window.addEventListener('keydown', cb)
      return () => window.removeEventListener('keydown', cb)
    }
  }, [editingId, fields, recordTarget, showRecordDialog])

  /**
   * 計算交易金額
   */
  const amount = React.useMemo(() => {
    if (fields) {
      const sum = fields.details.reduce((pre, cur) => pre + Math.round(cur.qty * cur.price), 0)
      return sum - fields.allowance
    } else {
      return 0
    }
  }, [fields])

  if (editingId === null || raw === null || fields === undefined) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <Tabs value={tab} onChange={(e, value) => { setTab(value) }}>
          <Tab label="客戶交易" />
          <Tab label="物流金流" />
        </Tabs>
        {tab === 0 &&
          <DialogContent>
            <DateField
              defaultValue={fields.date}
              onDateChange={(event, value) => {
                setFields(pv => ({ ...pv!, date: value }))
              }}
            />
            <CustomerAutocomplete
              value={fields.target}
              onChange={(event, value) => setFields(pv => ({ ...pv!, target: value }))}
              textFieldProps={{ autoFocus: true }}
            />
            <BasicTextField
              label="備註"
              defaultValue={fields.note}
              onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
              multiline
              rows={3}
            />
            <TransactionDetailGrid
              items={items}
              defaultValue={fields.details}
              onUpdate={rows => setFields(pv => ({ ...pv!, details: rows }))}
              autoApplyPricing="PRICE"
            />
          </DialogContent>
        }
        {tab === 1 &&
          <DialogContent>
            <UserAutocomplete
              roles={['LOGISTICIAN']}
              value={fields.deliveryPerson}
              onChange={(event, value) => setFields(pv => ({ ...pv!, deliveryPerson: value }))}
              textFieldProps={{ label: '物流人員' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={props => <BasicTextField {...props} />}
                label="物流完成時戳"
                inputFormat="YYYY-MM-DD HH:mm"
                value={fields.deliveryTime}
                onChange={value => { setFields(pv => ({ ...pv!, deliveryTime: value })) }}
              />
            </LocalizationProvider>
            <UserAutocomplete
              roles={['LOGISTICIAN']}
              value={fields.paymentPerson}
              onChange={(event, value) => setFields(pv => ({ ...pv!, paymentPerson: value }))}
              textFieldProps={{ label: '金流人員' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={props => <BasicTextField {...props} />}
                label="金流完成時戳"
                inputFormat="YYYY-MM-DD HH:mm"
                value={fields.paymentTime}
                onChange={value => { setFields(pv => ({ ...pv!, paymentTime: value })) }}
              />
            </LocalizationProvider>
            <BasicNumberField
              label="折讓"
              defaultValue={fields.allowance}
              onChange={e => setFields(pv => ({ ...pv!, allowance: Number(e.target.value) }))}
            />
            <BasicNumberField
              disabled
              label="總額"
              value={amount}
            />
          </DialogContent>
        }
        <DialogActions>
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            儲存
          </Button>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
          {tab === 0 &&
            <Button
              disabled={fields.target === null}
              color="inherit"
              onClick={showRecordDialog}
            >
              歷史
            </Button>
          }
          {tab === 1 &&
            <React.Fragment>
              <Button
                disabled={fields.deliveryTime !== null}
                color="success"
                onClick={() => { setFields(pv => ({ ...pv!, deliveryTime: dayjs() })) }}
              >
                物流完成
              </Button>
              <Button
                disabled={fields.paymentTime !== null}
                color="success"
                onClick={() => { setFields(pv => ({ ...pv!, paymentTime: dayjs() })) }}
              >
                金流完成
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
      <RecordDialog
        fullWidth
        maxWidth='sm'
        open={recordTarget !== null}
        target={recordTarget}
        onClose={() => setRecordTarget(null)}
      />
    </React.Fragment>
  )
}