/**
 * 交易記錄
 */
import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { DataGridPremium, GridColumns, GridSortModel } from '@mui/x-data-grid-premium'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'
import { Company } from '../../parse-class/Company'
import { Customer } from '../../parse-class/Customer'
import { Item } from '../../parse-class/Item'

export interface ITransactionRecord {
  date: Date
  item: Item
  qty: number
  price: number
}

const FIELDS = {
  DATE: 'DATE',
  ITEM: 'ITEM',
  QTY: 'QTY',
  PRICE: 'PRICE',
}

interface IProps extends DialogProps {
  target: { company: Company, customer: Customer } | null
}

export default function RecordDialog(props: IProps) {

  const { target, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [transactionList, setTransactionList] = React.useState<TransactionCustomer[] | null>(null)
  // state
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc', }, // 按照日期降序排列
  ])

  const loadData = React.useCallback(async (company: Company, customer: Customer) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(TransactionCustomer)
        .equalTo('company', company)
        .equalTo('customer', customer)
        .limit(1000)
        .descending('date')
        // @ts-ignore
        .include('details.item')
        .find()
      setTransactionList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (target) {
      loadData(target.company, target.customer)
    }
  }, [target, loadData])

  const rows = React.useMemo(() => {
    if (!transactionList || !target) {
      return []
    }
    const records: ITransactionRecord[] = []
    for (const transaction of transactionList) {
      for (const detail of transaction.get('details')!) {
        records.push({
          date: transaction.get('date')!,
          item: detail.item,
          qty: detail.qty,
          price: detail.price,
        })
      }
    }
    return records.map((record, index) => {
      return {
        id: index,
        [FIELDS.DATE]: record.date,
        [FIELDS.ITEM]: record.item,
        [FIELDS.QTY]: record.qty,
        [FIELDS.PRICE]: record.price,
      }
    })
  }, [transactionList, target])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 100,
        disableColumnMenu: true,
        valueFormatter: params => dayjs(params.value).format('YYYY-MM-DD')
      },
      {
        field: FIELDS.ITEM,
        headerName: '貨物',
        width: 270,
        valueGetter: params => {
          const item = params.value as Item
          return item.getFormatName()
        }
      },
      {
        field: FIELDS.QTY,
        headerName: '數量',
        type: 'number',
        width: 75,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: FIELDS.PRICE,
        headerName: '價格',
        type: 'number',
        width: 75,
        sortable: false,
        disableColumnMenu: true,
      },
    ]
  }, [])

  if (transactionList === null) {
    return null
  }

  return (
    <Dialog {...dialogProps}>
      <DialogContent>
        <DataGridPremium
          rows={rows}
          columns={columns}
          density="compact"
          autoHeight
          disableSelectionOnClick
          // sort
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
        />
      </DialogContent>
    </Dialog>
  )
}