import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { BackdropContext } from '../context/backdrop'
import { SnackbarContext } from '../context/snackbar'
import { Provider } from '../parse-class/Provider'

type AutocompletePropsType = Omit<AutocompleteProps<Provider, false, false, false>, 'options' | 'renderInput'>
export interface IProps extends AutocompletePropsType {
  data?: Provider[]            // 指定資料集
  textFieldProps?: TextFieldProps
}

export default function ProviderAutocomplete(props: IProps) {

  const { data, textFieldProps, ...autocompleteProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [providerList, setProviderList] = React.useState<Provider[] | null>(null)

  const loadData = React.useCallback(async () => {
    if (data) {
      return setProviderList(data)
    }
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(Provider)
        .limit(10000)
        .find()
      setProviderList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (providerList === null) {
    return null;
  }

  return (
    <Autocomplete
      options={providerList}
      getOptionLabel={provider =>
        provider.get('name') || `未知的供應商`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={autocompleteRenderInputParams =>
        <TextField
          {...autocompleteRenderInputParams}
          label="供應商"
          margin="normal"
          variant="outlined"
          {...props.textFieldProps}
        />
      }
      fullWidth
      autoHighlight
      {...autocompleteProps}
    />
  )
}