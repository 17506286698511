import { Customer } from '../Customer'
import { Item } from '../Item'

export interface IQuotationDetail {
  item: Item
  price: number
}

export interface IAttributes {
  date: Date
  customer: Customer
  note: string
  details: IQuotationDetail[]
}

export const CLASS_NAME = 'SalesQuotation'

export class SalesQuotation extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, SalesQuotation)