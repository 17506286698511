import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { BackdropContext } from '../context/backdrop'
import { SnackbarContext } from '../context/snackbar'
import { PosPrinter } from '../parse-class/PosPrinter'

type AutocompletePropsType = Omit<AutocompleteProps<PosPrinter, false, false, false>, 'options' | 'renderInput'>
export interface IProps extends AutocompletePropsType {
  textFieldProps?: TextFieldProps
}

export default function PosPrinterAutocomplete(props: IProps) {

  const { textFieldProps, ...autocompleteProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [printerList, setPrinterList] = React.useState<PosPrinter[] | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(PosPrinter)
      query.limit(10000)
      const data = await query.find()
      setPrinterList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (printerList === null) {
    return null
  }

  return (
    <Autocomplete
      options={printerList}
      getOptionLabel={printer =>
        printer.get('name') || `未知的出單機`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={autocompleteRenderInputParams =>
        <TextField
          {...autocompleteRenderInputParams}
          label="出單機"
          margin="normal"
          variant="outlined"
          {...props.textFieldProps}
        />
      }
      fullWidth
      autoHighlight
      {...autocompleteProps}
    />
  )
}