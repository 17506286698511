/**
 * 點貨選項對話框
 */
import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DateField from '../../input/DateField'
import UserAutocomplete from '../../input/UserAutocomplete'
import { User } from '../../parse-class/User'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export interface IOrderOptions {
  date: Date
  deliveryPerson: User
}

interface IFields {
  date: Date
  deliveryPerson: User | null
}

interface Props extends DialogProps {
  onResult: (result: IOrderOptions) => void
}

export default function OrderOptionsDialog(props: Props) {

  const { onResult, ...dialogProps } = props
  // state
  const [fields, setFields] = React.useState<IFields>({
    date: dayjs().utc(true).startOf('day').toDate(),
    deliveryPerson: null,
  })

  const verify = React.useMemo(() => {
    return fields.deliveryPerson !== null
  }, [fields])

  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        date: dayjs().utc(true).startOf('day').toDate(),
        deliveryPerson: null,
      })
    }
  }, [dialogProps.open])

  const onClickResult = React.useCallback(async () => {
    onResult({
      date: fields.date,
      deliveryPerson: fields.deliveryPerson!,
    })
  }, [fields, onResult])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>點貨選項</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, date: value }))
          }}
        />
        <UserAutocomplete
          roles={['LOGISTICIAN']}
          value={fields.deliveryPerson}
          onChange={(event, value) => setFields(pv => ({ ...pv!, deliveryPerson: value }))}
          textFieldProps={{ label: '物流人員' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onClickResult}
        >
          確定
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}