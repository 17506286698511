import React from 'react';
import MaterialSnackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

/**
 * Context
 */
interface IContext {
  show: (msg: string, color?: AlertColor) => void
}
export const SnackbarContext = React.createContext<IContext>({
  show: () => { }
});

/**
 * Provider
 */
export function SnackbarProvider(props: React.PropsWithChildren) {

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [color, setColor] = React.useState<AlertColor>('success');

  const show = React.useCallback((msg: string, color?: AlertColor) => {
    setOpen(true)
    setMsg(msg)
    setColor(color || 'success')
  }, [])

  return (
    <SnackbarContext.Provider value={{ show }}>
      {/* Children */}
      {props.children}
      {/* SnackBar */}
      <MaterialSnackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => { setOpen(false) }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert onClose={() => { setOpen(false) }} variant="filled" elevation={6} severity={color} >
          {msg}
        </MuiAlert >
      </MaterialSnackbar>
    </SnackbarContext.Provider>
  )
}