import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import DateField from '../../input/DateField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import QuotationDetailGrid from './QuotationDetailGrid'
import { Customer } from '../../parse-class/Customer'
import { SalesQuotation, IQuotationDetail } from '../../parse-class/SalesQuotation'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  customer: Customer | null
  note: string
  details: IQuotationDetail[]
}

interface IProps extends DialogProps {
  editingId: string | null
  onUpdate: () => void
}

export default function EditDialog(props: IProps) {

  const { editingId, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [raw, setRaw] = React.useState<SalesQuotation | null>(null)
  // state
  const [fields, setFields] = React.useState<IFields>()

  const verify = React.useMemo(() => {
    return fields
      && fields.details.length > 0
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(SalesQuotation)
        // @ts-ignore
        .include('details.item')
        .get(id)
      setRaw(data)
      setFields({
        date: data.get('date') || dayjs().utc(true).startOf('day').toDate(),
        customer: data.get('customer') || null,
        note: data.get('note') || '',
        details: data.get('details') || [],
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && editingId !== null) {
      loadData(editingId)
    } else {
      setFields(undefined)
    }
  }, [dialogProps.open, editingId, loadData])

  const onSave = React.useCallback(async () => {
    if (raw && fields && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('date', fields.date)
        raw.set('customer', fields.customer || undefined)
        raw.set('note', fields.note)
        raw.set('details', fields.details)
        await raw.save()
        snackbar.show('更新成功')
        onUpdate() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, raw, fields, onUpdate])

  if (editingId === null || raw === null || fields === undefined) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>編輯報價單</DialogTitle>
        <DialogContent>
          {/* 日期 */}
          <DateField
            value={fields.date}
            onDateChange={(event, value) => {
              setFields(pv => ({ ...pv!, date: value }))
            }}
          />
          {/* 客戶 */}
          <CustomerAutocomplete
            value={fields.customer}
            onChange={(event, value) => setFields(pv => ({ ...pv!, customer: value }))}
            textFieldProps={{
              autoFocus: true,
              placeholder: fields.customer ? "" : "留白不指定客戶"
            }}
          />
          {/* 備註 */}
          <BasicTextField
            label="備註"
            value={fields.note}
            onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
            multiline
            rows={3}
          />
          {/* 明細 */}
          <QuotationDetailGrid
            defaultValue={fields.details}
            onUpdate={details => setFields(pv => ({ ...pv!, details }))}
            autoApplyPricing="PRICE"
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!verify}
            color="primary"
            onClick={onSave}
          >
            儲存
          </Button>
          <Button
            color="error"
            onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
          >
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}