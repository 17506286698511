import React from 'react'
import { DataGridPro, GridColumns, useGridApiRef, GridRowsProp, GridRowId, GridEvents, GridToolbarContainer } from '@mui/x-data-grid-pro'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

export const FIELDS = {
  NAME: 'NAME',
  QTY: 'QTY',
  PRICE: 'PRICE',
}

export interface IDetailRow {
  name: string
  qty: number
  price: number
}

export interface IProps {
  defaultValue?: IDetailRow[]
  onUpdate?: (rows: IDetailRow[]) => void
}

export default function DetailGrid(props: IProps) {

  // props
  const { defaultValue, onUpdate } = props
  // hooks
  const apiRef = useGridApiRef()
  // data
  const [rows, setRows] = React.useState<GridRowsProp>([])

  /**
   * 觸發回呼，傳回表格資料
   * （所有變動都需要執行這個方法）
   */
  const handleUpdate = React.useCallback(() => {
    if (onUpdate) {
      const rows = Array.from(apiRef.current.getRowModels().values())
      onUpdate(rows.map(row => ({
        name: row[FIELDS.NAME],
        qty: row[FIELDS.QTY],
        price: row[FIELDS.PRICE],
      })))
    }
  }, [apiRef, onUpdate])

  /**
   * 在最後插入一列
   */
  const insertRow = React.useCallback(() => {
    // 產生唯一ID
    const allIds = apiRef.current.getAllRowIds()
    const maxId = allIds.length > 0 ? Math.max(...allIds.map(id => Number(id))) : 0
    const newId = maxId + 1
    // 預設值
    let defaultName = ''
    let defaultQty = 0
    let defaultPrice = 0
    // 調用API加入一列
    apiRef.current.updateRows([{
      id: newId,
      [FIELDS.NAME]: defaultName,
      [FIELDS.QTY]: defaultQty,
      [FIELDS.PRICE]: defaultPrice,
    }])
    // 將焦點放在該列第一欄
    apiRef.current.setCellMode(newId, FIELDS.NAME, 'edit')
    // 觸發回呼事件
    handleUpdate()
  }, [apiRef, handleUpdate])

  /**
   * 刪除指定列
   */
  const deleteRow = React.useCallback((rowId: GridRowId, field: string) => {
    // 切換焦點：如果存在不只一列，就進行切換。如果不是在第一列，就向上切換，否則向下切換。
    const ids = apiRef.current.getAllRowIds()
    if (ids.length > 1) {
      const index = ids.findIndex(id => id === rowId)
      if (index === 0) {
        apiRef.current.setCellFocus(ids[1], field)
      } else {
        apiRef.current.setCellFocus(ids[index - 1], field)
      }
    } else {
      // 如果刪除後就沒有資料了，移動到表頭
      apiRef.current.setColumnHeaderFocus(field)
    }
    // 調用API刪除指定列
    apiRef.current.updateRows([{ id: rowId, _action: 'delete' }])
    // 觸發回呼事件
    handleUpdate()
  }, [apiRef, handleUpdate])

  /**
   * 在表頭中的鍵盤事件
   */
  React.useEffect(() => {
    return apiRef.current.subscribeEvent(GridEvents.columnHeaderKeyDown, (params, event) => {
      switch (event.key) {
        /**
         * 按下「⇩」：如沒有任何資料，就新增一列
         */
        case 'ArrowDown':
          // 確認沒有任何資料
          if (apiRef.current.getRowsCount() === 0) {
            insertRow()
          }
          break
      }
    })
  }, [apiRef, insertRow])

  /**
   * 在儲存格中的鍵盤事件
   */
  React.useEffect(() => {
    return apiRef.current.subscribeEvent(GridEvents.cellKeyDown, (params, event) => {
      const { id, cellMode, field, value } = params
      // console.log(cellMode, event.key)
      switch (event.key) {
        /**
         * 按下「⇩」：如果是檢視模式，且是在最後一列，則新增一列。
         */
        case 'ArrowDown':
          if (cellMode === 'view') {
            // 確認目前是在最後一列
            const lastId = [...apiRef.current.getAllRowIds()].pop()
            if (id === lastId) {
              insertRow()
            }
          }
          break
        /**
          * 按下「Delete」：如果是檢視模式，則刪除整列。
          */
        case 'Delete':
          if (cellMode === 'view') {
            // 避免原先Delete清除Cell的動作
            event.stopPropagation()
            // 刪除列
            deleteRow(id, field)
          }
          break
        /**
          * 按下「Enter」：如果是編輯模式，將原本往下一列的功能，改成往下一欄
          */
        case 'Enter':
          if (cellMode === 'edit') {
            // 將格子轉回檢視模式
            apiRef.current.setCellMode(id, field, 'view')
            // 往下一欄
            const columns = apiRef.current.getAllColumns()
            const curIndex = apiRef.current.getColumnIndex(field)
            if (curIndex < columns.length - 2) { // 最後一欄金額不能編輯，所以-2
              const field = columns[curIndex + 1].field
              apiRef.current.setCellFocus(id, field)
            }
            // 避免原先往下一列的動作
            event.stopPropagation()
          }
          break
        /**
        * 按下「F2」：如果在數量或單價欄位，進行一次含稅轉未稅轉換
        */
        case 'F2':
          if (field === FIELDS.PRICE || field === FIELDS.QTY) {
            let newValue = value - Math.round(value / 1.05 * 0.05)
            apiRef.current.setCellMode(id, field, 'edit')
            apiRef.current.setEditCellValue({ id, field, value: newValue })
            apiRef.current.commitCellChange({ id, field })
            apiRef.current.setCellMode(id, field, 'view')
          }
          break
        /**
        * 按下「F4」：如果在數量或單價欄位，將其轉化為總金額（反算自身值）
        */
        case 'F4':
          if (field === FIELDS.QTY) {
            const price = Number(params.row[FIELDS.PRICE])
            if (price > 0) {
              const sum = Math.round(value)
              const qty = Math.round((sum / price) * 100) / 100
              apiRef.current.setCellMode(id, FIELDS.QTY, 'edit')
              apiRef.current.setEditCellValue({ id, field: FIELDS.QTY, value: qty })
              apiRef.current.commitCellChange({ id, field: FIELDS.QTY })
              apiRef.current.setCellMode(id, FIELDS.QTY, 'view')
            }
          } else if (field === FIELDS.PRICE) {
            const qty = Number(params.row[FIELDS.QTY])
            if (qty > 0) {
              const sum = Math.round(value)
              const price = Math.round((sum / qty) * 100) / 100
              apiRef.current.setCellMode(id, FIELDS.PRICE, 'edit')
              apiRef.current.setEditCellValue({ id, field: FIELDS.PRICE, value: price })
              apiRef.current.commitCellChange({ id, field: FIELDS.PRICE })
              apiRef.current.setCellMode(id, FIELDS.PRICE, 'view')
            }
          }
          break
      }
    })
  }, [apiRef, deleteRow, insertRow])

  /**
   * 格子內容變更：觸發回呼事件
   */
  React.useEffect(() => {
    return apiRef.current.subscribeEvent(GridEvents.cellEditCommit, (params, event) => {
      handleUpdate()
    })
  }, [apiRef, handleUpdate])

  /**
   * 將初始資料格式化成表格資料
   */
  React.useEffect(() => {
    if (defaultValue) {
      // 格式化資料
      setRows(defaultValue.map((row, index) => ({
        id: index,
        [FIELDS.NAME]: row.name,
        [FIELDS.QTY]: row.qty,
        [FIELDS.PRICE]: row.price,
      })))
    }
  }, [defaultValue])

  /**
   * 定義表格欄位
   */
  const columns: GridColumns = [
    {
      field: FIELDS.NAME,
      headerName: '品名',
      width: 240,
      editable: true,
      sortable: false,
    },
    {
      field: FIELDS.QTY,
      headerName: '數量',
      width: 100,
      type: 'number',
      editable: true,
      sortable: false,
    },
    {
      field: FIELDS.PRICE,
      headerName: '單價',
      width: 100,
      type: 'number',
      editable: true,
      sortable: false,
    },
    {
      field: 'SUM',
      headerName: '金額',
      width: 100,
      type: 'number',
      editable: false,
      sortable: false,
      valueGetter: params => Math.round(params.row[FIELDS.PRICE] * params.row[FIELDS.QTY]),
    }
  ]

  return (
    <Box mt={2}>
      <DataGridPro
        sx={{ minHeight: 230 }}
        apiRef={apiRef}
        autoHeight
        density="compact"
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        disableColumnReorder
        disableColumnMenu
        hideFooter
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <Tooltip title="將總金額轉成未稅金額">
                <Button color="primary">
                  轉未稅F2
                </Button>
              </Tooltip>
              <Tooltip title="將目標的總金額填入數量或單位欄位，按下按鈕後會自動計算">
                <Button color="primary">
                  轉金額F4
                </Button>
              </Tooltip>
            </GridToolbarContainer>
          ),
        }}
      />
    </Box>
  )
}