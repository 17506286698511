import React from 'react'
import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import DateField from '../../input/DateField'
import BasicTextField from '../../input/BasicTextField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import ItemAutocomplete from '../../input/ItemAutocomplete'
import { Customer } from '../../parse-class/Customer'
import { Item } from '../../parse-class/Item'
import { MarketingSample } from '../../parse-class/MarketingSample'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

interface IFields {
  date: Date
  customer: Customer | null
  item: Item | null
  note: string
}

interface IParams extends DialogProps {
  editingId: string | null
  onChange: () => void
}

export default function EditDialog(props: IParams) {

  const { editingId, onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [raw, setRaw] = React.useState<MarketingSample | null>(null)
  const [fields, setFields] = React.useState<IFields>()

  const verify = React.useMemo(() => {
    return fields
      && fields.customer !== null
      && fields.item !== null
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(MarketingSample).get(id)
      setRaw(data)
      setFields({
        date: data.get('date') || dayjs().utc(true).startOf('day').toDate(),
        customer: data.get('customer') || null,
        item: data.get('item') || null,
        note: data.get('note') || '',
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (editingId !== null) {
      loadData(editingId)
    } else {
      setFields(undefined)
    }
  }, [loadData, editingId])

  const onSave = React.useCallback(async () => {
    if (raw && fields && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('date', fields.date)
        raw.set('customer', fields.customer!)
        raw.set('item', fields.item!)
        raw.set('note', fields.note)
        await raw.save()
        snackbar.show('更新成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, raw, fields, onChange])

  if (fields === undefined) {
    return null
  }

  return (
    <Dialog {...dialogProps} >
      <DialogTitle>編輯樣品</DialogTitle>
      <DialogContent>
        <DateField
          value={fields.date}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, date: value }))
          }}
        />
        <CustomerAutocomplete
          value={fields.customer}
          onChange={(event, value) => setFields(pv => ({ ...pv!, customer: value }))}
        />
        <ItemAutocomplete
          value={fields.item}
          onChange={(event, value) => setFields(pv => ({ ...pv!, item: value }))}
        />
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}