import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer, GridSortModel } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { useCheckMobile } from '../../context/user-check-mobile'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import { MarketingNote } from '../../parse-class/MarketingNote'

export const FIELDS = {
  DATE: 'DATE',
  SALESPERSON: 'SALESPERSON',
  CUSTOMER: 'CUSTOMER',
  TOPIC: 'TOPIC',
  RESULT: 'RESULT',
}

export function MarketingNotePage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  const isMobile = useCheckMobile()
  // data
  const [marketingNoteList, setMarketingNoteList] = React.useState<MarketingNote[] | null>(null)
  // state
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc' } // 按照日期降序排列
  ])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(MarketingNote)
      query.limit(10000)
      query.include('customer')
      query.include('salesperson')
      query.descending('createdAt')
      const data = await query.find()
      setMarketingNoteList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const marketingNote = MarketingNote.createWithoutData(id)
      await marketingNote.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  /**
   * 將資料轉為Grid格式
   */
  const rows = React.useMemo(() => {
    if (!marketingNoteList) {
      return []
    }
    return marketingNoteList.map(marketingNote => (
      {
        id: marketingNote.id,
        [FIELDS.DATE]: marketingNote.get('date'),
        [FIELDS.SALESPERSON]: marketingNote.get('salesperson'),
        [FIELDS.CUSTOMER]: marketingNote.get('customer'),
        [FIELDS.TOPIC]: marketingNote.get('topic'),
        [FIELDS.RESULT]: marketingNote.get('result'),
      }
    ))
  }, [marketingNoteList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
      },
      {
        field: FIELDS.SALESPERSON,
        headerName: '行銷人員',
        width: 130,
        valueGetter: params => params.row[FIELDS.SALESPERSON].get('name') || '未知的行銷人員',
      },
      {
        field: FIELDS.CUSTOMER,
        headerName: '客戶',
        width: 180,
        valueGetter: params => params.row[FIELDS.CUSTOMER].get('name') || '未知的客戶',
      },
      {
        field: FIELDS.TOPIC,
        headerName: '主題',
        width: 200,
      },
      {
        field: FIELDS.RESULT,
        headerName: '結果',
        width: 350,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 80,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      }
    ]
  }, [onDelete])

  if (marketingNoteList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="行銷行動" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setShowAddDialog(true)}
                    >
                      新增
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        onChange={() => {
          // 載入新資料
          loadData()
          // 關閉視窗
          setShowAddDialog(false)
        }}
      />
      <EditDialog
        open={editingId !== null}
        onClose={() => { setEditingId(null) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        editingId={editingId}
        onChange={() => {
          // 載入新資料
          loadData()
          // 關閉視窗
          setEditingId(null)
        }}
      />
    </React.Fragment>
  )
}