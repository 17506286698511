import React from 'react'
import Container from '@mui/material/Container'
import { DataGridPremium, GridColumns, GridActionsCellItem, GridSortModel } from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { RecordDialog, IRecordData } from './RecordDialog'
import { ZItem } from '../../parse-class/ZItem'
import { ZPurchase } from '../../parse-class/ZPurchase'
import { ZSales } from '../../parse-class/ZSales'

enum FIELDS {
  ITEM = 'ITEM',
  NAME = 'NAME',
  BASE = 'BASE',
  PURCHASE_QTY = 'PURCHASE_QTY',
  SALES_QTY = 'SALES_QTY',
  REVENUE = 'REVENUE',
}

export function ZItemPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [itemData, setItemData] = React.useState<ZItem[] | null>(null)
  // state
  const [recordData, setRecordData] = React.useState<IRecordData[] | null>(null)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.REVENUE, sort: 'desc', }, // 按照營業額降序排列
  ])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(ZItem)
        .limit(9999999)
        .find()
      setItemData(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const showPurchaseRecord = React.useCallback(async (item: ZItem) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(ZPurchase)
        // @ts-ignore
        .equalTo('details.item', item)
        // @ts-ignore
        .include('details.item')
        .include('company')
        .descending('date')
        .limit(500)
        .find()
      const result: IRecordData[] = []
      for (const purchase of data) {
        for (const detail of purchase.get('details')!) {
          if (detail.item.id !== item.id) continue
          result.push({
            date: purchase.get('date')!,
            company: purchase.get('company')!.get('shortName')!,
            qty: detail.qty,
            price: detail.cost,
          })
        }
      }
      setRecordData(result)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSalesRecord = React.useCallback(async (item: ZItem) => {
    try {
      backdrop.show('讀取資料中')
      const data = await new Parse.Query(ZSales)
        // @ts-ignore
        .equalTo('details.item', item)
        // @ts-ignore
        .include('details.item')
        .include('company')
        .descending('date')
        .limit(500)
        .find()
      const result: IRecordData[] = []
      for (const sales of data) {
        for (const detail of sales.get('details')!) {
          if (detail.item.id !== item.id) continue
          result.push({
            date: sales.get('date')!,
            company: sales.get('company')!.get('shortName')!,
            qty: detail.qty,
            price: detail.price,
          })
        }
      }
      setRecordData(result)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = React.useMemo(() => {
    if (itemData === null) {
      return []
    }
    return itemData.map(item => ({
      id: item.id,
      [FIELDS.ITEM]: item,
      [FIELDS.NAME]: item.get('shortName'),
      [FIELDS.BASE]: item.get('base'),
      [FIELDS.PURCHASE_QTY]: item.get('cache')?.purchaseQty,
      [FIELDS.SALES_QTY]: item.get('cache')?.salesQty,
      [FIELDS.REVENUE]: item.get('cache')?.revenue,
    }))
  }, [itemData])

  const columns: GridColumns = React.useMemo(() => [
    { field: FIELDS.NAME, headerName: '品項', width: 300 },
    { field: FIELDS.BASE, headerName: '訂價', width: 150, type: 'number' },
    { field: FIELDS.PURCHASE_QTY, headerName: '年進量', width: 150, type: 'number' },
    { field: FIELDS.SALES_QTY, headerName: '年銷量', width: 150, type: 'number' },
    { field: FIELDS.REVENUE, headerName: '年銷額', width: 150, type: 'number' },
    {
      field: 'purchase',
      headerName: '採購',
      type: 'actions',
      width: 70,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="採購"
            color="primary"
            onClick={() => { showPurchaseRecord(row[FIELDS.ITEM]) }}
          />,
        ]
      }
    },
    {
      field: 'sales',
      headerName: '銷售',
      type: 'actions',
      width: 70,
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="銷售"
            color="error"
            onClick={() => { showSalesRecord(row[FIELDS.ITEM]) }}
          />,
        ]
      }
    }
  ], [showPurchaseRecord, showSalesRecord])

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 3 }}>
        <StandardBox>
          <StandardBoxTitle title="貨物分析" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              disableSelectionOnClick
              pagination
              autoPageSize
              loading={itemData === null}
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
            />
          </Box>
        </StandardBox>
      </Container>
      <RecordDialog
        open={recordData !== null}
        onClose={() => { setRecordData(null) }}
        maxWidth="sm"
        fullWidth
        data={recordData}
      />
    </React.Fragment>
  )
}

