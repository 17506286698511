import React from 'react'
import dayjs from 'dayjs'
import { DataGridPremium, GridColumns, GridRowsProp, GridRowModel, GridRowId, GridActionsCellItem, GridToolbarContainer, GridFooterContainer, GridRowModesModel, GridSortModel, GridRowModes } from '@mui/x-data-grid-premium'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { Item } from '../../parse-class/Item'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const TEMP_NEW_ROW_ID = 'NEW'

const FIELDS = {
  DATE: 'DATE',
  COST: 'COST',
  PRICE: 'PRICE',
  NOTE: 'NOTE',
  DEFAULT: 'DEFAULT',
}

interface Props {
  itemId: string | null
  onClose: () => void
}

let idCounter = 0

export default function ItemPricingDialog(props: Props) {

  const { itemId, onClose } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [item, setItem] = React.useState<Item | null>(null)
  // state
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [editingId, setEditingId] = React.useState<GridRowId | null>(null)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const item = await new Parse.Query(Item).get(id)
      setItem(item)
      setRows(item.get('pricingData')?.map(pricing => (
        {
          id: idCounter++,
          [FIELDS.DATE]: pricing.date,
          [FIELDS.COST]: pricing.cost,
          [FIELDS.PRICE]: pricing.price,
          [FIELDS.NOTE]: pricing.note,
          [FIELDS.DEFAULT]: pricing.isDefault,
        }
      )) || [])
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (itemId) {
      loadData(itemId)
      setRowModesModel({})
      setSelectionModel([])
      setEditingId(null)
      setSortModel([{ field: FIELDS.DATE, sort: 'desc' }]) // 按照日期降序排列
    }
  }, [itemId, loadData])

  const onSave = React.useCallback(async () => {
    if (item) {
      try {
        backdrop.show('更新資料中')
        item.set('pricingData', rows.map(row => ({
          date: row[FIELDS.DATE],
          cost: row[FIELDS.COST],
          price: row[FIELDS.PRICE],
          note: row[FIELDS.NOTE],
          isDefault: row[FIELDS.DEFAULT],
        })))
        await item.save()
        snackbar.show('更新成功')
        onClose()
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, rows])

  const handleAddClick = React.useCallback(() => {
    // 新增暫存列並指定預設值
    setRows(oldRows => [...oldRows, {
      id: TEMP_NEW_ROW_ID,
      [FIELDS.DATE]: dayjs().startOf('day').toDate(), // 這邊用local的時間就好，setter會轉回UTC時間
      [FIELDS.COST]: 0,
      [FIELDS.PRICE]: 0,
      [FIELDS.NOTE]: '',
      [FIELDS.DEFAULT]: false,
    }])
    // 進入編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [TEMP_NEW_ROW_ID]: { mode: GridRowModes.Edit, fieldToFocus: FIELDS.DATE },
    }))
    // 設置編輯ID
    setEditingId(TEMP_NEW_ROW_ID)
  }, [])

  const handleDeleteClick = React.useCallback(async (id: GridRowId) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    // 刪除列資料
    setRows(oldRows => oldRows.filter(row => row.id !== id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditClick = React.useCallback((id: GridRowId) => {
    // 進入編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: FIELDS.DATE },
    }))
    // 設置編輯ID
    setEditingId(id)
  }, [])

  const handleDefaultClick = React.useCallback(async (id: GridRowId) => {
    // 僅將自己設為預設
    setRows(rows => rows.map(row => ({
      ...row,
      [FIELDS.DEFAULT]: row.id === id
    })))
  }, [])

  const handleEditSaveClick = React.useCallback(() => {
    // 退出編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [editingId!]: { mode: GridRowModes.View },
    }))
    // 清除編輯ID
    setEditingId(null)
  }, [editingId])

  const handleEditCancelClick = React.useCallback(() => {
    // 退出編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [editingId!]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
    // 如果是新增，則刪除暫存列
    if (editingId === TEMP_NEW_ROW_ID) {
      setRows(oldRows => oldRows.filter(row => row.id !== TEMP_NEW_ROW_ID))
    }
    // 清除編輯ID
    setEditingId(null)
  }, [editingId])

  const processRowUpdate = React.useCallback(async (newRow: GridRowModel) => {
    // 如果是新增的情況
    if (newRow.id === TEMP_NEW_ROW_ID) {
      // 刪除暫存列
      setRows(oldRows => oldRows.filter(row => row.id !== TEMP_NEW_ROW_ID))
      // 新增列
      setRows(oldRows => [...oldRows, { ...newRow, id: idCounter++ }])
    }
    // 如果是編輯的情況
    else {
      // 更新列
      setRows(oldRows => oldRows.map(row => row.id === newRow.id ? newRow : row))
    }
    return newRow
  }, [])

  const handleProcessRowUpdateError = React.useCallback((e: Error) => {
    snackbar.show('更新失敗：' + e, 'error')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 150,
        editable: true,
        valueFormatter: params => dayjs(params.value).format('YYYY-MM-DD'),
        valueSetter: params => {
          // 原本DataGrid的日期編輯會拿到local的時間（2022-10-01 utc+8）
          // 要把他轉回 （2022-10-01 utc+0）
          return { ...params.row, [FIELDS.DATE]: dayjs(params.value).utc(true).toDate() }
        }
      },
      {
        field: FIELDS.COST,
        headerName: '成本',
        type: 'number',
        width: 100,
        editable: true,
      },
      {
        field: FIELDS.PRICE,
        headerName: '訂價',
        type: 'number',
        width: 100,
        editable: true,
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 200,
        editable: true,
      },
      {
        field: FIELDS.DEFAULT,
        headerName: '套用',
        type: 'actions',
        width: 75,
        getActions: ({ id, row }) => {
          const isDefault = row[FIELDS.DEFAULT]
          return [
            <GridActionsCellItem
              disabled={editingId !== null || isDefault}
              icon={isDefault ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
              label="套用"
              onClick={() => handleDefaultClick(id)}
            />
          ]
        }
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 120,
        getActions: ({ id }) => {
          if (editingId !== id) {
            return [
              <GridActionsCellItem
                disabled={editingId !== null}
                icon={<EditIcon />}
                label="編輯"
                onClick={() => handleEditClick(id)}
              />,
              <GridActionsCellItem
                disabled={editingId !== null}
                icon={<DeleteIcon />}
                label="刪除"
                onClick={() => handleDeleteClick(id)}
                color="error"
              />,
            ]
          } else {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="儲存"
                onClick={() => handleEditSaveClick()}
                color="primary"
              />,
              <GridActionsCellItem
                icon={<CloseIcon />}
                label="取消"
                onClick={() => handleEditCancelClick()}
                color="inherit"
              />,
            ]
          }
        },
      },
    ]
  }, [editingId, handleDefaultClick, handleEditClick, handleDeleteClick, handleEditSaveClick, handleEditCancelClick])

  if (item === null) {
    return null
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={itemId !== null}
      onClose={onClose}
    >
      <DialogTitle>貨物訂價</DialogTitle>
      <DialogContent>
        <DataGridPremium
          rows={rows}
          columns={columns}
          density="compact"
          autoHeight
          // edit
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={(params, event) => { event.defaultMuiPrevented = true }}  // 關閉預設進入編輯模式的方法
          onRowEditStop={(params, event) => { event.defaultMuiPrevented = true }}   // 關閉預設離開編輯模式的方法
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          // selection
          disableMultipleSelection
          onSelectionModelChange={(selectionModel) => { setSelectionModel(selectionModel) }}
          // sort
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          // Component
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <Button disabled={editingId !== null} color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
                  新增
                </Button>
              </GridToolbarContainer>
            ),
            Footer: () => {
              // 如果正在編輯中，就使用編輯中的訂價與成本；否則使用選擇中的
              let row = undefined
              if (editingId !== null) {
                row = rows.find(row => row.id === editingId)
              } else if (selectionModel.length === 1 && selectionModel[0] !== TEMP_NEW_ROW_ID) {
                row = rows.find(row => row.id === selectionModel[0])
              }
              const cost: number = row ? row[FIELDS.COST] : 0
              const price: number = row ? row[FIELDS.PRICE] : 0
              const grossProfit = price - cost
              const floatFormater = new Intl.NumberFormat('zh-TW', { style: 'decimal', maximumFractionDigits: 1, minimumFractionDigits: 1 })
              const percentFormater = new Intl.NumberFormat('zh-TW', { style: 'percent', minimumFractionDigits: 1 })
              const pkgWeight = item.get('pkgWeight') || 0
              const unitQty = item.get('unitQty') || 0
              const unitName = item.get('unitName') || '單位'
              return (
                <GridFooterContainer>
                  <TableContainer>
                    <Table size="small" padding="none">
                      <TableHead>
                        <TableRow>
                          <TableCell width="120"></TableCell>
                          <TableCell width="200" align="center">成本<br />{cost}元</TableCell>
                          <TableCell width="200" align="center">定價<br />{price}元</TableCell>
                          <TableCell width="200" align="center">毛利<br />{grossProfit}元{price > 0 ? `（${percentFormater.format(grossProfit / price)}）` : ''} </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">重量　{pkgWeight}公斤</TableCell>
                          <TableCell align="right">{floatFormater.format(cost / pkgWeight)}元/公斤</TableCell>
                          <TableCell align="right">{floatFormater.format(price / pkgWeight)}元/公斤</TableCell>
                          <TableCell align="right">{floatFormater.format(grossProfit / pkgWeight)}元/公斤</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left">數量　{item.get('unitExact') ? '' : '約'}{unitQty}{unitName}</TableCell>
                          <TableCell align="right">{floatFormater.format(cost / unitQty)}元/{unitName}</TableCell>
                          <TableCell align="right">{floatFormater.format(price / unitQty)}元/{unitName}</TableCell>
                          <TableCell align="right">{floatFormater.format(grossProfit / unitQty)}元/{unitName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridFooterContainer>
              )
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSave}>
          儲存
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}