import { PosPrinter } from '../parse-class/PosPrinter'
import { PosPrinterTask } from '../parse-class/PosPrinterTask'

namespace TaskHelper {

  interface IOptions {
    title: string
    salesperson: string
    cellphone: string
    copies: number
  }

  interface IParams {
    title: string
    salesperson: string
    cellphone: string
    copies: number
  }

  export function createParams(options: IOptions) {

    const { title, salesperson, cellphone, copies } = options
    const params: IParams = {
      title,
      salesperson,
      cellphone,
      copies
    }
    return params
  }
}

export interface IOptions {
  printer: PosPrinter
  title: string
  salesperson: string
  cellphone: string
  copies: number
}

export async function createSalesQuotationCoverTask(options: IOptions) {
  // 建立任務
  const { printer, title, salesperson, cellphone, copies } = options
  const params = TaskHelper.createParams({
    title,
    salesperson,
    cellphone,
    copies
  })
  const task = new PosPrinterTask()
  task.set('printer', printer)
  task.set('template', 'sales_quotation_cover')
  task.set('params', JSON.stringify(params))
  await task.save()
}