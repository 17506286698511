import React from 'react'
import { HashRouter, Routes, Route } from "react-router-dom"
import { App } from '../page/App'
import { MapDeliveryPage } from '../page/MapDeliveryPage'
import { MapCustomerPage } from '../page/MapCustomerPage'
import { CustomerPage } from '../page/CustomerPage'
import { ProviderPage } from '../page/ProviderPage'
import { ItemPage } from '../page/ItemPage'
import { TransactionCustomerPage } from '../page/TransactionCustomerPage'
import { AccountCustomerPage } from '../page/AccountCustomerPage'
import { TransactionProviderPage } from '../page/TransactionProviderPage'
import { AccountProviderPage } from '../page/AccountProviderPage'
import { PurchaseHelperPage } from '../page/PurchaseHelperPage'
import { ReportDailyTransactionPage } from '../page/ReportDailyTransactionPage'
import { InventoryAdjustmentPage } from '../page/InventoryAdjustmentPage'
import { InventoryUnsalablePage } from '../page/InventoryUnsalablePage'
import { GoogleMapPlacePage } from '../page/GoogleMapPlacePage'
import { SalesQuotationPage } from '../page/SalesQuotationPage'
import { MarketingNotePage } from '../page/MarketingNotePage'
import { MarketingSamplePage } from '../page/MarketingSamplePage'
import { TaxEntityPage } from '../page/TaxEntityPage'
import { UniformInvoicePage } from '../page/UniformInvoicePage'
import { ElectronicInvoicePage } from '../page/ElectronicInvoicePage'
import { ZItemPage } from '../page/ZItemPage'
import { ZCompanyPage } from '../page/ZCompanyPage'
import { PosPrinterPage } from '../page/PosPrinterPage'
import { ClientPreferencePage } from '../page/ClientPreferencePage'
import { AuthContext } from '../context/auth'

export function Router() {

  const { user } = React.useContext(AuthContext)

  /**
   * 因為大部分頁面都會載入需要權限的資料，
   * 所以未登錄時只顯示APP框架和登入框，
   * 避免因抓取資料而顯示沒有權限的錯誤訊息
   */
  if (!user) {
    return (
      <HashRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </HashRouter>
    )
  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<TransactionCustomerPage />} />
          <Route path="map-delivery" element={<MapDeliveryPage />} />
          <Route path="map-customer" element={<MapCustomerPage />} />
          <Route path="customer" element={<CustomerPage />} />
          <Route path="provider" element={<ProviderPage />} />
          <Route path="item" element={<ItemPage />} />
          <Route path="transaction_provider" element={<TransactionProviderPage />} />
          <Route path="account_provider" element={<AccountProviderPage />} />
          <Route path="purchase_helper" element={<PurchaseHelperPage />} />
          <Route path="transaction_customer" element={<TransactionCustomerPage />} />
          <Route path="account_customer" element={<AccountCustomerPage />} />
          <Route path="report/daily_transaction" element={<ReportDailyTransactionPage />} />
          <Route path="inventory_adjustment" element={<InventoryAdjustmentPage />} />
          <Route path="inventory_unsalable" element={<InventoryUnsalablePage />} />
          <Route path="google_map_place" element={<GoogleMapPlacePage />} />
          <Route path="sales_quotation" element={<SalesQuotationPage />} />
          <Route path="marketing_note" element={<MarketingNotePage />} />
          <Route path="marketing_sample" element={<MarketingSamplePage />} />
          <Route path="tax_entity" element={<TaxEntityPage />} />
          <Route path="uniform_invoice" element={<UniformInvoicePage />} />
          <Route path="electronic_invoice" element={<ElectronicInvoicePage />} />
          <Route path="z_item" element={<ZItemPage />} />
          <Route path="z_company" element={<ZCompanyPage />} />
          <Route path="pos_printer" element={<PosPrinterPage />} />
          <Route path="client_preference" element={<ClientPreferencePage />} />
        </Route>
      </Routes>
    </HashRouter>
  )
}