import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer, GridRowModel } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import SyncIcon from '@mui/icons-material/Sync'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import OptionsDialog from './OptionsDialog'
import AddDialog from './AddDialog'
import CheckDialog from './CheckDialog'
import PurchaseMsgDialog from './PurchaseMsgDialog'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'
import { Provider } from '../../parse-class/Provider'
import { updateItemCacheStorage } from '../../cloud/report-storage'

export const FIELDS = {
  AVAILABLE: 'AVAILABLE',
  ITEM: 'ITEM',
  SAFETY_STOCKS: 'SAFETY_STOCKS',
  NOTE: 'NOTE',
  CACHE_STOCK: 'CACHE_STOCK',
  CACHE_MONTHLY_SALES: 'CACHE_MONTHLY_SALES',
  ORDER: 'ORDER',
  PREVIOUS_ORDER: 'PREVIOUS_ORDER',
}

export function PurchaseHelperPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [helperList, setHelperList] = React.useState<PurchaseHelper[] | null>(null)
  // state
  const [provider, setProvider] = React.useState<Provider | null>(null)
  const [showOptionsDialog, setShowOptionsDialog] = React.useState(true)
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [showCheckDialog, setShowCheckDialog] = React.useState(false)
  const [showMsgDialog, setShowMsgDialog] = React.useState(false)

  const loadData = React.useCallback(async () => {
    if (!provider) {
      return setHelperList([])
    }
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(PurchaseHelper)
      query.limit(10000)
      query.equalTo('provider', provider)
      query.descending('createdAt')
      query.include('item', 'provider')
      const data = await query.find()
      setHelperList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 重新載入快取庫存
   */
  const loadItemCacheStorage = React.useCallback(async () => {
    try {
      backdrop.show('重新載入庫存中')
      await updateItemCacheStorage()
    } catch (e: any) {
      snackbar.show('重新載入庫存失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    await loadData()  // 重新載入資料
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const data = PurchaseHelper.createWithoutData(id)
      await data.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  /**
   * 訂單數量轉前次訂單
   */
  const toPrevious = React.useCallback(async () => {
    if (!helperList) return
    try {
      backdrop.show('更新資料中')
      for (const helper of helperList) {
        helper.set('previousOrder', helper.get('order'))
        helper.set('order', 0)
      }
      await Parse.Object.saveAll(helperList)
      snackbar.show('更新成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('更新資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helperList, loadData])

  const title = React.useMemo(() => {
    if (provider) {
      return `採購助手（${provider.get('name')}）`
    } else {
      return '採購助手'
    }
  }, [provider])

  const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    const helper = await new Parse.Query(PurchaseHelper).get(oldRow.id)
    helper.set('available', newRow[FIELDS.AVAILABLE])
    helper.set('safetyStocks', newRow[FIELDS.SAFETY_STOCKS])
    helper.set('order', newRow[FIELDS.ORDER])
    helper.set('previousOrder', newRow[FIELDS.PREVIOUS_ORDER])
    helper.set('note', newRow[FIELDS.NOTE])
    await helper.save()
    return {
      id: helper.id,
      [FIELDS.AVAILABLE]: helper.get('available'),
      [FIELDS.SAFETY_STOCKS]: helper.get('safetyStocks'),
      [FIELDS.ORDER]: helper.get('order'),
      [FIELDS.PREVIOUS_ORDER]: helper.get('previousOrder'),
      [FIELDS.NOTE]: helper.get('note'),
    }
  }, [])

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    snackbar.show('更新資料失敗：' + error.message, 'error')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = React.useMemo(() => {
    if (!helperList) {
      return []
    }
    return helperList.map(helper => ({
      id: helper.id,
      [FIELDS.AVAILABLE]: helper.get('available'),
      [FIELDS.ITEM]: helper.get('item'),
      [FIELDS.SAFETY_STOCKS]: helper.get('safetyStocks'),
      [FIELDS.NOTE]: helper.get('note'),
      [FIELDS.CACHE_STOCK]: helper.get('item')!.get('cacheStock'),
      [FIELDS.CACHE_MONTHLY_SALES]: helper.get('item')!.get('cacheMonthlySales'),
      [FIELDS.ORDER]: helper.get('order'),
      [FIELDS.PREVIOUS_ORDER]: helper.get('previousOrder'),
    }))
  }, [helperList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        // hide: true,
        field: FIELDS.AVAILABLE,
        headerName: '有效',
        type: 'boolean',
        width: 80,
        sortable: false,
        editable: true
      },
      {
        field: FIELDS.ITEM,
        headerName: '貨物',
        width: 300,
        valueGetter: params => params.row[FIELDS.ITEM].getFormatName(),
      },
      {
        field: FIELDS.CACHE_STOCK,
        headerName: '庫存',
        width: 80,
        type: 'number',
      },
      {
        field: FIELDS.CACHE_MONTHLY_SALES,
        headerName: '月銷',
        width: 80,
        type: 'number',
      },
      {
        field: FIELDS.SAFETY_STOCKS,
        headerName: '安全庫存',
        width: 80,
        type: 'number',
        editable: true
      },
      {
        field: FIELDS.ORDER,
        headerName: '訂單',
        width: 80,
        type: 'number',
        editable: true
      },
      {
        field: FIELDS.PREVIOUS_ORDER,
        headerName: '前次訂單',
        width: 80,
        type: 'number',
        editable: true
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 300,
        editable: true
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 60,
        getActions: ({ id }) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="刪除"
            color="error"
            onClick={() => { onDelete(id as string) }}
          />,
        ]
      }
    ]
  }, [onDelete])

  if (helperList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title={title} />
          <Box mt={2} mb={1} height="80vh" sx={{
            '& .unavailable-row': {
              bgcolor: '#AAAAAA',
            },
            '& .unavailable-row:hover:hover': { // 不知為何，要兩個hover才會生效
              bgcolor: '#BBBBBB',
            }
          }} >
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              // styles
              getRowClassName={params => params.row[FIELDS.AVAILABLE] ? '' : `unavailable-row`}
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      disabled={!provider}
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<SettingsIcon />}
                      onClick={() => { setShowOptionsDialog(true) }}
                    >
                      選項
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<SyncIcon />}
                      onClick={() => loadItemCacheStorage()}
                    >
                      重新載入庫存
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<WarningAmberIcon />}
                      onClick={() => setShowCheckDialog(true)}
                    >
                      檢查
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<AutoFixHighIcon />}
                      onClick={() => toPrevious()}
                    >
                      轉前次訂單
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<AutoFixHighIcon />}
                      onClick={() => setShowMsgDialog(true)}
                    >
                      轉採購訊息
                    </Button>
                  </GridToolbarContainer>
                )
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      {/*  */}
      <OptionsDialog
        open={showOptionsDialog}
        onClose={() => { setShowOptionsDialog(false) }}
        fullWidth
        maxWidth="sm"
        onUpdate={options => {
          setProvider(options.provider)
          setShowOptionsDialog(false)
        }}
      />
      {/* Add Dialog */}
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        fullWidth
        maxWidth="sm"
        provider={provider!}
        onUpdate={() => {
          loadData()              // 載入新資料
          setShowAddDialog(false) // 關閉視窗
        }}
      />
      {/*  */}
      <CheckDialog
        open={showCheckDialog}
        onClose={() => { setShowCheckDialog(false) }}
        fullWidth
        maxWidth="md"
        provider={provider}
      />
      {/*  */}
      <PurchaseMsgDialog
        open={showMsgDialog}
        onClose={() => { setShowMsgDialog(false) }}
        fullWidth
        maxWidth="sm"
        provider={provider}
      />
    </React.Fragment >
  )
}
