import { PosPrinter } from '../parse-class/PosPrinter'
import { PosPrinterTask } from '../parse-class/PosPrinterTask'
import { SalesQuotation } from '../parse-class/SalesQuotation'

namespace TaskHelper {

  interface IOptions {
    quotation: SalesQuotation
  }

  interface IParams {
    title: string
    items: {
      name: string
      spec: string
      pkg: string
      price: number
      description: string
    }[]
  }

  export function createParams(options: IOptions) {

    const { quotation } = options

    // 報價明細
    const details = quotation.get('details') || []
    const params: IParams = {
      title: quotation.get('note') || '',
      items: details.map(detail => {
        const item = detail.item
        let price = detail.price
        // 如果價格為0，自動帶入預設價格
        if (price === 0) {
          price = item.getDefaultPricing()?.price || 0
        }
        const unitName = item.get('unitName') || ''
        const unitQty = item.get('unitQty') || 0
        const avg = unitName.length > 0 && unitQty !== 0 ?
          `（${item.get('unitExact') ? '' : '約'}${unitQty}${unitName}→${Intl.NumberFormat('zh-tw', { maximumFractionDigits: 1 }).format(price / unitQty)}元/${unitName}）` : '';
        return {
          name: item.get('name') || '',
          spec: item.get('spec') || '',
          pkg: item.get('pkg') || '',
          price: price,
          description: `${item.get('pkgDescription') || ''}${avg}`,
        }
      }),
    }
    return params
  }
}

export interface IOptions {
  printer: PosPrinter
  quotationIds: string[]
  copies: number
}

export async function createSalesQuotationTask(options: IOptions) {

  const { printer, quotationIds, copies } = options

  const quotations = await new Parse.Query(SalesQuotation)
    .containedIn('objectId', quotationIds)
    // @ts-ignore
    .include('details.item')
    .find()

  for (let i = 0; i < copies; i++) {
    for (let quotation of quotations) {
      const params = TaskHelper.createParams({ quotation })
      const task = new PosPrinterTask()
      task.set('printer', printer)
      task.set('template', 'sales_quotation')
      task.set('params', JSON.stringify(params))
      await task.save()
    }
  }
}