/**
 * 物流排序對話框
 * TODO: React.StrictMode 會導致react-beautiful-dnd在開發階段出問題，可以先關閉
 */
import React from 'react'

import dayjs from 'dayjs'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'

interface IProps extends DialogProps {
  today: dayjs.Dayjs
}

export default function TransactionDeliveryOrderDialog(props: IProps) {

  const { today, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [transactionList, setTransactionList] = React.useState<TransactionCustomer[] | null>(null)
  // state
  const [hasChanged, setHasChanged] = React.useState(false)   // 記錄是否排序有異動

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const transactionList = await new Parse.Query(TransactionCustomer)
        .limit(10000)
        .equalTo('date', today.toDate())
        .include('customer')
        .ascending('deliveryOrder')
        .find()
      setTransactionList(transactionList)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
      setHasChanged(false)
    }
  }, [dialogProps.open, loadData])

  const save = React.useCallback(async () => {
    if (!transactionList) return
    try {
      backdrop.show('更新資料中')
      transactionList.forEach((transaction, index) => {
        transaction.set('deliveryOrder', index)
      })
      await TransactionCustomer.saveAll(transactionList)
      dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') // 關閉視窗
    } catch (e: any) {
      snackbar.show('更新資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionList])

  const reorderData = React.useCallback((sourceIndex: number, destinationIndex: number) => {
    setTransactionList(raw => {
      if (!raw) return null
      const clone = [...raw]
      const [temp] = clone.splice(sourceIndex, 1)
      clone.splice(destinationIndex, 0, temp)
      return clone
    })
  }, [])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>物流排程 {today.format('YYYY-MM-DD')}</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={result => {
          if (!result.destination) return
          reorderData(result.source.index, result.destination.index)
          setHasChanged(true)
        }}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List ref={provided.innerRef} dense>
                {transactionList?.map((transaction, index) =>
                  <Draggable
                    key={transaction.id}
                    draggableId={transaction.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ background: snapshot.isDragging ? 'rgb(235,235,235)' : undefined }}
                      >
                        <ListItemIcon>
                          <DragHandleIcon />
                        </ListItemIcon>
                        <ListItemText primary={transaction.get('customer')?.get('name')} />
                      </ListItem>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!hasChanged}
          color="primary"
          onClick={save}
        >
          儲存
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}