import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import { Image } from 'mui-image'

interface IProps extends DialogProps {
  src: string | null
}

export default function ImageViewDialog(props: IProps) {

  const { src, ...dialogProps } = props

  return (
    <React.Fragment >
      <Dialog
        {...dialogProps}
        onClick={() => {
          dialogProps.onClose && dialogProps.onClose({}, 'backdropClick')
        }}
      >
        {src &&
          <Image
            height="100%"
            width="100%"
            src={src}
            fit='scale-down'
            showLoading
            duration={1000}
          />
        }
      </Dialog>
    </React.Fragment >
  )
  
}