import React from 'react'
import Container from '@mui/material/Container'
import { DataGridPremium, GridColumns, GridSortModel, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox } from '../../component/standard-component'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import ViewDialog from './ViewDialog'
import { ElectronicInvoice } from '../../parse-class/ElectronicInvoice'
import { TaxEntity } from '../../parse-class/TaxEntity'
import { getSelfTaxEntity } from '../../cloud/get-self-tax-entity'

export const FIELDS = {
  DATE: 'DATE',
  CODE: 'CODE',
  RANDOM: 'RANDOM',
  TAX_ENTITY: 'TAX_ENTITY',
  TAX_TYPE: 'TAX_TYPE',
}

export function ElectronicInvoicePage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [invoiceList, setInvoiceList] = React.useState<ElectronicInvoice[]>([])
  const [selfTaxEntity, setSelfTaxEntity] = React.useState<TaxEntity | null>(null)
  // state
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc' } // 按照日期降序排列
  ])
  const [direction, setDirection] = React.useState<'INPUT' | 'OUTPUT'>('INPUT')
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [viewingId, setViewingId] = React.useState<string | null>(null)
  const [editingId, setEditingId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(ElectronicInvoice)
      query.limit(10000)
      query.descending('createdAt')
      query.include('fromEntity')
      query.include('toEntity')
      const data = await query.find()
      setInvoiceList(data)
      const selfTaxEntity = await getSelfTaxEntity()
      setSelfTaxEntity(selfTaxEntity)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const invoice = ElectronicInvoice.createWithoutData(id)
      await invoice.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!invoiceList || !selfTaxEntity) {
      return []
    }
    let filteredInvoiceList: ElectronicInvoice[]
    switch (direction) {
      case 'INPUT':
        filteredInvoiceList = invoiceList.filter(invoice => invoice.get('toEntity')?.id === selfTaxEntity.id)
        break
      case 'OUTPUT':
        filteredInvoiceList = invoiceList.filter(invoice => invoice.get('fromEntity')?.id === selfTaxEntity.id)
        break
    }
    return filteredInvoiceList.map(invoice => (
      {
        id: invoice.id,
        [FIELDS.DATE]: invoice.get('date'),
        [FIELDS.CODE]: invoice.get('code'),
        [FIELDS.RANDOM]: invoice.get('random'),
        [FIELDS.TAX_ENTITY]: direction === 'INPUT' ? invoice.get('fromEntity')?.get('nickname') : invoice.get('toEntity')?.get('nickname'),
        [FIELDS.TAX_TYPE]: invoice.get('taxType'),
      }
    ))
  }, [invoiceList, selfTaxEntity, direction])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
      },
      {
        field: FIELDS.CODE,
        headerName: '發票號碼',
        width: 130,
      },
      {
        field: FIELDS.RANDOM,
        headerName: '隨機碼',
        width: 75,
      },
      {
        field: FIELDS.TAX_ENTITY,
        headerName: '抬頭統編',
        width: 200,
      },
      {
        field: FIELDS.TAX_TYPE,
        headerName: '稅別',
        width: 75,
        valueFormatter: params => {
          switch (params.value) {
            case 'TAXABLE': return '應稅'
            case 'TAX_EXEMPTION': return '免稅'
            case 'ZERO_TAX': return '零稅率'
          }
        }
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 110,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="查看"
              onClick={() => { setViewingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      }
    ]
  }, [onDelete])

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <StandardBox sx={{ pt: 0, px: 0 }}>
          <Tabs value={direction} onChange={(e, value) => { setDirection(value) }}>
            <Tab label="銷貨電子發票" value={'OUTPUT'} />
            <Tab label="進貨電子發票" value={'INPUT'} />
          </Tabs>
          <Box mt={2} mb={1} mx={2} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel([...model])}
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setShowAddDialog(true)
                      }}
                    >
                      新增
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <ViewDialog
        open={viewingId !== null}
        onClose={() => setViewingId(null)}
        fullWidth
        maxWidth='sm'
        viewingId={viewingId}
      />
      <AddDialog
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        fullWidth
        maxWidth='sm'
        direction={direction}
        onUpdate={() => {
          setShowAddDialog(false) // 關閉視窗
          loadData()              // 重新載入資料
        }}
      />
      <EditDialog
        open={editingId !== null}
        onClose={() => setEditingId(null)}
        fullWidth
        maxWidth='sm'
        direction={direction}
        editingId={editingId}
        onUpdate={() => {
          setEditingId(null)    // 關閉視窗
          loadData()            // 重新載入資料
        }}
      />
    </React.Fragment>
  )
}