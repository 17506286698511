import { Download } from '../parse-class/Download'

export interface ISampleLabel {
  customerName: string
  itemName: string
  price: string
}

export interface IParam {
  labels: ISampleLabel[]
}

export const downloadSampleLabelPDF = async (param: IParam, options?: Parse.Cloud.RunOptions) => {
  const download: Download = await Parse.Cloud.run('downloadSampleLabelPDF', param, options)
  return download
}