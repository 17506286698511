/**
 * 庫存貨物銷售明細視窗
 */
import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { DataGridPremium, GridColumns, GridSortModel } from '@mui/x-data-grid-premium'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { Item } from '../../parse-class/Item'
import { Customer } from '../../parse-class/Customer'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'

interface ISalesRocord {
  date: Date
  customer: Customer
  qty: number
}

export const Columns = {
  DATE: 'DATE',
  NAME: 'NAME',
  QTY: 'QTY',
}

interface IProps extends DialogProps {
  itemId: string | null
}

export default function SalesRecordDialog(props: IProps) {

  const { itemId, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [item, setItem] = React.useState<Item | null>(null)
  const [salesRocord, setSalesRocord] = React.useState<ISalesRocord[] | null>(null)
  // state
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: Columns.DATE, sort: 'desc' } // 按照日期名稱降序
  ])

  const loadData = React.useCallback(async (itemId: string) => {
    try {
      backdrop.show('讀取資料中')
      const item = await new Parse.Query(Item).get(itemId)
      setItem(item)
      const transactions = await new Parse.Query(TransactionCustomer)
        // @ts-ignore
        .equalTo('details.item', item)
        .select('date', 'customer', 'details')
        .include('customer')
        .descending('date')
        .limit(100)
        .find()
      const salesRocord: ISalesRocord[] = []
      for (const transaction of transactions) {
        const qty = transaction.get('details')!
          .filter(detail => detail.item.id === itemId)
          .reduce((pre, cur) => pre + cur.qty, 0)
        const customer = transaction.get('customer')
        salesRocord.push({
          date: transaction.get('date')!,
          customer: customer!,
          qty: qty,
        })
      }
      setSalesRocord(salesRocord)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && itemId) {
      loadData(itemId)
    }
  }, [dialogProps.open, itemId, loadData])

  const rows = React.useMemo(() => {
    if (!salesRocord) {
      return []
    }
    return salesRocord.map((r, index) => ({
      id: index,
      [Columns.DATE]: r.date,
      [Columns.NAME]: r.customer.get('name'),
      [Columns.QTY]: r.qty,
    }))
  }, [salesRocord])

  /**
   * 定義欄位
   */
  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: Columns.DATE,
        type: 'date',
        headerName: '日期',
        width: 120,
      },
      {
        field: Columns.NAME,
        headerName: '名稱',
        width: 200,
      },
      {
        field: Columns.QTY,
        headerName: '數量',
        width: 120,
        type: 'number',
        valueFormatter: params => `${params.value}${item?.get('pkg') || ''}`
      },
    ]
  }, [item])

  if (item === null || salesRocord === null) {
    return null
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{item.getFormatName({ pkg: false })}</DialogTitle>
      <DialogContent>
        <DataGridPremium
          rows={rows}
          columns={columns}
          density="compact"
          autoHeight
          disableSelectionOnClick
          // sort
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}