import React from 'react'
import { Image as MuiImage } from 'mui-image'
import { useDropzone } from 'react-dropzone' // https://react-dropzone.js.org/
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DeleteIcon from '@mui/icons-material/Delete'
import ImageViewDialog from '../../component/ImageViewDialog'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { useCheckMobile } from '../../context/user-check-mobile'
import { Item } from '../../parse-class/Item'
import { ItemImage } from '../../parse-class/ItemImage'
import { Image } from '../../parse-class/Image'

interface IProps extends DialogProps {
  itemId: string | null
}

export default function ImageDialog(props: IProps) {

  const { itemId, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  const isMobile = useCheckMobile()
  // data
  const [item, setItem] = React.useState<Item | null>(null)
  const [images, setImages] = React.useState<ItemImage[] | null>(null)
  // state
  const [tab, setTab] = React.useState<'list' | 'upload'>('list')
  const [uploadingFile, setUploadingFile] = React.useState<File | null>(null)
  const [showImage, setShowImage] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    if (!itemId) return
    try {
      backdrop.show('讀取資料中')
      const item = await new Parse.Query(Item).get(itemId)
      setItem(item)
      const data = await new Parse.Query(ItemImage)
        .equalTo('item', item)
        .include('image')
        // @ts-ignore
        .include('image.x300', 'image.x1440', 'image.raw')
        .find()
      setImages(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId])

  React.useEffect(() => {
    if (dialogProps.open) {
      loadData()
      setTab('list')
    }
  }, [dialogProps.open, loadData])

  const onDelete = React.useCallback(async (itemImage: ItemImage) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這張圖片？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      await itemImage.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const uploader = useDropzone({
    maxFiles: 1,
    autoFocus: true,
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      if (fileRejections.length === 0 && acceptedFiles.length === 1) {
        setUploadingFile(acceptedFiles[0])
      }
    }
  })

  const preview = React.useMemo(() => {
    return uploadingFile && URL.createObjectURL(uploadingFile)
  }, [uploadingFile])

  const uploadFile = React.useCallback(async () => {
    if (!uploadingFile || !item) return
    try {
      backdrop.show('上傳圖片中')
      // 建構 Parse File
      const buffer = await uploadingFile.arrayBuffer()
      const file = new Parse.File(uploadingFile.name, [...new Uint8Array(buffer)])
      // 建構 Image
      const image = new Image()
      image.set('raw', file)
      // 建構 ItemImage
      const itemImage = new ItemImage()
      itemImage.set('image', image)
      itemImage.set('item', item)
      await itemImage.save()
      snackbar.show('上傳成功')
      setUploadingFile(null)  // 清除上傳資料
      await loadData()        // 重新讀取資料
    } catch (e: any) {
      snackbar.show('上傳失敗：' + e.message, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingFile, item, loadData])

  const ImageDisplay = React.useMemo(() => {
    if (!images) {
      return null
    }
    return (
      <Grid container spacing={1}>
        {images.length === 0 &&
          <Grid item>
            <Typography>目前沒有任何圖片</Typography>
          </Grid>
        }
        {images.map(image => {
          const raw = image.get('image')!.get('raw')?.url({ forceSecure: true })
          const x1440 = image.get('image')!.get('x1440')?.url({ forceSecure: true })
          const x300 = image.get('image')!.get('x300')?.url({ forceSecure: true })
          const sm = x300 || raw
          const lg = x1440 || raw
          return (
            (
              <Grid item key={image.id} xs={12} sm={6} md={4} xl={4}>
                <Card>
                  <CardActionArea
                    disabled={!lg}
                    onClick={() => { setShowImage(lg || '') }}
                  >
                    <MuiImage
                      height={200}
                      src={sm || ''}
                      fit='cover'
                      duration={1000}
                    />
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => { onDelete(image) }}
                    >
                      刪除
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          )
        })}
      </Grid>
    )
  }, [images, onDelete])

  const UploadDisplay = React.useMemo(() => (
    <React.Fragment>
      <Box
        {...uploader.getRootProps()}
        height={80}
        border={1}
        borderRadius="borderRadius"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={uploader.isDragActive ? "primary.light" : ''}
      >
        <input {...uploader.getInputProps()} />
        <Typography color="textPrimary" variant="h6" align="center">
          按這裡上傳<br />或把照片拖進來
        </Typography>
      </Box>
      {preview &&
        <Box p={1}>
          <MuiImage
            height={270}
            width={200}
            src={preview}
            fit='scale-down'
            duration={500}
          />
        </Box>
      }
    </React.Fragment>
  ), [uploader, preview])

  return (
    <React.Fragment>
      <Dialog  {...dialogProps}>
        <Tabs value={tab} onChange={(e, v) => { setTab(v) }} >
          <Tab label="瀏覽" value="list" />
          <Tab label="上傳" value="upload" />
        </Tabs>
        <DialogContent>
          <Box minHeight={350}>
            {tab === 'list' && ImageDisplay}
            {tab === 'upload' && UploadDisplay}
          </Box>
        </DialogContent>
        <DialogActions>
          {tab === 'upload' &&
            <Button
              disabled={!uploadingFile}
              color="primary"
              onClick={uploadFile}
            >
              上傳
            </Button>
          }
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
      {/* Image Dialog */}
      <ImageViewDialog
        open={showImage !== null}
        onClose={() => { setShowImage(null) }}
        fullWidth
        maxWidth="xl"
        fullScreen={isMobile}
        src={showImage}
      />
    </React.Fragment >
  )
}