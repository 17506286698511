import { PosPrinter } from '../parse-class/PosPrinter'
import { PosPrinterTask } from '../parse-class/PosPrinterTask'
import { Item } from '../parse-class/Item'
import { updateItemCacheStorage } from '../cloud/report-storage'

namespace TaskHelper {

  interface IOptions { }

  interface IParams {
    details: {
      name: string,
      pkg: string,
      qty: number,
      pos: string,
    }[],
  }

  function formatItemName(item: Item) {
    let name = item.get('name') || ''
    let spec = item.get('spec') || ''
    let source = item.get('source') || ''
    // 加上規格
    if (spec.length > 0) {
      name = name + `【${spec}】`
    }
    // 加上供應商
    if (source.length > 0) {
      name = `${source}：` + name
    }
    return name
  }

  export async function createParams(options?: IOptions): Promise<IParams> {
    // 計算貨物庫存
    await updateItemCacheStorage()
    const items = await new Parse.Query(Item)
      .limit(10000)
      .find()
    // 格式化
    const result: { name: string, pkg: string, qty: number, pos: string }[] = []
    for (let item of items) {
      // 數量只保留兩位小數（避免JS數字計算後變成無限小數造成的麻煩）
      const cacheStock = item.get('cacheStock')!
      const qty = Math.round(cacheStock * 100) / 100
      // 【沒有位置】且【數量為0】時省略
      const pos = item.get('pos')!.trim()
      if (pos.length === 0 && qty === 0) {
        continue
      }
      result.push({
        name: formatItemName(item),
        pkg: item.get('pkg') || '',
        qty,
        pos: pos || '??',
      })
    }
    // 排序
    const sorted = result.sort((r1, r2) => {
      const posCompare = r1.pos.localeCompare(r2.pos)
      if (posCompare !== 0) {
        return posCompare
      }
      const nameCompare = r1.name.localeCompare(r2.name)
      return nameCompare
    })
    return { details: sorted }
  }
}

export interface IOptions {
  printer: PosPrinter
}

export async function createInventoryTask(options: IOptions) {
  // 取得transaction
  const { printer } = options
  // 建立列印任務
  const params = await TaskHelper.createParams()
  const task = new PosPrinterTask()
  task.set('printer', printer)
  task.set('template', 'inventory')
  task.set('params', JSON.stringify(params))
  await task.save()
}