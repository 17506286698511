import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import ItemAutocomplete from '../../input/ItemAutocomplete'
import BasicNumberField from '../../input/BasicNumberField'
import BasicTextField from '../../input/BasicTextField'
import { Item } from '../../parse-class/Item'
import { Provider } from '../../parse-class/Provider'
import { PurchaseHelper } from '../../parse-class/PurchaseHelper'

interface IFields {
  available: boolean
  item: Item | null
  safetyStocks: number
  note: string
}

interface IParams extends DialogProps {
  provider: Provider
  onUpdate: () => void
}

export default function AddDialog(props: IParams) {

  const { provider, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    available: true,
    item: null,
    safetyStocks: 0,
    note: '',
  })

  const verify = React.useMemo(() => {
    return fields.item !== null
  }, [fields])

  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        available: true,
        item: null,
        safetyStocks: 0,
        note: '',
      })
    }
  }, [dialogProps.open])

  const onSave = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('新增資料中')
        const data = new PurchaseHelper()
        data.set('available', fields.available)
        data.set('provider', provider)
        data.set('item', fields.item!)
        data.set('safetyStocks', fields.safetyStocks)
        data.set('note', fields.note)
        await data.save()
        snackbar.show('新增成功')
        onUpdate() // 通知外面資料更新
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify, fields, onUpdate])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>新增採購資料</DialogTitle>
      <DialogContent>
        <ItemAutocomplete
          value={fields.item}
          onChange={(event, value) => setFields(pv => ({ ...pv!, item: value }))}
          textFieldProps={{ autoFocus: true }}
        />
        <BasicNumberField
          label="安全庫存"
          defaultValue={0}
          onChange={e => setFields(pv => ({ ...pv!, safetyStocks: Number(e.target.value) }))}
        />
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv!, note: e.target.value })) }}
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          label="有效"
          control={
            <Checkbox
              defaultChecked={fields.available}
              onChange={e => setFields(pv => ({ ...pv!, available: e.target.checked }))}
              color="default"
            />
          }
        />
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}