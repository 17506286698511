import { Company } from '../Company'
import { Customer } from '../Customer'
import { Item } from '../Item'
import { User } from '../User'

export interface ITransactionDetail {
  item: Item
  qty: number
  price: number
}

export interface IAttributes {
  date: Date
  company: Company                // 交貨收款的公司
  customer: Customer              // 收貨付款的客戶
  details: ITransactionDetail[]
  allowance: number               // 折讓
  deliveryOrder: number           // 交(收)貨排程
  deliveryPerson: User            // 交(收)貨人員
  deliveryTime: Date              // 交(收)貨時間
  paymentPerson: User             // 收(付)款人員
  paymentTime: Date               // 收(付)款時間
  note: string
}

export const CLASS_NAME = 'TransactionCustomer'

export class TransactionCustomer extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }

  public sum() {
    const details = this.get('details') || []
    return details.reduce((pre, cur) => pre + Math.round(cur.qty * cur.price), 0)
  }
}

Parse.Object.registerSubclass(CLASS_NAME, TransactionCustomer)