import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ClientPreferenceContext } from '../../context/client-preference'
import DateField from '../../input/DateField'
import PosPrinterAutocomplete from '../../input/PosPrinterAutocomplete'
import { PosPrinter } from '../../parse-class/PosPrinter'
import { createInventoryTask } from '../../pos-printer/create-inventory-task'
dayjs.extend(utc)

interface IFields {
  printer: PosPrinter | null
  today: Date
}

export interface IProps extends DialogProps {
}

export default function PrintInventoryDialog(props: IProps) {

  const { ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const clientPreference = React.useContext(ClientPreferenceContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    printer: null,
    today: dayjs().utc(true).startOf('day').toDate()
  })

  React.useEffect(() => {
    if (dialogProps.open) {
      const defaultPosPrinter = clientPreference.get().defaultPosPrinter
      setFields({
        printer: defaultPosPrinter || null,
        today: dayjs().utc(true).startOf('day').toDate()
      })
    }
  }, [dialogProps.open, clientPreference])

  const verify = React.useMemo(() => {
    return fields.printer !== null
  }, [fields])

  const onClickResult = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('上傳列印任務中')
        await createInventoryTask({
          printer: fields.printer!,
        })
        snackbar.show('列印成功')
        dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') // 關閉視窗
      } catch (e: any) {
        snackbar.show('上傳列印任務失敗' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, dialogProps])

  return (
    <Dialog  {...dialogProps}>
      <DialogTitle>列印庫存盤點單</DialogTitle>
      <DialogContent>
        <DateField
          disabled
          value={fields.today}
          onDateChange={(event, value) => {
            setFields(pv => ({ ...pv!, today: value }))
          }}
        />
        <PosPrinterAutocomplete
          value={fields.printer}
          onChange={(event, value) => setFields(pv => ({ ...pv, printer: value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
          關閉
        </Button>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onClickResult}
        >
          列印
        </Button>
      </DialogActions>
    </Dialog>
  )
}