import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColumns, GridToolbarContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import ImageIcon from '@mui/icons-material/Image'
import RoomIcon from '@mui/icons-material/Room'
import DeleteIcon from '@mui/icons-material/Delete'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { Customer } from '../../parse-class/Customer'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import LegacyImageDialog from './LegacyImageDialog'

export const FIELDS = {
  NAME: 'NAME',
  ADDRESS: 'ADDRESS',
  GOOGLE_PLACE_ID: 'GOOGLE_PLACE_ID',
}

export function CustomerPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [customerList, setCustomerList] = React.useState<Customer[] | null>(null)
  // state
  const [imageCustomerId, setImageCustomerId] = React.useState<string | null>(null)
  const [showAddDialog, setShowAddDialog] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Customer)
      query.limit(10000)
      query.descending('createdAt')
      const data = await query.find()
      setCustomerList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const onDelete = React.useCallback(async (id: string) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    try {
      backdrop.show('刪除資料中')
      const customer = Customer.createWithoutData(id)
      await customer.destroy()
      snackbar.show('刪除成功')
      await loadData() // 重新載入資料
    } catch (e: any) {
      snackbar.show('刪除資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!customerList) {
      return []
    }
    return customerList.map(customer => (
      {
        id: customer.id,
        [FIELDS.NAME]: customer.get('name'),
        [FIELDS.ADDRESS]: `${customer.get('city')}${customer.get('district')}`,
        [FIELDS.GOOGLE_PLACE_ID]: customer.get('googlePlaceId'),
      }
    ))
  }, [customerList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.NAME,
        headerName: '客戶',
        width: 200,
      },
      {
        field: FIELDS.ADDRESS,
        headerName: '地區',
        width: 120,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 200,
        getActions: ({ id, row }) => {
          const placeId: string = row[FIELDS.GOOGLE_PLACE_ID]
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingId(id as string) }}
            />,
            <GridActionsCellItem
              disabled={placeId.length === 0}
              icon={<RoomIcon />}
              label="地圖"
              onClick={() => {
                // const url = `https://www.google.com/maps/place/?q=place_id:${placeId}` // 手機版無法使用
                const url = `https://www.google.com/maps/search/?api=1&query=0,0&query_place_id=${placeId}`
                window.open(url, '_blank')?.focus()
              }}
            />,
            <GridActionsCellItem
              icon={<ImageIcon />}
              label="照片"
              onClick={() => { setImageCustomerId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="刪除"
              color="error"
              onClick={() => { onDelete(id as string) }}
            />,
          ]
        }
      }
    ]
  }, [onDelete])

  if (customerList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="客戶" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              // Component
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => { setShowAddDialog(true) }}
                    >
                      新增
                    </Button>
                  </GridToolbarContainer>
                ),
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      {/*  */}
      <LegacyImageDialog
        open={imageCustomerId !== null}
        onClose={() => { setImageCustomerId(null) }}
        fullWidth
        maxWidth="md"
        customerId={imageCustomerId}
      />
      {/* Add Dialog */}
      <AddDialog
        open={showAddDialog}
        onClose={() => { setShowAddDialog(false) }}
        onChange={() => {
          loadData()              // 載入新資料
          setShowAddDialog(false) // 關閉視窗
        }}
      />
      {/* Edit Dailog */}
      <EditDialog
        id={editingId}
        onClose={() => { setEditingId(null) }}
        onChange={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}