
export interface IAttributes {
  nickname: string // 別稱
  taxTitle: string // 抬頭
  taxId: string    // 統編
}

export const CLASS_NAME = 'TaxEntity'

export class TaxEntity extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, TaxEntity)