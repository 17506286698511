
export interface IAttributes {
  name: string
  city: string
  district: string
  address: string
  note: string
}

export const CLASS_NAME = 'Provider'

export class Provider extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, Provider)