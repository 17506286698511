import { Provider } from '../Provider'
import { Item } from '../Item'

export interface IAttributes {
  available: boolean      // 有效中
  provider: Provider      // 供應商
  item: Item              // 貨物
  safetyStocks: number    // 安全庫存
  note: string            // 採購備註
  order: number           // 訂單數量
  previousOrder: number   // 上次訂單
}

export const CLASS_NAME = 'PurchaseHelper'

export class PurchaseHelper extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }

  /**
   * 正在採購的供應商
   */
  static async getProviders() {
    const helpers = await new Parse.Query(PurchaseHelper)
      .limit(10000)
      .equalTo('available', true)   // 限定有效採購
      .select('provider')
      .include('provider')
      .find()
    return helpers.map(d => d.get('provider')!)
      // 去除重複
      .filter((ele, index, array) => array.findIndex(i => i.id === ele.id) === index)
  }

  /**
   * 正在採購的貨物
   */
  static async getItems(provider: Provider) {
    const helpers = await new Parse.Query(PurchaseHelper)
      .limit(10000)
      .equalTo('available', true)    // 限定有效採購
      .equalTo('provider', provider) // 指定供應商
      .select('item')
      .include('item')
      .find()
    return helpers.map(d => d.get('item')!)
  }
}

Parse.Object.registerSubclass(CLASS_NAME, PurchaseHelper)