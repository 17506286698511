import { Customer } from '../Customer'
import { User } from '../User'

export interface IAttributes {
  date: Date
  salesperson: User
  customer: Customer
  topic: string
  result: string
}

export const CLASS_NAME = 'MarketingNote'

export class MarketingNote extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, MarketingNote)