/**
 * 帳款頁面
 */
import React from 'react'
import { DataGridPremium, GridColumns, GridSortModel, GridRowId, GridToolbarContainer, GridFooterContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import BeenhereIcon from '@mui/icons-material/Beenhere'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { Provider } from '../../parse-class/Provider'
import { TransactionProvider } from '../../parse-class/TransactionProvider'

interface IAccount {
  id: string            // 交易ID
  date: Date
  provider: Provider
  sum: number           // 交易金額
  allowance: number     // 折讓
}

export const FIELDS = {
  DATE: 'DATE',
  PROVIDER: 'PROVIDER',
  SUM: 'SUM',
  ALLOWANCE: 'ALLOWANCE',
}

export function AccountProviderPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // data
  const [accountList, setAccountList] = React.useState<IAccount[] | null>(null)
  // state
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc' }, // 按照日期降序排列
    { field: 'id', sort: 'desc' }, // 按照ID降序排列
  ])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const transactions = await new Parse.Query(TransactionProvider)
        .equalTo('paymentTime', undefined)        // 未完成金流
        .include('provider')
        .limit(100000)
        .find()
      setAccountList(transactions.map(transaction => ({
        id: transaction.id,
        date: transaction.get('date')!,
        provider: transaction.get('provider')!,
        sum: transaction.sum(),
        allowance: transaction.get('allowance') || 0
      })))
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 計算選中的帳款加總
   */
  const sum = React.useMemo(() => {
    if (!accountList) return 0
    return accountList
      .filter(t => selectionModel.includes(t.id))
      .reduce((pre, cur) => pre + (cur.sum - cur.allowance), 0)
  }, [accountList, selectionModel])

  /**
   * 一鍵金流完成
   */
  const finish = React.useCallback(async () => {
    const sure = await confirm.confirm({ title: '確認', content: `是否完成${selectionModel.length}筆交易的金流？`, sure: '確定', close: '取消' })
    if (!sure) return
    for (let id of selectionModel) {
      const now = new Date()
      const transaction = await new Parse.Query(TransactionProvider).get(id as string)
      transaction.set('paymentTime', now)
      await transaction.save()
    }
    await loadData() // 重新載入資料
  }, [selectionModel, confirm, loadData])

  const rows = React.useMemo(() => {
    if (!accountList) {
      return []
    }
    return accountList
      .map(transaction => {
        return {
          id: transaction.id,
          [FIELDS.DATE]: transaction.date,
          [FIELDS.PROVIDER]: transaction.provider.get('name'),
          [FIELDS.SUM]: transaction.sum,
          [FIELDS.ALLOWANCE]: transaction.allowance,
        }
      })
  }, [accountList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: 'id', // 排序用
        hide: true,
      },
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
      },
      {
        field: FIELDS.PROVIDER,
        headerName: '供應商',
        width: 160,
      },
      {
        field: FIELDS.SUM,
        headerName: '交易金額',
        type: 'number',
        width: 100,
      },
      {
        field: FIELDS.ALLOWANCE,
        headerName: '折讓',
        type: 'number',
        width: 80,
      },
    ]
  }, [])

  if (accountList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="供應商帳款" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              // selection
              checkboxSelection
              onSelectionModelChange={selectionModel => { setSelectionModel(selectionModel) }}
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              //
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      disabled={selectionModel.length === 0}
                      color="primary"
                      startIcon={<BeenhereIcon />}
                      onClick={() => { finish() }}
                    >
                      金流完成
                    </Button>
                  </GridToolbarContainer>
                ),
                Footer: () => (
                  <GridFooterContainer>
                    <Box sx={{ flex: 1, p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                      總計：{Intl.NumberFormat().format(sum)}
                    </Box>
                  </GridFooterContainer>
                )
              }}
            />
          </Box>
        </StandardBox>
      </Container>
    </React.Fragment>
  )
}