import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import BasicSelect from '../../input/BasicSelect'
import CityAutocomplete from '../../input/CityAutocomplete'
import { Customer } from '../../parse-class/Customer'

interface IFields {
  newTags: string               // 要新增的標籤（多個標籤用逗號分開）
  // 不同條件之間取交集，要同時符合
  filterCity: string | null     // 指定標籤（空字串代表沒標籤的客戶）
  filteTag: string | 0 | 1          // 指定縣市
}

interface IProps extends DialogProps {
  onChange: () => void
}

export default function CustomerEditDialog(props: IProps) {

  const { onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const [fields, setFields] = React.useState<IFields>({
    newTags: '',
    filterCity: null,
    filteTag: 0,
  })
  // data
  const [tags, setTags] = React.useState<string[] | null>(null)

  const verify = React.useMemo(() => {
    return fields.newTags.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async () => {
    try {
      // 讀取資料
      backdrop.show('讀取資料中')
      const customers = await new Parse.Query(Customer)
        .limit(10000)
        .exists('position')  // 找出有座標的客戶
        .select('tags')      // 只抓標籤資料
        .find()
      // 解析出不重複的標籤
      const tags = customers.flatMap(c => c.get('tags') || [])
      setTags([...new Set(tags)])
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open) {
      setFields({
        newTags: '',
        filterCity: null,
        filteTag: 0,
      })
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogProps.open])

  /**
   * 根據過濾條件，從伺服器上抓取客戶資訊
   */
  const fetchFilteredCustomers = React.useCallback(async () => {
    const { filterCity, filteTag } = fields
    let query = new Parse.Query(Customer)
    query.limit(10000)
    query.select('tags')
    if (filterCity !== null) {
      query.equalTo('city', filterCity)
    }
    if (filteTag === 0) {
      // 全部：不作任何過濾
    } else if (filteTag === 1) {
      // 沒有任何標籤的客戶
      let emptyArrayQuery = new Parse.Query(Customer)
      emptyArrayQuery.notEqualTo('tags', [])                  // 找出不是空陣列的標籤
      emptyArrayQuery = Parse.Query.nor(emptyArrayQuery)      // 反轉（所有空陣列的標籤）
      query = Parse.Query.and(query, emptyArrayQuery)         // 套用到原本的請求
    } else if (typeof filteTag === 'string') {
      // @ts-ignore
      query.equalTo('tags', filteTag)                         // 所有客戶
    }
    const customers = await query.find()
    return customers
  }, [fields])

  const onAdd = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('批量新增標籤中')
        const customers = await fetchFilteredCustomers()
        const addTags = fields.newTags.split(',').map(t => t.trim()).filter(t => t.length > 0) // 整理
        for (let customer of customers) {
          const oldTags = customer.get('tags') || []
          const value = [...new Set([...oldTags, ...addTags])]
          customer.set('tags', value)
        }
        await Customer.saveAll(customers)
        snackbar.show('新增成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('批量新增標籤失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, onChange])

  const onDelete = React.useCallback(async () => {
    if (verify) {
      try {
        backdrop.show('批量刪除標籤中')
        const customers = await fetchFilteredCustomers()
        const deleteTags = fields.newTags.split(',').map(t => t.trim()).filter(t => t.length > 0) // 整理
        for (let customer of customers) {
          const oldTags = customer.get('tags') || []
          const value = oldTags.filter(oldTag => !deleteTags.includes(oldTag))
          customer.set('tags', value)
        }
        await Customer.saveAll(customers)
        snackbar.show('刪除成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('批量刪除標籤失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, onChange])

  if (tags === null) {
    return null
  }

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>批量增刪標籤</DialogTitle>
      <DialogContent>
        {/* 標籤名稱 */}
        <BasicTextField
          label="標籤名稱"
          helperText="多個標籤用逗號分開"
          value={fields.newTags}
          onChange={e => { setFields(pv => ({ ...pv, newTags: e.target.value })) }}
        />
        {/* 透過縣市篩選 */}
        <CityAutocomplete
          textFieldProps={{ label: '指定縣市（可選）' }}
          value={fields.filterCity}
          onChange={(e, value) => { setFields(pv => ({ ...pv, filterCity: value })) }}
        />
        {/* 透過標籤篩選 */}
        <BasicSelect
          label="指定標籤（可選）"
          value={fields.filteTag}
          onChange={e => { setFields(pv => ({ ...pv, filteTag: e.target.value as string })) }}
        >
          <MenuItem value={0}>全部</MenuItem>
          <MenuItem value={1}>沒有任何標籤的客戶</MenuItem>
          {tags.map(tag =>
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          )}
        </BasicSelect>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAdd} color="primary">
          新增
        </Button>
        <Button onClick={onDelete} color="primary">
          刪除
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}