/**
 * 帳款頁面
 */
import React from 'react'
import dayjs from 'dayjs'
import { DataGridPremium, GridColumns, GridSortModel, GridActionsCellItem, GridRowId, GridToolbarContainer, GridFooterContainer } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import EditIcon from '@mui/icons-material/Edit'
import PrintIcon from '@mui/icons-material/Print'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import CustomerTransactionEditDialog from '../MapDeliveryPage/CustomerTransactionEditDialog'
import { Customer } from '../../parse-class/Customer'
import { TransactionCustomer } from '../../parse-class/TransactionCustomer'

interface IAccount {
  id: string            // 交易ID
  date: Date
  customer: Customer
  sum: number           // 交易金額
  allowance: number     // 折讓
}

export const FIELDS = {
  DATE: 'DATE',
  CUSTOMER: 'CUSTOMER',
  SUM: 'SUM',
  ALLOWANCE: 'ALLOWANCE',
}

export function AccountCustomerPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // data
  const [accountList, setAccountList] = React.useState<IAccount[] | null>(null)
  // state
  const [showBillDialog, setShowBillDialog] = React.useState(false)
  const [editingTransactionId, setEditingTransactionId] = React.useState<string | null>(null)
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: FIELDS.DATE, sort: 'desc' }, // 按照日期降序排列
    { field: 'id', sort: 'desc' }, // 按照ID降序排列
  ])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const transactions = await new Parse.Query(TransactionCustomer)
        .equalTo('paymentTime', undefined)        // 未完成金流
        .include('customer')
        .limit(100000)
        .find()
      setAccountList(transactions.map(transaction => ({
        id: transaction.id,
        date: transaction.get('date')!,
        customer: transaction.get('customer')!,
        sum: transaction.sum(),
        allowance: transaction.get('allowance') || 0
      })))
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 計算選中的帳款加總
   */
  const sum = React.useMemo(() => {
    if (!accountList) return 0
    return accountList
      .filter(t => selectionModel.includes(t.id))
      .reduce((pre, cur) => pre + (cur.sum - cur.allowance), 0)
  }, [accountList, selectionModel])

  const billTransactions = React.useMemo(() => {
    if (!showBillDialog || !accountList) return undefined
    return accountList
      // 有選中的交易
      .filter(t => selectionModel.includes(t.id))
      // 日期排序
      .sort((a, b) => a.date.getTime() - b.date.getTime())
  }, [selectionModel, accountList, showBillDialog])

  const rows = React.useMemo(() => {
    if (!accountList) {
      return []
    }
    return accountList
      .map(transaction => {
        return {
          id: transaction.id,
          [FIELDS.DATE]: transaction.date,
          [FIELDS.CUSTOMER]: transaction.customer.get('name'),
          [FIELDS.SUM]: transaction.sum,
          [FIELDS.ALLOWANCE]: transaction.allowance,
        }
      })
  }, [accountList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: 'id', // 排序用
        hide: true,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 70,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              onClick={() => { setEditingTransactionId(id as string) }}
            />,
          ]
        }
      },
      {
        field: FIELDS.DATE,
        headerName: '日期',
        type: 'date',
        width: 110,
      },
      {
        field: FIELDS.CUSTOMER,
        headerName: '客戶',
        width: 160,
      },
      {
        field: FIELDS.SUM,
        headerName: '交易金額',
        type: 'number',
        width: 100,
      },
      {
        field: FIELDS.ALLOWANCE,
        headerName: '折讓',
        type: 'number',
        width: 80,
      },
    ]
  }, [])

  if (accountList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="客戶帳款" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              // selection
              checkboxSelection
              onSelectionModelChange={selectionModel => { setSelectionModel(selectionModel) }}
              // sort
              sortModel={sortModel}
              onSortModelChange={model => setSortModel(model)}
              //
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <Button
                      disabled={selectionModel.length === 0}
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={() => { setShowBillDialog(true) }}
                    >
                      請款單
                    </Button>
                  </GridToolbarContainer>
                ),
                Footer: () => (
                  <GridFooterContainer>
                    <Box sx={{ flex: 1, p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                      總計：{Intl.NumberFormat().format(sum)}
                    </Box>
                  </GridFooterContainer>
                )
              }}
            />
          </Box>
        </StandardBox>
      </Container>
      <CustomerTransactionEditDialog
        open={editingTransactionId !== null}
        onClose={() => setEditingTransactionId(null)}
        fullWidth
        maxWidth='md'
        fullScreen={isMobile}
        editingId={editingTransactionId}
        onChange={() => {
          setEditingTransactionId(null)       // 關閉視窗
          loadData()                          // 重新載入資料
        }}
      />
      {/* Bill Dialog */}
      <Dialog open={showBillDialog} onClose={() => { setShowBillDialog(false) }} fullWidth maxWidth="sm">
        <DialogTitle>請款單</DialogTitle>
        {billTransactions &&
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ flex: 1 }}>
                {billTransactions.map(t =>
                  <React.Fragment key={t.id}>{dayjs(t.date).format('YYYY-MM-DD')}<br /></React.Fragment>
                )}
              </Box>
              <Box sx={{ flex: 1 }}>
                {billTransactions.map(t =>
                  <React.Fragment key={t.id}>${Intl.NumberFormat().format(t.sum - t.allowance)}<br /></React.Fragment>
                )}
              </Box>
            </Box>
            <Box mt={2}>
              總金額：{Intl.NumberFormat().format(billTransactions.reduce((p, c) => p + (c.sum - c.allowance), 0))}
            </Box>
          </DialogContent>
        }
        <DialogActions>
          <Button color="primary" onClick={() => { setShowBillDialog(false) }}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  )
}