/**
 * 同種庫存群組編輯對話框
 */
import React from 'react'
import { DataGridPremium, GridColumns, GridRowsProp, GridRowModel, GridRowId, GridActionsCellItem, GridToolbarContainer, GridRowModesModel, GridRowModes } from '@mui/x-data-grid-premium'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { ConfirmContext } from '../../context/confirm'
import { InventoryItemAutocomplete } from './InventoryItemAutocomplete'
import { Item } from '../../parse-class/Item'

const TEMP_NEW_ROW_ID = 'NEW'
let idCounter = 0

const FIELDS = {
  ITEM: 'ITEM',
  WEIGHT: 'WEIGHT',
}

interface IProps extends DialogProps {
  group: string | null
  onChange: () => void
}

export default function InventoryGroupDialog(props: IProps) {

  const { group, onChange, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const confirm = React.useContext(ConfirmContext)
  // date
  const [rawItems, setRawItems] = React.useState<Item[]>([])
  // state
  const [rows, setRows] = React.useState<GridRowsProp>([])
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [editingId, setEditingId] = React.useState<GridRowId | null>(null)

  const loadData = React.useCallback(async (group: string) => {
    try {
      backdrop.show('讀取資料中')
      const items = await new Parse.Query(Item)
        .equalTo('inventoryGroup', group)
        .find()
      setRawItems(items)
      setRows(items.map(item => ({
        id: idCounter++,
        [FIELDS.ITEM]: item,
        [FIELDS.WEIGHT]: item.get('inventoryWeight') || 1
      })))
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && group !== null) {
      loadData(group)
    }
  }, [dialogProps.open, group, loadData])

  const onSave = React.useCallback(async () => {
    try {
      backdrop.show('更新資料中')
      // 原先的成員，清除其Group和Weight
      for (const item of rawItems) {
        item.unset('inventoryGroup')
        item.set('inventoryWeight', 1)
      }
      await Parse.Object.saveAll(rawItems)
      // 新的成員，設定Group和Weight
      for (const row of rows) {
        const item: Item = row[FIELDS.ITEM]
        const weight: number = row[FIELDS.WEIGHT]
        item.set('inventoryGroup', group!)
        item.set('inventoryWeight', weight)
        await item.save()
      }
      snackbar.show('更新成功')
      onChange() // 通知外部資料有變更
    } catch (e: any) {
      snackbar.show('更新失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingId, rawItems, rows, group, onChange])

  const handleAddClick = React.useCallback(() => {
    // 新增暫存列並指定預設值
    setRows(oldRows => [...oldRows, {
      id: TEMP_NEW_ROW_ID,
      [FIELDS.ITEM]: null,
      [FIELDS.WEIGHT]: 1,
    }])
    // 進入編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [TEMP_NEW_ROW_ID]: { mode: GridRowModes.Edit, fieldToFocus: FIELDS.ITEM },
    }))
    // 設置編輯ID
    setEditingId(TEMP_NEW_ROW_ID)
  }, [])

  const handleEditClick = React.useCallback((id: GridRowId) => {
    // 進入編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: FIELDS.WEIGHT },
    }))
    // 設置編輯ID
    setEditingId(id)
  }, [])

  const handleDeleteClick = React.useCallback(async (id: GridRowId) => {
    const sure = await confirm.confirm({ title: '您確定要刪除這筆資料？', sure: '刪除' })
    if (!sure) return
    // 刪除列資料
    setRows(oldRows => oldRows.filter(row => row.id !== id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditSaveClick = React.useCallback(() => {
    // 退出編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [editingId!]: { mode: GridRowModes.View },
    }))
    // 清除編輯ID
    setEditingId(null)
  }, [editingId])

  const handleEditCancelClick = React.useCallback(() => {
    // 退出編輯模式
    setRowModesModel(oldModel => ({
      ...oldModel,
      [editingId!]: { mode: GridRowModes.View, ignoreModifications: true },
    }))
    // 如果是新增，則刪除暫存列
    if (editingId === TEMP_NEW_ROW_ID) {
      setRows(oldRows => oldRows.filter(row => row.id !== TEMP_NEW_ROW_ID))
    }
    // 清除編輯ID
    setEditingId(null)
  }, [editingId])

  const processRowUpdate = React.useCallback(async (newRow: GridRowModel) => {
    // 如果是新增的情況
    if (newRow.id === TEMP_NEW_ROW_ID) {
      // 刪除暫存列
      setRows(oldRows => oldRows.filter(row => row.id !== TEMP_NEW_ROW_ID))
      // 新增列
      setRows(oldRows => [...oldRows, { ...newRow, id: idCounter++ }])
    }
    // 如果是編輯的情況
    else {
      // 更新列
      setRows(oldRows => oldRows.map(row => row.id === newRow.id ? newRow : row))
    }
    return newRow
  }, [])

  const handleProcessRowUpdateError = React.useCallback((e: Error) => {
    snackbar.show('更新失敗：' + e, 'error')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.ITEM,
        headerName: '貨物',
        width: 400,
        editable: true,
        sortable: false,
        valueFormatter: params => {
          const item: Item | null = params.value
          return item ? item.getFormatName() : '（新增欄位）'
        },
        renderEditCell: params => (
          <InventoryItemAutocomplete
            gridRenderEditCellParams={params}
          />
        )
      },
      {
        field: FIELDS.WEIGHT,
        headerName: '權重',
        type: 'number',
        width: 100,
        editable: true,
        sortable: false,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 120,
        getActions: ({ id }) => {
          if (editingId !== id) {
            return [
              <GridActionsCellItem
                disabled={editingId !== null}
                icon={<EditIcon />}
                label="編輯"
                onClick={() => handleEditClick(id)}
              />,
              <GridActionsCellItem
                disabled={editingId !== null}
                icon={<DeleteIcon />}
                label="刪除"
                onClick={() => handleDeleteClick(id)}
                color="error"
              />,
            ]
          } else {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="儲存"
                onClick={() => handleEditSaveClick()}
                color="primary"
              />,
              <GridActionsCellItem
                icon={<CloseIcon />}
                label="取消"
                onClick={() => handleEditCancelClick()}
                color="inherit"
              />,
            ]
          }
        },
      },
    ]
  }, [editingId, handleEditClick, handleDeleteClick, handleEditSaveClick, handleEditCancelClick])

  if (group === null) {
    return null
  }

  return (
    <React.Fragment>
      <Dialog {...dialogProps}>
        <DialogTitle>同種庫存群組</DialogTitle>
        <DialogContent>
          <DataGridPremium
            rows={rows}
            columns={columns}
            density="compact"
            autoHeight
            // edit
            experimentalFeatures={{ newEditingApi: true }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowEditStart={(params, event) => { event.defaultMuiPrevented = true }}  // 關閉預設進入編輯模式的方法
            onRowEditStop={(params, event) => { event.defaultMuiPrevented = true }}   // 關閉預設離開編輯模式的方法
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            // Component
            components={{
              Toolbar: () => (
                <GridToolbarContainer>
                  <Button disabled={editingId !== null} color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
                    新增
                  </Button>
                </GridToolbarContainer>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onSave}
          >
            儲存
          </Button>
          <Button color="error" onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}