import React from 'react'
import { DataGridPremium, GridColumns, useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import PlaceIcon from '@mui/icons-material/Place'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import CustomerAddDialog from './CustomerAddDialog'
import { GoogleMapPlace } from '../../parse-class/GoogleMapPlace'
import { Customer } from '../../parse-class/Customer'

export const FIELDS = {
  PLACE_ID: 'PLACE_ID',
  NAME: 'NAME',
  CITY: 'CITY',
  DISTRICT: 'DISTRICT',
  TAGS: 'TAGS',
}

export function GoogleMapPlacePage() {

  // ref
  const apiRef = useGridApiRef()
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // data
  const [placeList, setPlaceList] = React.useState<GoogleMapPlace[] | null>(null)
  const [customerList, setCustomerList] = React.useState<Customer[] | null>(null);
  //state
  const [addingPlaceId, setAddingPlaceId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const placeList = await new Parse.Query(GoogleMapPlace)
        .limit(999999)
        .find()
      setPlaceList(placeList)
      const customerList = await new Parse.Query(Customer)
        .limit(100000)
        .select('googlePlaceId')
        .find()
      setCustomerList(customerList)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const processRowUpdate = React.useCallback(async (newRow: any, oldRow: any) => {
    if (newRow[FIELDS.TAGS] !== oldRow[FIELDS.TAGS]) {
      // format
      const tagsStr: string = newRow[FIELDS.TAGS]
      const tags = tagsStr.split(',').map(s => s.trim()).filter(s => s.length > 0)
      // update
      const place = placeList?.find(place => place.id === newRow.id)!
      place.set('tags', tags)
      await place.save()
    }
    return newRow
  }, [placeList])

  const onProcessRowUpdateError = React.useCallback((e: any) => {
    snackbar.show('上傳資料失敗：' + e, 'error')
  }, [snackbar])

  const copyPlaceId = React.useCallback(async (id: string) => {
    await navigator.clipboard.writeText(id)
    snackbar.show('已複製ID到剪貼簿')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = React.useMemo(() => {
    if (!placeList) {
      return []
    }
    return placeList.map(place => (
      {
        id: place.id,
        [FIELDS.PLACE_ID]: place.get('placeId'),
        [FIELDS.NAME]: place.get('name'),
        [FIELDS.CITY]: place.get('city'),
        [FIELDS.DISTRICT]: place.get('district'),
        [FIELDS.TAGS]: place.get('tags')?.join(',') || '',
      }
    ))
  }, [placeList])

  const columns: GridColumns = React.useMemo(() => {
    return [
      {
        field: FIELDS.NAME,
        headerName: '名稱',
        width: 200,
      },
      {
        field: FIELDS.CITY,
        headerName: '縣市',
        width: 85,
        sortable: false,
      },
      {
        field: FIELDS.DISTRICT,
        headerName: '區域',
        width: 85,
        sortable: false,
      },
      {
        field: FIELDS.TAGS,
        headerName: '標籤',
        width: 250,
        editable: true,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 130,
        getActions: ({ id, row }) => {
          const placeId: string = row[FIELDS.PLACE_ID]
          return [
            <GridActionsCellItem
              icon={<PlaceIcon />}
              label="地圖"
              color="primary"
              onClick={() => {
                window.open(`https://www.google.com/maps/place/?q=place_id:${placeId}`, 'map_window', 'menubar:false,height=800,width=1200')
              }}
            />,
            <GridActionsCellItem
              disabled={customerList?.find(c => c.get('googlePlaceId') === placeId) !== undefined}
              icon={<AddIcon />}
              label="建立"
              color="primary"
              onClick={() => {
                setAddingPlaceId(id as string)
              }}
            />,
            <GridActionsCellItem
              icon={<ContentCopyIcon />}
              label="複製ID"
              color="primary"
              onClick={() => {
                copyPlaceId(placeId)
              }}
            />,
          ]
        }
      }
    ]
  }, [customerList, copyPlaceId])

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="Google Map Place 分析" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              loading={placeList === null || customerList === null}
              apiRef={apiRef}
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={onProcessRowUpdateError}
            />
          </Box>
        </StandardBox>
      </Container>
      <CustomerAddDialog
        open={addingPlaceId !== null}
        onClose={() => { setAddingPlaceId(null) }}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        addingPlaceId={addingPlaceId}
        onUpdate={customer => {
          setCustomerList(pre => [...pre!, customer]) // 重新讀取客戶資料（讓建立按鈕Disable）
          setAddingPlaceId(null)                      // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}