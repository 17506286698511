import { ZCompany } from '../ZCompany'
import { ZItem } from '../ZItem'

export interface IPurchaseDetail {
  item: ZItem            // 貨物
  qty: number             // 數量
  cost: number            // 成本
  base: number            // 底價
  memo: string            // 備註
}

export interface IAttributes {
  date: Date                      // 日期
  code: string                    // 進貨編號
  company: ZCompany               // 供應商
  salesperson: string             // 業務
  sum: number                     // 總額
  received: number                // 已收額
  memo: string                    // 備註
  details: IPurchaseDetail[]      // 明細
}

export const CLASS_NAME = 'ZPurchase'

export class ZPurchase extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, ZPurchase)


