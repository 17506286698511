import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import BasicTextField from '../../input/BasicTextField'
import CityAutocomplete from '../../input/CityAutocomplete'
import DistrictAutocomplete from '../../input/DistrictAutocomplete'
import { Provider } from '../../parse-class/Provider'

interface IFields {
  name: string;
  city: string | null;
  district: string | null;
  address: string;
  note: string;
}

interface IParams {
  id: string | null;
  onClose: () => void;
  onChange: () => void;
}

export default function EditDialog(props: IParams) {

  const { id, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // state
  const [raw, setRaw] = React.useState<Provider | null>(null)
  const [fields, setFields] = React.useState<IFields>({
    name: '',
    city: null,
    district: null,
    address: '',
    note: '',
  })

  const verify = React.useMemo(() => {
    return fields.name.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Provider)
      const provider = await query.get(id)
      setRaw(provider)
      setFields({
        name: provider.get('name') || '',
        city: provider.get('city') || null,
        district: provider.get('district') || null,
        address: provider.get('address') || '',
        note: provider.get('note') || '',
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (id !== null) {
      loadData(id)
    }
  }, [id, loadData])

  const onSave = React.useCallback(async () => {
    if (id !== null && raw !== null && verify) {
      try {
        backdrop.show('更新資料中')
        raw.set('name', fields.name)
        raw.set('city', fields.city || '')
        raw.set('district', fields.district || '')
        raw.set('address', fields.address)
        raw.set('note', fields.note)
        await raw.save()
        snackbar.show('更新成功')
        onChange(); // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, raw, verify, fields, onChange])

  // 等待從伺服器取得資料
  if (raw === null) {
    return null;
  }

  return (
    <Dialog
      open={id !== null}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle>編輯供應商</DialogTitle>
      <DialogContent>
        {/* 名稱 */}
        <BasicTextField
          label="名稱"
          value={fields.name}
          onChange={e => { setFields(pv => ({ ...pv, name: e.target.value })) }}
        />
        {/* 縣市 */}
        <CityAutocomplete
          value={fields.city}
          onChange={(event, value) => { setFields(pv => ({ ...pv, city: value, district: null })) }}
        />
        {/* 鄉鎮市區 */}
        <DistrictAutocomplete
          city={fields.city}
          value={fields.district}
          onChange={(event, value) => { setFields(pv => ({ ...pv, district: value })) }}
        />
        {/* 地址 */}
        <BasicTextField
          label="地址"
          value={fields.address}
          onChange={e => { setFields(pv => ({ ...pv, address: e.target.value })) }}
        />
        {/* 備註 */}
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}