import { PosPrinter } from '../parse-class/PosPrinter'
import { PosPrinterTask } from '../parse-class/PosPrinterTask'
import { TransactionCustomer } from '../parse-class/TransactionCustomer'
import { Item } from '../parse-class/Item'

namespace TaskHelper {

  interface IOptions {
    transactions: TransactionCustomer[]
  }

  interface IParams {
    details: {
      name: string,
      pkg: string,
      qty: number,
      pos: string,
    }[]
  }

  function formatItemName(item: Item) {
    const source = item.get('source')
    const name = item.get('name') || ''
    const spec = item.get('spec') || ''
    return (source ? `${source}：` : '') + name + (spec ? `【${spec}】` : '')
  }

  export function createParams(options: IOptions) {

    // 統計數量
    const { transactions } = options
    const calQty = new Map<string, { item: Item, qty: number }>()
    for (const detail of transactions.flatMap(t => t.get('details') || [])) {
      const key = detail.item.id
      if (calQty.has(key)) {
        const value = calQty.get(key)!
        const qty = value.qty + detail.qty
        calQty.set(key, { ...value, qty })
      } else {
        calQty.set(key, {
          item: detail.item,
          qty: detail.qty
        })
      }
    }
    // 格式化
    const result: { name: string, pkg: string, qty: number, pos: string }[] = []
    for (let { item, qty } of calQty.values()) {
      result.push({
        name: formatItemName(item),
        pkg: item.get('pkg') || '',
        qty: qty,
        pos: item.get('pos') || '??',
      })
    }
    // 排序
    const sorted = result.sort((r1, r2) => {
      const posCompare = r1.pos.localeCompare(r2.pos)
      if (posCompare !== 0) {
        return posCompare
      }
      const nameCompare = r1.name.localeCompare(r2.name)
      return nameCompare
    })
    // 準備資料
    const params: IParams = {
      details: sorted,
    }
    return params
  }
}

export interface IOptions {
  printer: PosPrinter
  transactionIds: string[]
}

export async function createPickTask(options: IOptions) {
  // 取得transaction
  const { printer, transactionIds } = options
  const transactions = await new Parse.Query(TransactionCustomer)
    .containedIn('objectId', transactionIds)
    .select('details')
    // @ts-ignore
    .include('details.item')
    .find()
  // 建立任務
  const params = TaskHelper.createParams({ transactions })
  const task = new PosPrinterTask()
  task.set('printer', printer)
  task.set('template', 'pick')
  task.set('params', JSON.stringify(params))
  await task.save()
}